import request from "@src/utils/request";

export function signUpUser(data) {
    return request()
        .post("/api/v1/signup-user/")
        .send(data);
}

export function requestEditLink(email) {
    return request()
        .post("/api/v1/request-edit-link/")
        .send({ email });
}
