import React, {useEffect, useMemo, useRef, useState} from "react";
import map from "lodash/map";
import styles from "./Carousel.scss";
import ArticleTile from "@src/components/ArticleTile";
import Button from "@src/components/shared/elements/Button";
import * as ArticleActions from "@src/actions/ArticleActions";


function Carousel({ children }) {
    const [carouselIndex, setCarouselIndex] = useState(0);
    const [slidesWidth, setSlidesWidth]  = useState(0);
    const slidesRef = useRef();

    useEffect(() => {
        if (slidesRef?.current) {
            setTimeout(() => {
                const containerBounds = slidesRef.current.getBoundingClientRect();
                setSlidesWidth(containerBounds.width);
            }, 200);
        }
    }, [slidesRef]);

    const slidesCount = useMemo(() => {
        return children?.length || 0;
    }, [children]);

    const carouselSlidesLeft = useMemo(() => {

        const singleSlide = slidesWidth/slidesCount;

        if (carouselIndex && slidesWidth) {
            const leftPos = carouselIndex * singleSlide;
            return `-${leftPos}px`;
        }

        return "0";
    }, [carouselIndex, slidesWidth, slidesCount]);

    const handlePrev = () => {
        if (carouselIndex !== 0) {
            setCarouselIndex(carouselIndex - 1);
        }
    }

    const handleNext = () => {
        if (carouselIndex !== 2) {
            setCarouselIndex(carouselIndex + 1);
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.prev}>
                <Button disabled={carouselIndex === 0} iconOnly buttonIcon={"fa-solid fa-caret-left"} onClick={handlePrev}/>
            </div>
            <div className={styles.listContainer}>
                <div className={styles.list} style={{ marginLeft: carouselSlidesLeft }} ref={slidesRef}>
                    {children}
                </div>
            </div>
            <div className={styles.next}>
                <Button disabled={carouselIndex === slidesCount-1} iconOnly buttonIcon={"fa-solid fa-caret-right"} onClick={handleNext} />
            </div>
        </div>
    );
}

Carousel.propTypes = {}

export default Carousel;
