import React, {useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import filter from "lodash/filter";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import styles from "./VotingLocations.scss";
import MapContainer from "@src/components/maps/MapContainer";
import * as MapActions from "@src/actions/MapActions";
import * as UserActions from "@src/actions/UserActions";
import votingLocations from "@src/pages/VotingLocations/index";
import MapMarker from "@src/components/maps/Google/MapMarker";
import Panel from "@src/components/shared/widgets/Panel";
import Hero from "@src/components/Hero";
import {setPageTitle} from "@src/utils/windowHelpers";


function VotingLocations({
    defaultMapPosition,
    loadCurrentOrganization,
    loadVotingLocations,
    mapConfig,
    organization,
    showMap = false,
    votingLocations
}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [openMarker, setOpenMarker] = useState(null);

    useEffect(() => {
        if (!organization) {
            loadCurrentOrganization();
        }
        if(!votingLocations) {
            loadVotingLocations();
        }
        setPageTitle("Voting Locations", window.location);
    }, []);



    const loaderComponent = useMemo(() => {
        return isLoaded ? null : <div>LOADING MAP ... </div>
    }, [isLoaded]);

    const handleMapLoaded = () => {
        setIsLoaded(true);
    }

    const handleMarkerClick = (data, location) => {
    }

    const handleMarkerHovered = (isHovering, location) => {
        console.log("VotingLocations :: handleMarkerHovered :: location = ", location);
        if (isHovering) {
            setOpenMarker(location.id);
        } else {
            setOpenMarker(null);
        }
    }

    // const votingLocationMarkers = useMemo(() => {
    //     if (votingLocations) {
    //         return map(sortBy(votingLocations, ["order"]), loc => {
    //             const isSelected = openMarker === loc.id;
    //             return (
    //                 <MapMarker
    //                     key={loc.id}
    //                     lat={loc.location.lat}
    //                     lng={loc.location.lng}
    //                     dark
    //                     onClick={(data) => handleMarkerClick(data, loc)}
    //                     onHover={(isHovering) => handleMarkerHovered(isHovering, loc)}
    //                     isSelected={isSelected}
    //                     className={classnames(loc.votingAvailability)}
    //                 />
    //             );
    //         });
    //     }
    //     return null;
    // }, [votingLocations, openMarker]);

    const earlyLocationList = useMemo(() => {
        if (votingLocations) {
            const list = map(
                sortBy(filter(votingLocations, vLoc => vLoc.votingAvailability !== "election_day"), ["order"]),
                (loc, indx) => {
                    const isActive = openMarker === loc.id;
                    return (
                        <div
                            className={classnames(styles.votingLocation, styles.green, {
                                [styles.isActive]: isActive,
                            })}
                            key={`vlo_${loc.order}_${indx}`}
                            onClick={() => setOpenMarker(loc.id)}
                        >
                            <div className={styles.locationName}>
                                <h4 className={styles.name}>{loc.name}</h4>
                                <div className={styles.address}>{loc.location.formattedAddress}</div>
                            </div>
                            <div className={styles.hours}>{loc.hours}</div>
                        </div>
                    );
                });

            return (
                <div className={styles.locationList}>
                    <Panel title={"Early Voting Locations"} stickyHeader color={"green"} >
                        {list}
                    </Panel>
                </div>
            )
        }
        return null;
    }, [votingLocations, openMarker]);

    const locationList = useMemo(() => {
        console.log("VotingLocations :: locationList :: openMarker = ", openMarker);
        if (votingLocations) {
            const list = map(
                sortBy(filter(votingLocations, vLoc => vLoc.votingAvailability !== "early_voting"), ["order"]),
                (loc, indx) => {
                    const isActive = openMarker === loc.id;
                    return (
                        <div
                            className={classnames(styles.votingLocation, {
                                [styles.isActive]: isActive,
                            })}
                            key={`vlo_${loc.order}_${indx}`}
                            onClick={() => setOpenMarker(loc.id)}
                        >
                            <div className={styles.locationName}>
                                <h4 className={styles.name}>{loc.name}</h4>
                                <div className={styles.address}>{loc.location.formattedAddress}</div>
                            </div>
                            <div className={styles.hours}>{loc.hours}</div>
                        </div>
                    );
                });

            return (
                <div className={styles.locationList}>
                    <Panel title={"Election Day Locations"} stickyHeader >
                        {list}
                    </Panel>
                </div>
            )
        }
        return null;
    }, [votingLocations, openMarker]);

    // const renderedMap = useMemo(() => {
    //     if (!votingLocations || !defaultMapPosition) {
    //         return null;
    //
    //     }
    //
    //     if (votingLocationMarkers) {
    //         return (
    //             <div className={styles.mapWrapper}>
    //                 <MapContainer
    //                     key={"voting-maps"}
    //                     mapConfig={mapConfig}
    //                     onMapLoaded={handleMapLoaded}
    //                     showBorder
    //                 >
    //                     {votingLocationMarkers}
    //                 </MapContainer>
    //             </div>
    //         );
    //     }
    //     return null;
    // }, [ defaultMapPosition, votingLocations, votingLocationMarkers ]);

    return (
        <div className={styles.root}>
            <Hero
                imageFilename={"wcc_hero_background_strip.jpg"}
                imageAltText={"American flag and Tennessee flying"}
                headerText={"Where to Vote"}
                subpage
            />
            {/*<Link className={styles.backLink} to={"/maps/"}><i className={"fa-solid fa-chevron-left"} /> Election Maps</Link>*/}
            <h1 style={{ position: "absolute", left: "-9999px" }}>Voting Locations</h1>
            {/*{loaderComponent}*/}
            <div className={styles.inner}>
                {earlyLocationList}
                {locationList}
                {/*{showMap && renderedMap}*/}
            </div>
        </div>
    );
}


VotingLocations.propTypes = {
    showMap: PropTypes.bool,
}

export default connect(
    (state, ownProps) => {
        const organization = state.appState.currentOrganization;
        const defaultMapPosition = state.appState.currentOrganization?.defaultMapPosition;

        return {
            organization,
            mapConfig: organization?.baseMap,
            votingLocations: state.mapState.votingLocations,
            defaultMapPosition,
        }
    },
    (dispatch) => ({
        loadCurrentOrganization: () => dispatch(UserActions.loadCurrentOrganization()),
        loadVotingLocations: () => dispatch(MapActions.loadVotingLocations()),
    })
)(VotingLocations);
