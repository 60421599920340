import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./RichTextEditor.scss";
import CKEditorComponent from "@src/components/shared/widgets/RichTextEditor/CKEditor";

function RichTextEditor({
    editing,
    label,
    loaded,
    name,
    onChange,
    value,
}) {

    const handleOnChange = (html) => {
        // handled in case we need to swap out editors and only use HTML
        onChange({ name, value: html });
    }

    if ((editing && !value) || !loaded) {
        return null;
    }

    return (
        <div className={styles.root}>
            {label && <div className={styles.label}>{label}</div>}
            <div className={styles.inputBox}>
                <CKEditorComponent
                    onChange={handleOnChange}
                    initialHtml={value}
                    name={name}
                />
            </div>
        </div>
    );
}


RichTextEditor.propTypes = {
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    loaded: PropTypes.bool,
    editing: PropTypes.bool,
}

RichTextEditor.defaultProps = {}

export default RichTextEditor;
