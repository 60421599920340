// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MapList__root{flex:none;width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center}@media (min-width: 768px){.MapList__root{display:grid;gap:1em;grid-template-columns:repeat(auto-fit, minmax(300px, 300px))}}
`, "",{"version":3,"sources":["webpack://./web/src/components/maps/MapList/MapList.scss"],"names":[],"mappings":"AAGA,eACE,SAAU,CACV,UAAW,CACX,YAAa,CACb,qBAAsB,CACtB,sBAAuB,CACvB,kBAAmB,CAEnB,0BARF,eASI,YAAa,CACb,OAAQ,CACR,4DAA6D,CAGhE","sourcesContent":["@import \"@commonstyles/vars.scss\";\n@import \"@commonstyles/colors.scss\";\n\n.root {\n  flex: none;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  @media (min-width: $breakpointSm) {\n    display: grid;\n    gap: 1em;\n    grid-template-columns: repeat(auto-fit, minmax(300px, 300px));\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `MapList__root`
};
export default ___CSS_LOADER_EXPORT___;
