import React, {useEffect, useMemo, useState} from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./HomePage.scss";
import Hero from "@src/components/Hero";
import BottomContent from "@src/components/BottomContent";
import {setPageTitle} from "@src/utils/windowHelpers";

function HomePage(props) {
    const navigate = useNavigate();

    useEffect(() => {
        setPageTitle("Home", window.location);
    }, []);

    const handleButtonClick = () => {
        navigate("/subscribe");
    }
    return (
        <div className={styles.root}>
            <Hero
                imageFilename={"wcc_hero_background_strip.jpg"}
                imageAltText={"American flag and Tennessee flying"}
                headerText={"Your Conservative Grassroots Network"}
                subtitleText={<span>in Williamson County, Tennessee</span>}
                buttonText={"Subscribe Now"}
                onButtonClick={handleButtonClick}
            />
            <main className={styles.main}>
                {/*<h1>Williamson County Citizens</h1>*/}
                <section>
                    <h2>We are Williamson County Citizens</h2>
                    <div className={styles.divider} />
                    <p>Whether you’ve lived here for generations or have relocated in recent years, Williamson County is a special place to call home. Most likely you have chosen to live here for conservative ideals such as low crime, great schools, Judeo-Christian values, individual liberties, and quality of life.</p>
                </section>
                <section>
                    <h3>The Challenge</h3>
                    <div className={styles.divider} />
                    <p>Unfortunately, our local political governance has us headed away from these conservative ideals and down the path of becoming the next Davidson County. Our local government resembles a political aristocracy more than it does real representation. Many of our elected officials say all the right things but do not promote authentic conservative policy.</p>
                </section>
                <section>
                    <h3>Our Solution</h3>
                    <div className={styles.divider} />
                    <p>Thankfully, as conservative citizens in Williamson County we are in the majority. The simple solution is voter turnout. If we as conservatives vote in <strong>ALL</strong> available elections, <u>we win</u>!</p>
                    <p>Your vote in city, county, and state elections is the most powerful force you have against the advancement of establishment and liberal agendas in your community.</p>
                </section>
                <section>
                    <h3>Who We Are</h3>
                    <div className={styles.divider} />
                    <p>We are a network of local voters that elect grassroots conservatives to public office.<br />We stand for <span className={styles.real}>Real</span> conservative representation in Williamson County.</p>
                </section>
            </main>
            <BottomContent />
        </div>
    );
}


HomePage.propTypes = {}

HomePage.defaultProps = {}

export default HomePage;
