import { sendPageView } from "@src/utils/Analytics";
const activeListeners = {};

const startWindowClickHandler = (id) => {
    if(!!activeListeners[id]) {
        window.addEventListener("click", activeListeners[id]);
    }
}

const clearWindowClickHandler = (id) => {
    if(activeListeners[id]) {
        window.removeEventListener("click", activeListeners[id]);
    }

};

export const removeClickOutside = (id) => {
    clearWindowClickHandler(id);
};

export const addClickOutside = (id, callback) => {
    if(!!activeListeners[id]) {
        // we don't need 2 of them
        clearWindowClickHandler(id);
    }

    const clickOutside = (evt) => {
        callback();
        handleClear();
    };
    const handleClear = () => {
        clearWindowClickHandler(id);
    }
    activeListeners[id] = clickOutside;
    startWindowClickHandler(id);
};


export const setPageTitle = (pageTitle, location) => {
    const organization = window.ENV?.ORG_NAME || "Williamson County Citizens";
    const pageName = `${pageTitle} :: ${organization}`;
    document.title = pageName;
    sendPageView(pageName, location);
};

export const itemInView = (clientRect, offset=60) => {
    // 60px is the height of the fixed userNav
    if (!clientRect) {
        return false;
    }


    const topInView = clientRect.top > offset && clientRect.top < window.innerHeight;
    const bottomInView = clientRect.bottom < window.innerHeight && clientRect.bottom > offset;
    const middleInView = clientRect.bottom > window.innerHeight && clientRect.top < offset;
    return topInView || bottomInView || middleInView;
}
