
import { combineReducers } from "redux";
import * as ActionTypes from "@src/constants/ActionTypes";
import { produce } from "immer";
import flatMap from "lodash/flatMap";
import keyBy from "lodash/keyBy";
import omit from "lodash/omit";

function elections(state = {}, action) {
    switch (action.type) {
        case ActionTypes.UPCOMING_ELECTIONS_LOADED:
        case ActionTypes.PAST_ELECTIONS_LOADED:
            return {
                ...state,
                ...keyBy(action.electionDates.elections, "slug"),
            }
        case ActionTypes.ELECTIONS_LOADED:
            return {
                ...state,
                ...keyBy(action.elections, "slug"),
            }
        case ActionTypes.ELECTION_CREATED:
        case ActionTypes.ELECTION_UPDATED:
        case ActionTypes.ELECTION_DETAILS_LOADED:
            return {
                ...state,
                [action.election.slug]: action.election,
            };
        case ActionTypes.ELECTION_DELETED:
            return omit(state, action.election);
        default:
            return state;
    }
}


function articles(state={}, action) {
    switch (action.type) {
        case ActionTypes.ARTICLE_FEED_LOADED:
            return {
                ...state,
                ...keyBy(action.articles, "path"),
            };
        case ActionTypes.ARTICLE_CREATED:
        case ActionTypes.ARTICLE_UPDATED:
        case ActionTypes.ARTICLE_DETAILS_LOADED:
            return {
                ...state,
                [action.article.path]: action.article,
            };
        case ActionTypes.ARTICLE_DELETED:
            return omit(state, action.article);
        default:
            return state;
    }
}

function recentArticles(state={}, action) {
    switch (action.type) {
        case ActionTypes.RECENT_ARTICLES_LOADED:
            return {
                ...state,
                ...keyBy(action.recentArticles, "id"),
            };
        case ActionTypes.ARTICLE_UPDATED:
        case ActionTypes.ARTICLE_CREATED:
            return {
                ...state,
                [action.article.id]: action.article,
            };
        case ActionTypes.ARTICLE_DELETED:
            return omit(state, action.article);
        default:
            return state;
    }
}

function upcomingElections(state={}, action) {
    switch (action.type) {
        case ActionTypes.UPCOMING_ELECTIONS_LOADED:
            return {
                ...state,
                ...keyBy(action.electionDates, "id"),
            };
        default:
            return state;
    }
}

function pastElections(state={}, action) {
    switch (action.type) {
        case ActionTypes.PAST_ELECTIONS_LOADED:
            return {
                ...state,
                ...keyBy(action.electionDates, "id"),
            };
        default:
            return state;
    }
}

function electionDates(state = {}, action) {
    switch (action.type) {
        case ActionTypes.UPCOMING_ELECTIONS_LOADED:
        case ActionTypes.PAST_ELECTIONS_LOADED:
            return {
                ...state,
                ...keyBy(action.electionDates, "id"),
            };
        case ActionTypes.ELECTION_DATE_LOADED:
        case ActionTypes.ELECTION_UPDATED:
        case ActionTypes.ELECTION_CREATED:
            return {
                ...state,
                [action.election.id]: action.election,
            };
        case ActionTypes.ELECTION_DELETED:
            return omit(state, action.election);
        default:
            return state;
    }
}

function featuredArticles(state= null, action) {
    switch (action.type) {
        case ActionTypes.CLEAR_FEATURED_ARTICLES:
            return null;
        case ActionTypes.FEATURED_ARTICLES_LOADED:
            return action.featuredArticles.results;
        default:
            return state;
    }
}

function commentHierarchyComments(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENT_AND_REPLIES_LOADED:
            return {
                ...state,
                [action.comment.id]: {...action.comment},
            }
        case ActionTypes.COMMENT_UPDATED:
            return {
                ...state,
                [action.comment.id]: action.comment,
            };
        case ActionTypes.COMMENT_DELETED:
            return omit(state, action.comment.id);
        case ActionTypes.COMMENT_AND_REPLIES_ERROR:
            return {
                ...state,
                [action.commentId]: {
                    missing: true,
                },
            }
        default:
            return state;
    }
}


function commentHierarchyReplies(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENT_AND_REPLIES_LOADED:
            return {
                ...state,
                ...keyBy(action.replies, "id"),
            }
        case ActionTypes.REPLY_DELETED:
            return produce(state, draftState => {
                delete draftState[action.comment.id];
                return draftState;
            });
        case ActionTypes.REPLY_UPDATED:
        case ActionTypes.REPLY_POSTED:
            return {
                ...state,
                [action.comment.id]: action.comment,
            };
        case ActionTypes.REPLIES_RELOADED:
            return {
                ...state,
                ...keyBy(action.replyResult.results, "id"),
            };
        case ActionTypes.COMMENT_AND_REPLIES_ERROR:
            return {
                ...state,
                [action.commentId]: {
                    missing: true,
                },
            }
        default:
            return state;
    }

}

function totalComments(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENTS_LOADED:
            return {
                ...state,
                [action.articleId]: action.commentResponse.total,
            };
        case ActionTypes.COMMENTS_RELOADED:
            return {
                ...state,
                [action.articleId]: state[action.articleId] + action.commentResponse.total,
            };
        case ActionTypes.COMMENT_POSTED:
            return {
                ...state,
                [action.comment.article]: state[action.comment.article] + 1
            };
        case ActionTypes.COMMENT_DELETED:
            return {
                ...state,
                [action.comment.article]: state[action.comment.article] - 1
            };
        default:
            return state;
    }
}

function comments(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENTS_LOADED:
            return {
                ...state,
                ...keyBy(action.commentResponse.results, "id"),

                // TODO: move these to a different reducer so this is single use.
                total: action.commentResponse.total,
            };
        case ActionTypes.COMMENTS_RELOADED:
            return {
                ...state,
                ...keyBy(action.commentResponse.results, "id"),
                total: state.total + action.commentResponse.results.length,
            }
        case ActionTypes.COMMENT_UPDATED:
        case ActionTypes.COMMENT_POSTED:
            return {
                ...state,
                [action.comment.id]: action.comment,
            };
        case ActionTypes.COMMENT_DELETED:
            return omit(state, action.comment.id);
        default:
            return state;
    }
}

function replies(state={}, action) {
    switch (action.type) {
        case ActionTypes.REPLY_DELETED:
            return produce(state, draftState => {
                delete draftState[action.comment.id];
                return draftState;
            });
        case ActionTypes.REPLY_UPDATED:
        case ActionTypes.REPLY_POSTED:
            return {
                ...state,
                [action.comment.id]: action.comment,
            };
        case ActionTypes.COMMENTS_LOADED:
            const replies = keyBy(flatMap(action.commentResponse.results, (com) => {
                return com.replies.results;
            }), "id");
            return {
                ...state,
                ...replies,
            };
        case ActionTypes.REPLIES_LOADED:
        case ActionTypes.REPLIES_RELOADED:
            return {
                ...state,
                ...keyBy(action.replyResult.results, "id"),
            };
        default:
            return state;
    }
}

function articleLikes(state={}, action) {
    switch (action.type) {
        case ActionTypes.ARTICLE_LIKES_LOADED:
            return {
                ...state,
                [action.articleId]: action.likedBy,
            }
        case ActionTypes.ARTICLE_LIKED:
            return updateLikes(state, action.articleId, action.userProfile);
        default:
            return state;
    }
}

function commentLikes(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENT_LIKES_LOADED:
        case ActionTypes.REPLY_LIKES_LOADED:
            return {
                ...state,
                [action.commentId]: action.likedBy,
            }
        case ActionTypes.COMMENT_LIKED:
        case ActionTypes.REPLY_LIKED:
            return updateLikes(state, action.commentId, action.userProfile);
        default:
            return state;
    }
}


function siteNotifications(state={}, action) {
    switch (action.type) {
        case ActionTypes.SITE_NOTIFICATIONS_LOADED:
            return keyBy(action.siteNotifications, "id");
        case ActionTypes.SITE_NOTIFICATION_UPDATED:
        case ActionTypes.SITE_NOTIFICATION_CREATED:
        case ActionTypes.SITE_NOTIFICATION_DETAILS_LOADED:
            return {
                ...state,
                [action.siteNotification.id]: action.siteNotification,
            }
        default:
            return state;
    }
}


function politicians(state = {}, action) {
    switch (action.type) {
        case ActionTypes.POLITICIANS_LOADED:
            return keyBy(action.politicians, "id");
        case ActionTypes.POLITICIAN_LOADED:
            return {
                ...state,
                [action.politician.id]: action.politician,
            }
        default:
            return state;
    }
}


function ratings(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CURRENT_ORGANIZATION_LOADED:
            return keyBy(action.organization.ratings, "id");
        default:
            return state;
    }
}


export default combineReducers({
    articleLikes,
    articles,
    commentHierarchyComments,
    commentHierarchyReplies,
    commentLikes,
    comments,
    elections,
    featuredArticles,
    politicians,
    ratings,
    recentArticles,
    replies,
    siteNotifications,
    totalComments,
    electionDates,
    upcomingElections,
    pastElections,
});
