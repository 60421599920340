import React, {useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./AddressValidation.scss";

import AddressLookup from "@src/components/maps/AddressLookup";
import Button from "@src/components/shared/elements/Button";

import * as SignupActions from "@src/actions/SignupActions";
import * as MapActions from "@src/actions/MapActions";

import {formatPlaceData, isValidAddressForOrg} from "@src/utils/mappingUtils";
import {verifyLocation} from "@src/actions/MapActions";
import {createAddress} from "@src/api/MapApi";

function AddressValidation({
    clearMapLocation,
    createAddress,
    onContinue,
    organization,
    selectedLocation,
    setMapLocation,
}) {
    const [message, setMessage] = useState("");
    const [validAddress, setValidAddress] = useState("");

    useEffect(() => {
        if (selectedLocation) {
            const placeData = formatPlaceData(selectedLocation);
            createAddress(placeData);

            const isValidAddress = isValidAddressForOrg(placeData, organization);
            if (isValidAddress) {
                setValidAddress(placeData.googlePlaceId);
            } else {
                setMessage(`Not a valid ${organization.locationName} address`);
            }
        }
    }, [selectedLocation]);


    const handleClearSelectedLocation = () => {
        clearMapLocation();
        setValidAddress("");
    }

    const handlePlaceSelected = (placesInfo) => {
        setMessage("");
        setMapLocation(placesInfo);
    }

    const renderAddressValidation = () => {
        if (!validAddress) {
            return (<>
                <AddressLookup
                    loadScript
                    onPlaceSelected={handlePlaceSelected}
                    mapPosition={organization?.defaultMapPosition}
                    isSignUp
                />
            </>);
        }

        if (selectedLocation) {
            return (
                <>
                    <div className={styles.selectedLocation}>
                        <div className={styles.address}>{selectedLocation.formatted_address}</div>
                        <Button
                            className={styles.clearBtn}
                            buttonColor={"white"}
                            xsmall
                            onClick={handleClearSelectedLocation}
                            buttonText={"clear"}
                        />
                    </div>

                </>
            );
        }
    }

    const handleContinue = () => {
        onContinue();
    }

    return (
        <div className={styles.root}>
            <h2>Enter Your<br /> Williamson County Street Address</h2>
            {renderAddressValidation()}
            <div className={styles.continueButton}>
                {selectedLocation && message && <div className={styles.invalidLocation}>{message}</div>}
                <Button
                    onClick={handleContinue}
                    buttonText={"Continue"}
                    disabled={!validAddress}
                    wide
                />
            </div>
        </div>
    );
}


AddressValidation.propTypes = {
    onContinue: PropTypes.func.isRequired,
}

export default connect(
    (state) => {
        const organization = state.appState.currentOrganization;
        return {
            organization,
            selectedLocation: state.mapState.location,
            signupStatus: state.appState.signupStatus,
        };
    },
    (dispatch) => ({
        clearMapLocation: () => dispatch(MapActions.clearMapLocation()),
        createAddress: (placesInfo) => dispatch(MapActions.createAddress(placesInfo)),
        setMapLocation: (location) => dispatch(MapActions.setMapLocation(location)),
        signUpUser: (user) => dispatch(SignupActions.signUpUser(user)),
    })
)(AddressValidation);
