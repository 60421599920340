import React, {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import filter from "lodash/filter";
import map from "lodash/map";
import styles from "./ArticleTile.scss";
import moment from "moment";
import getStaticFile from "@src/utils/getStaticFile";
import {cleanPublicUrl} from "@src/utils/publicBucketUtils";

function ArticleTile({ article }) {
    const publishedDate = moment(article.publishedDate).format("MMMM DD, YYYY");
    const authorName = "Williamson County Citizens"; // article.author?.fullName || "Staff";
    // const defaultImage = useMemo(() => {
    //     const placeholderSrc = getStaticFile("img/map_image1.jpg");
    //     return {
    //         file: placeholderSrc,
    //         description: "An image for this article"
    //     }
    // }, []);

    const images = useMemo(() => {
        if (article?.media) {
            const allImages = map(filter(article.media, ["media.mediaType", "image"], ["inline", false]), mediaItem => {
                const { name, description, image } = mediaItem.media;
               return {
                   ...cleanPublicUrl(mediaItem.media.image),
                   name,
                   description
               };
            });
            return allImages;
        }
        return [];
    }, [article]);

    // const mainImage = useMemo(() => {
    //     if (images.length) {
    //         return images[0];
    //     }
    //     return defaultImage;
    // }, [images, defaultImage]);

    return (
        <Link className={classnames(styles.root, "topRedBorder")} to={`/news/${article.path}/`}>
            <div className={styles.date}>{publishedDate}</div>
            {/*<div className={styles.cardImage}>*/}
            {/*    <img src={mainImage.file} alt={mainImage.description} />*/}
            {/*</div>*/}
            <h3 className={styles.headline}>{article.headline}</h3>
            <div className={styles.author}>By {authorName}</div>
            <div className={styles.bodyText}>{article.summary}</div>
        </Link>
    );
}


ArticleTile.propTypes = {
    article: PropTypes.object.isRequired,
}

export default ArticleTile;
