// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ArticlesListPage__root{padding:0 0 3rem}.ArticlesListPage__root .ArticlesListPage__inner{flex:1;width:100%;max-width:800px;margin:0 auto 2rem;display:flex;flex-direction:column}
`, "",{"version":3,"sources":["webpack://./web/src/pages/app/ArticlesListPage/ArticlesListPage.scss"],"names":[],"mappings":"AAGA,wBACE,gBAAiB,CADnB,iDAII,MAAO,CACP,UAAW,CACX,eAAgB,CAChB,kBAAmB,CACnB,YAAa,CACb,qBAAsB","sourcesContent":["@import \"@commonstyles/vars.scss\";\n@import \"@commonstyles/colors.scss\";\n\n.root {\n  padding: 0 0 3rem;\n\n  .inner {\n    flex: 1;\n    width: 100%;\n    max-width: 800px;\n    margin: 0 auto 2rem;\n    display: flex;\n    flex-direction: column;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ArticlesListPage__root`,
	"inner": `ArticlesListPage__inner`
};
export default ___CSS_LOADER_EXPORT___;
