import React, {useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import { useNavigate } from "react-router-dom";
import map from "lodash/map";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./SignupPage.scss";

import AddressValidation from "./AddressValidation";
import Hero from "@src/components/Hero";
import SignupForm from "./SignupForm";
import Loader from "@src/components/shared/elements/Loader";
import * as SignupActions from "@src/actions/SignupActions";
import Modal from "@src/components/shared/elements/Modal";
import Button from "@src/components/shared/elements/Button";
import {setPageTitle} from "@src/utils/windowHelpers";

function SignupPage({
    addressLocation,
    clearSignupStatus,
    organization,
    requestEditLink,
    signupStatus,
    signUpUser
}) {
    const [loading, setLoading] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [currentStep, setCurrentStep] = useState("address");
    const [showEditConfirmation, setShowEditConfirmation] = useState(false);
    const [confirmUpdateInfo, setConfirmUpdateInfo] = useState(false);
    const [sentFormData, setSentFormData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        setPageTitle("Subscribe", window.location);
    }, []);

    useEffect(() => {
        if (signupStatus.success) {
            if (signupStatus.user) {
                window.location = `/signup-redirect/${signupStatus.user.user}/?vc=${signupStatus.user.redirectCode}`;
            }
        } else if (signupStatus.duplicate) {
            setLoading(null);
            setConfirmUpdateInfo(true);
        } else if (signupStatus.editRequested) {
            setShowEditConfirmation(true);
            setLoading(null);
        } else if (signupStatus.error) {
            setLoading(null);
            setErrorMessage(signupStatus.error);
        }
    }, [signupStatus]);


    const handleFormSubmit = (formData) => {
        setLoading("Setting Up Subscription");
        const formDataToSend = {
            ...formData,
            address: addressLocation?.id || null,
            password: window.ENV.TEMP_PASSWORD,
            organization: organization.id,
        };

        setSentFormData(formDataToSend);
        signUpUser(formDataToSend)
    };
    const handleAddressContinue = () => {
        setCurrentStep("form");
    }

    const renderCurrentStep = () => {
        switch (currentStep) {
            case "form":
                return <SignupForm onSubmit={handleFormSubmit} />;
            case "address":
            default:
                return <AddressValidation onContinue={handleAddressContinue} />;
        }
    }

    const handleEditRequest = () => {
        if (sentFormData) {
            requestEditLink(sentFormData.username);
            setLoading("Requesting Edit Link");
        } else {
            handleCancel();
        }
    }

    const handleCancel = () => {
        setLoading(null);
        setConfirmUpdateInfo(false);
        setShowEditConfirmation(false);
        setErrorMessage(null);
        clearSignupStatus();
        navigate("/");
    }

    return (
        <div className={styles.root}>
            {loading && <Loader loaderText={loading} overlay />}
            <Hero
                imageFilename={"wcc_hero_background_strip.jpg"}
                imageAltText={"American flag and Tennessee flying"}
                headerText={"Subscribe to WCC"}
                subpage
            />
            <main>
                <div className={styles.inner}>
                    {renderCurrentStep()}
                </div>
            </main>
            {confirmUpdateInfo && (
                <Modal
                    modalTitle={"You have already subscribed"}
                    show
                    small
                    onClose={handleCancel}
                >
                    <div>
                        <div>
                            If you would like to update your information, click the button below and a link will be sent to your email address.
                        </div>
                        <div className={"modal-buttons"}>
                            <Button onClick={handleEditRequest} buttonText={"Send me a link"} />
                            <Button onClick={handleCancel} buttonColor={"white"} buttonText={"Cancel"} />
                        </div>
                    </div>
                </Modal>
            )}
            {errorMessage && (
                <Modal
                    modalTitle={"Technical Difficulties"}
                    show
                    small
                    onClose={handleCancel}
                >
                    <div>
                        <div>
                            Sorry, there was a problem setting up your subscription. Please try again later.
                        </div>
                        <div className={"modal-buttons"}>
                            <Button onClick={handleCancel} buttonText={"Close"} />
                        </div>
                    </div>
                </Modal>
            )}
            {showEditConfirmation && (
                <Modal
                    modalTitle={"Edit Link Requested"}
                    show
                    small
                    onClose={handleCancel}
                >
                    <div>
                        <div>
                            Check your email for the link we just sent and use it to update your subscription information.
                        </div>
                        <div className={"modal-buttons"}>
                            <Button onClick={handleCancel} buttonText={"Close"} />
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
}


SignupPage.propTypes = {}

export default connect(
    (state) => {
        const organization = state.appState.currentOrganization;
        return {
            organization,
            addressLocation: state.user.addressLocation,
            signupStatus: state.appState.signupStatus,
        };
    },
    (dispatch) => ({
        signUpUser: (user) => dispatch(SignupActions.signUpUser(user)),
        requestEditLink: (email) => dispatch(SignupActions.requestEditLink(email)),
        clearSignupStatus: () => dispatch(SignupActions.clearSignupStatus()),
    })
)(SignupPage);
