
import {combineReducers} from "redux";
import * as ActionTypes from "@src/constants/ActionTypes";
import keyBy from "lodash/keyBy";

function newsletterSignup(state=false, action) {
    switch (action.type) {
        case ActionTypes.NEWSLETTER_SIGNUP_SUCCESS:
            return true;
        case ActionTypes.NEWSLETTER_SIGNUP_ERROR:
            return false;
        default:
            return state;
    }
}

function elections(state={}, action) {
    switch (action.type) {
        case ActionTypes.ELECTIONS_LOADED:
            return {
                ...state,
                ...keyBy(action.elections, "id"),
            }
        case ActionTypes.ELECTION_DETAILS_LOADED:
            return {
                ...state,
                [action.election.id]: action.election,
            }
        default:
            return state;
    }
}

export default combineReducers({
    newsletterSignup,
    elections
});
