import forEach from "lodash/forEach";
import map from "lodash/map";

export const findFeaturesAtLocation = (mapLayers, loc, google) => {
    const matched = {};
    forEach(mapLayers, (layer, layerKey) => {
        console.log("feature-utils :: layer :: layerKey = ", layerKey);
        // Loop through features
        forEach(layer.features, (feature, index) => {
            const poly = new google.maps.Polygon({
                paths: feature.paths,
                clickable: false
            });
            if (google.maps.geometry.poly.containsLocation(loc, poly)) {
                matched[layerKey] = {
                    layer: {
                        name: layer.name,
                        key: layerKey,
                        order: layer.order,
                    },
                    featureId: index,
                    ...feature,
                };
            }
        });
    })
    return matched;
}

export const getLayerFeatures = (mapLayers) => {
    const allLayers = {};
    forEach(mapLayers, (layer, layerKey) => {
        // Loop through features
        const features = map(layer.features, (feature, index) => ({
            layer: {
                name: layer.name,
                key: layerKey
            },
            featureId: index,
            ...feature,
        }));
        allLayers[layerKey] = {
            name: layer.name,
            key: layerKey,
            order: layer.order,
            features,
        };
    });
    return allLayers;
}
