import React from "react";
import {Provider} from "react-redux";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import styles from "./Root.scss";
import PropTypes from "prop-types";
import createWatermark from "@src/components/watermark";
import RootContainer from "@src/components/RootContainer";
import HomePage from "@src/pages/HomePage";
import AboutPage from "@src/pages/AboutPage";
import getStore from "@src/utils/getStore";
import * as urls from "@src/constants/ApplicationUrls";
import UserProfile from "@src/pages/app/UserProfile";
import ArticlesListPage from "@src/pages/app/ArticlesListPage";
import ArticleWrapper from "@src/pages/app/ArticleWrapper";
import AgencyDetails from "@src/pages/app/AgencyDetails";
import ElectionsDashboard from "@src/pages/app/ElectionsDashboard";
import ElectionDetails from "@src/pages/app/ElectionDetails";
import CandidateDetails from "@src/pages/app/CandidateDetails";
import ElectedOfficialsPage from "@src/pages/app/ElectedOfficialsPage";
import ElectedOfficialsDetails from "@src/pages/app/ElectedOfficialsDetails";
import EditorWrapper from "@src/pages/editor/EditorWrapper";
import EditorDashboard from "@src/pages/editor/EditorDashboard";
import MapListPage from "@src/pages/MapListPage";
import MapPageWrapper from "@src/pages/MapPage/MapPageWrapper";
import ElectionWrapper from "@src/pages/app/ElectionWrapper";
import ElectionsCalendar from "@src/pages/elections/ElectionsCalendar";
import VotingLocations from "@src/pages/VotingLocations";
import SignupPage from "@src/pages/SignupPage";
import WelcomePage from "@src/pages/WelcomePage";
import ContactPage from "@src/pages/ContactPage";
import TermsPage from "@src/pages/TermsPage";
import PrivacyPage from "@src/pages/PrivacyPage";
import SubscriptionVerifyPage from "@src/pages/SubscriptionVerifyPage";
import {CONTACT, USER_VERIFIED} from "@src/constants/ApplicationUrls";

function Root(props) {
    createWatermark();
    return (
        <Provider store={getStore()}>
            <BrowserRouter basename="/">
                <RootContainer>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path={urls.ABOUT} element={<AboutPage />} />
                        <Route path={urls.PROFILE} element={<UserProfile />} />
                        <Route path={urls.SUBSCRIBE} element={<SignupPage />} />
                        <Route path={urls.SUBSCRIPTION_PENDING} element={<SubscriptionVerifyPage />} />
                        <Route path={urls.USER_VERIFIED} element={<WelcomePage />} />
                        <Route path={urls.CONTACT} element={<ContactPage />} />
                        <Route path={urls.PRIVACY} element={<PrivacyPage />} />
                        <Route path={urls.TERMS_AND_CONDITIONS} element={<TermsPage />} />

                        {/* Public Routes ?? */}
                        <Route path={urls.ARTICLE_LIST} element={<ArticlesListPage />} />
                        <Route path={urls.ARTICLE} element={<ArticleWrapper />} />
                        <Route path={urls.AGENCY_DETAILS} element={<AgencyDetails />} />
                        <Route path={urls.ELECTIONS} element={<ElectionsCalendar />} />
                        <Route path={urls.ELECTION_DETAILS} element={<ElectionWrapper />} />
                        <Route path={urls.ELECTION_CANDIDATE_DETAILS} element={<CandidateDetails />} />
                        <Route path={urls.AGENCY_ELECTED_OFFICIALS} element={<ElectedOfficialsPage />} />
                        <Route path={urls.AGENCY_ELECTED_OFFICIAL_DETAILS} element={<ElectedOfficialsDetails />} />
                        <Route path={urls.ALL_MAPS} element={<MapListPage allMaps />}/>
                        <Route path={urls.LOCAL_GOVERNMENT_PAGE} element={<MapPageWrapper />} />
                        <Route path={urls.LOCAL_GOVERNMENT} element={<MapListPage allMaps />} />
                        <Route path={urls.VOTING_LOCATIONS} element={<VotingLocations />} />


                        {/* Admin Routes */}
                        <Route path={urls.ARTICLE_CREATE} element={<EditorWrapper editorType={"article"} isNew />} />
                        <Route path={urls.ARTICLE_EDIT} element={<EditorWrapper editorType={"article"}  />} />
                        <Route path={urls.ELECTION_CREATE} element={<EditorWrapper editorType={"election"} isNew />} />
                        <Route path={urls.ELECTION_EDIT} element={<EditorWrapper editorType={"election"} />} />
                        <Route path={urls.EDITOR_DASHBOARD} element={<EditorDashboard />} />
                    </Routes>
                </RootContainer>
            </BrowserRouter>
        </Provider>
    );
}

Root.propTypes = {};


// if (DEVELOPMENT) {
//     Root = hot(module)(Root);
// }
export default Root;
