import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./AdminAccessWrapper.scss";
import Button from "@src/components/shared/elements/Button";

function AdminAccessWrapper({
    userProfile,
    children,
}) {
    const isLoggedIn = useMemo(() => {
        return window.ENV.IS_LOGGED_IN
    }, []);

    const hasAdminAccess = useMemo(() => {
        if (!isLoggedIn) return false;
        return userProfile?.isAdmin;
    }, [userProfile, isLoggedIn]);

    if (hasAdminAccess) {
        return (
            <>{children}</>
        );
    }

    return (
        <div className={styles.root}>
            <div className={styles.innerContent}>
                <h2>No Access</h2>
                <Link to={"/"}>
                    <Button buttonText={"Go to Homepage"} large />
                </Link>
            </div>
        </div>
    );
}


AdminAccessWrapper.propTypes = {}

export default connect(
    (state, ownProps) => ({
        userProfile: state.user.userProfile,
    }),
    (dispatch) => ({
        // function: () => dispatch(ACTION.function()),
    })
)(AdminAccessWrapper);
