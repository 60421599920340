import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";

import ArticleEditor from "@src/pages/editor/ArticleEditor";
import ElectionEditor from "@src/pages/editor/ElectionEditor";

function EditorWrapper({editorType, isNew }) {
    const params = useParams();

    switch (editorType) {
        case "article":
            return <ArticleEditor params={params} isNew={isNew}/>
        case "election":
            return <ElectionEditor params={params} isNew={isNew}/>
        default:
            return <div><h1>EditorType "{editorType}" does not match any Editor</h1></div>
    }
}

export default EditorWrapper;
