import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./InfoMessage.scss";
import Panel from "@src/components/shared/widgets/Panel";

function InfoMessage({
    title = "Information",
    contentType = "info",
    messageContent,
    light,
}) {
    const panelColor = useMemo(() => {
        switch (contentType) {
            case "success":
                return "green";
            case "danger":
                return "red";
            case "warning":
                return "yellow";
            default:
                return "blue";
        }
    }, [contentType]);
    return (
        <div className={styles.root}>
            <Panel
                title={title}
                light={light}
                color={panelColor}
            >
                <div className={styles.messageContent}>
                    {messageContent}
                </div>
            </Panel>
        </div>
    );
}


InfoMessage.propTypes = {
    title: PropTypes.string,
    messageContent: PropTypes.any.isRequired,
    light: PropTypes.bool,
    contentType: PropTypes.oneOf(["info", "success", "warning", "danger"]),
}

export default InfoMessage;
