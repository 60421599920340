import map from "lodash/map";

export const FEED = "feed";
export const BANNER = "banner";
export const POPUP = "popup";
export const FULLSCREEN = "fullscreen";

export const VERY_LOW = "Very Low";
export const LOW = "Low";
export const MEDIUM = "Medium";
export const HIGH = "High";
export const VERY_HIGH = "Very High (red color)";

// IN ORDER TO ADD A LEVEL YOU MUST ALSO ADD IT TO THE CHOICES IN THE MODEL
// TODO: Migrate priority to not use choices ??
export const PRIORITY_LEVELS = {
    [VERY_LOW]: 0,
    [LOW]: 25,
    [MEDIUM]: 50,
    [HIGH]: 75,
    [VERY_HIGH]: 100,
}

export const PRIORITY_CLASSES = {
    [VERY_LOW]: "veryLow",
    [LOW]: "low",
    [MEDIUM]: "medium",
    [HIGH]: "high",
    [VERY_HIGH]: "veryHigh",
}

export function getPriorityName(priority) {
    switch(priority) {
        case 100:
            return VERY_HIGH;
        case 75:
            return HIGH;
        case 50:
            return MEDIUM;
        case 25:
            return LOW;
        case 0:
            return VERY_LOW;
        default:
            return "";
    }
}

export function getPriorityClassName(priority) {
    return PRIORITY_CLASSES[priority] || PRIORITY_CLASSES[getPriorityName(priority)];
}

export const PRIORITY_LEVEL_OPTIONS = map(PRIORITY_LEVELS, (value, level) => ({ name: level, value}));

export const NOTIFICATION_TYPE_OPTIONS = [
    {
        name: "Banner Notification",
        value: "banner"
    },
    {
        name: "Feed Notification",
        value: "feed"
    }
    // {
    //     name: "Popup Notification",
    //     value: "popup"
    // },
    // {
    //     name: "Fullscreen Notification",
    //     value: "fullscreen"
    // },

];

export const WARNING = "warning";
export const SYSTEM = "system";
export const INFO = "info";
export const EVENT = "event";
export const REMINDER = "reminder";

export const NOTIFICATION_STYLES = {
    WARNING: WARNING,
    SYSTEM: SYSTEM,
    INFO: INFO,
    EVENT: EVENT,
    REMINDER: REMINDER,
}

const NOTIFICATION_HEADERS = {
    [WARNING]: {
        icon: "fas fa-exclamation-circle",
        text: "Important Message",
    },
    [SYSTEM]: {
        icon: null,
        text: "System Message",
    },
    [INFO]: {
        icon: "fas fa-info-circle",
        text: "Information",
    },
    [EVENT]: {
        icon: "fas fa-calendar",
        text: "Event Information",
    },
    [REMINDER]: {
        icon: "fas fa-alarm-exclamation",
        text: "Reminder"
    },
    default: {
        icon: null,
        text: "Message"
    }
}

export const NOTIFICATION_STYLE_OPTIONS = [
    {
        name: "System message",
        value: SYSTEM,
    },
    {
        name: "Important message",
        value: WARNING,
    },
    {
        name: "Informational message",
        value: INFO,
    },
    {
        name: "Event information",
        value: EVENT,
    },
    {
        name: "Reminder",
        value: REMINDER,
    }
]

export const getNotificationHeaderText = (type) => {
    const headerType = type || "default";
    return NOTIFICATION_HEADERS[headerType];
}
