import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import styles from "./UserInitials.scss";
import { getRandomFromSet, checkColorBrightness } from "@src/utils/colorUtils";
import * as UserActions from "@src/actions/UserActions";
function UserInitials({ sourceColor = "red", userProfile, loadCurrentUser }) {
    useEffect(() => {
        if (!userProfile?.username) {
            loadCurrentUser();
        }
    }, [userProfile]);


    const colorStyles = useMemo(() => {
        const color = getRandomFromSet(sourceColor);
        const colorBrightness = checkColorBrightness(color);
        const textColor = colorBrightness === "dark" ? "#FFFFFF" : "inherit";
        return {
            // backgroundColor: color,
            // color: textColor,
        }
    }, []);

    const userInitials = useMemo(() => {
        if (userProfile?.username) {
            return `${userProfile.firstName.substring(0,1)}${userProfile.lastName.substring(0,1)}`;
        }
        return "UNK";
    }, [userProfile]);

    return (
        <div className={styles.root} style={colorStyles}>{userInitials}</div>
    );
}


UserInitials.propTypes = {
    sourceColor: PropTypes.string,
}

export default connect(
        (state, ownProps) => ({
        userProfile: state.user.userProfile,
    }),
    (dispatch) => ({
        loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
    })
)(UserInitials);
