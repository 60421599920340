// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ElectedOfficialsPage__root{padding:0 2rem}.ElectedOfficialsPage__root .ElectedOfficialsPage__links{display:flex;flex-direction:column}.ElectedOfficialsPage__root .ElectedOfficialsPage__links>a{font-size:1rem;margin-bottom:0.25rem}
`, "",{"version":3,"sources":["webpack://./web/src/pages/app/ElectedOfficialsPage/ElectedOfficialsPage.scss"],"names":[],"mappings":"AAGA,4BACE,cAAe,CADjB,yDAGI,YAAa,CACb,qBAAsB,CAJ1B,2DAOM,cAAe,CACf,qBAAsB","sourcesContent":["@import \"@commonstyles/vars.scss\";\n@import \"@commonstyles/colors.scss\";\n\n.root {\n  padding: 0 2rem;\n  .links {\n    display: flex;\n    flex-direction: column;\n\n    > a {\n      font-size: 1rem;\n      margin-bottom: 0.25rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ElectedOfficialsPage__root`,
	"links": `ElectedOfficialsPage__links`
};
export default ___CSS_LOADER_EXPORT___;
