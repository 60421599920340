import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styles from "./AddressLookup.scss";
import {
    APIProvider,
} from '@vis.gl/react-google-maps';
import PlacesAutoComplete from "@src/components/maps/Google/PlacesAutoComplete";

function AddressLookup({ mapPosition, onPlaceSelected, isSignUp }) {

    if (!isSignUp && !mapPosition) {
        return null;
    }

    const handlePlaceSelected = (placeDetails) => {
        onPlaceSelected(placeDetails);
    }

    const getAreaBounds = () => {
        if (mapPosition) {
            const {north, south, east, west} = mapPosition;

            if (!!north && !!south && !!east && !!west) {
                return {
                    north,
                    east,
                    west,
                    south
                }
            }
        }
        return null;
    };
    const areaBounds = getAreaBounds();
    if (!window.ENV.GOOGLE_API_KEY) {
        return null;
    }
    return (
        <>
            {!isSignUp  && (
                <div>
                    Enter your home address to view the list of your Government Officials
                </div>
            )}
            <div className={styles.root}>
                <APIProvider apiKey={window.ENV.GOOGLE_API_KEY}>
                    <PlacesAutoComplete
                        onPlaceSelect={handlePlaceSelected}
                        areaBounds={areaBounds}
                    />
                </APIProvider>
            </div>

        </>
    );
}


AddressLookup.propTypes = {
    mapPosition: PropTypes.object,
    onPlaceSelected: PropTypes.func.isRequired,
    isSignUp: PropTypes.bool,
}

export default AddressLookup;
