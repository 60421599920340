import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import map from "lodash/map";
import find from "lodash/find";
import moment from "moment";
import classnames from "classnames";
import styles from "./ElectionDetails.scss";
import {Link} from "react-router-dom";
import * as ElectionActions from "@src/actions/ElectionActions";
import ElectionCardInfo from "@src/components/elections/ElectionCardInfo";
import ElectionCalendarItem from "@src/components/elections/ElectionCalendarItem";
import {setPageTitle} from "@src/utils/windowHelpers";

function ElectionDetails({ electionDate, electionDateId, loadElectionDate }) {
    useEffect(() => {
        if (electionDateId) {
            loadElectionDate(electionDateId);
        }
    }, [electionDateId]);

    const votingDate = useMemo(() => {
        if (electionDate?.votingDate) {
            return moment(electionDate.votingDate).format("MMMM Do YYYY");
        }
        return null;
    }, [electionDate]);

    useEffect(() => {
        setPageTitle(`${votingDate} :: Election`, window.location);
    }, [votingDate]);

    if (!electionDate) {
        return <div>LOADING ... </div>
    }

    return (
        <div className={styles.root}>

            <div className={styles.inner}>
                <div className={styles.electionDate}>
                    <Link className={styles.backLink} to={"/elections"}><i className={"fa-solid fa-chevron-left"} /> Election Calendar</Link>
                    <h1>{votingDate}</h1>
                    <div className={styles.after} />
                </div>
                <ElectionCalendarItem config={electionDate} key={electionDate.id} hideVotingDate />
            </div>
        </div>
    );
}


ElectionDetails.propTypes = {
    electionDateId: PropTypes.string,
}

ElectionDetails.defaultProps = {}

export default connect(
    (state, ownProps) => ({
        electionDate: state.entities.electionDates[ownProps.electionDateId],
    }),
    (dispatch) => ({
        loadElectionDate: (electionDateId) => dispatch(ElectionActions.loadElectionDate(electionDateId)),
    })
)(ElectionDetails);
