import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./OpportunityTag.scss";
import IconTag from "@src/components/IconTag";
import { Tooltip } from 'react-tooltip'
function OpportunityTag({
    opportunity,
}) {
    console.log("OpportunityTag :: OpportunityTag :: opportunity.notes = ", opportunity.notes);
    const opportunityToolTip = useMemo(() => {
        const notes = opportunity?.notes || "None... all is lost";
        return `OPPORTUNITY: ${notes}`;
    }, [opportunity]);


    return (
        <>
            <Tooltip id="opportunity-tooltip" />
            <div
                className={styles.root}
                data-tooltip-id="opportunity-tooltip"
                data-tooltip-content={opportunityToolTip}
            >
                <IconTag labelText={"Notes"} iconName={"memo-pad"} color={"white"} solicIcon />
            </div>
        </>
    );
}


OpportunityTag.propTypes = {
    opportunity: PropTypes.object.isRequired,
}

export default OpportunityTag;
