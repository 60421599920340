import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ElectionCardInfo.scss";
import map from "lodash/map";
import PrimaryTag from "@src/components/elections/PrimaryTag";
import MapTag from "@src/components/elections/MapTag";
import Panel from "@src/components/shared/widgets/Panel";
import ElectionSeatInfo from "@src/components/elections/ElectionSeatInfo";

function ElectionCardInfo({ election, isPast }) {

    const seats = map(election.seats, seat => (
        <ElectionSeatInfo seat={seat} key={seat.id} />
    ));

    // const extras = useMemo(() => {
    //     if (!election) {
    //         return [];
    //     }
    //     return (
    //         <div className={styles.extras}>
    //             <PrimaryTag isPrimary={election.isPrimary} />
    //             <MapTag slug={election.map} />
    //         </div>
    //     )
    // }, [election]);

    const noSeatsMessage = useMemo(() => {
        if (isPast) {
            return <h3>No {election.name} Races</h3>;
        }
        return (
            <>
                <h3>Sample Ballot Not Available</h3><p>This will be updated as soon as the ballot becomes available</p>
            </>
        )
    }, [isPast])

    return (
        <Panel
            title={election.name}
            light
            isChild
        >
            <div className={styles.seats}>
                {!!seats.length ? (
                    <>{seats}</>
                ): (
                    <div className={styles.noBallot}>
                        {noSeatsMessage}
                    </div>
                ) }
            </div>
        </Panel>
    );
}


ElectionCardInfo.propTypes = {
    isPast: PropTypes.bool,
}

export default ElectionCardInfo;
