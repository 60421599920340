import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import forEach from "lodash/forEach";
import flatMap from "lodash/flatMap";
import loMap from "lodash/map";
import classnames from "classnames";
import styles from "./GoogleMaps.scss";
import MapMarker from "@src/components/maps/Google/MapMarker";
import TextMarker from "@src/components/maps/Google/TextMarker";
import {mapStyle} from '@src/components/maps/Google/utils/map-style';
import {findFeaturesAtLocation} from "@src/components/maps/Google/utils/feature-utils";
import WithErrorBoundary from "@src/components/shared/elements/WithErrorBoundary";
import {getRandomFromSet} from "@src/utils/colorUtils";
import {cleanPublicUrl} from "@src/utils/publicBucketUtils";

import * as MapActions from "@src/actions/MapActions";
import {MOBILE, PORTRAIT} from "@src/constants/WindowConstants";


function GoogleMaps({
    children,
    setMapLayer,
    selectedLocation,
    mapConfig,
    mapLayers,
    onLoaded,
    screenSizes,
    setUserLocations,
    showBorder,
}) {
    const [googleMapRef, setGoogleMapRef] = useState(null);
    const [googleRef, setGoogleRef] = useState(null);

    useEffect(() => {
        if (selectedLocation) {
            const loc = selectedLocation.geometry.location;
            const matchingLocations = findFeaturesAtLocation(mapLayers, loc, google);
            setUserLocations(matchingLocations);
        }
    }, [selectedLocation, mapLayers]);

    const layerLabels = useMemo(() => {
        return flatMap(mapConfig.mapLayers, layer => {
            return loMap(layer.geoLocation.labels, mapLabel => (
                <TextMarker
                    key={`layer_${layer.order}_label_${mapLabel.featureId}_marker`}
                    text={mapLabel.label}
                    lat={Number(mapLabel.latitude)}
                    lng={Number(mapLabel.longitude)}
                    light={layer.layerOptions.lightLabels}
                />
            ));
        });
    }, [mapConfig]);

    const defaultPosition = useMemo(() => {
        if (mapConfig?.mapPosition) {

            const { zoomLevel, centerLat, centerLng, scale, minZoom } = mapConfig.mapPosition;
            const { size } = screenSizes || {};

            let lat = 0;
            let lng = 0;
            const zoom = size === MOBILE ? minZoom : zoomLevel;
            try {
                lat = Number(centerLat);
                lng = Number(centerLng);
            } catch (err) {
                console.error(
                    `Error setting map lat/lng: mapConfig.mapPosition.centerLat: ${centerLat} / mapConfig.mapPosition.centerLng: ${centerLng}`,
                    err,
                );
            }

            return {lat, lng, zoom, scale};
        }
        return { lat: 0, lng: 0, zoom: 15 }
    }, [mapConfig]);

    const mapStyleObject = useMemo(() => {
        const configStyles = {}
        const { mapPosition } = mapConfig;
        const mobilePortrait = screenSizes.size === MOBILE && screenSizes.orientation === PORTRAIT;
        if (mapPosition.scale !== 1 || mapPosition.mobileScale !== 1) {
            const usedScale = mobilePortrait ? mapPosition.mobileScale : mapPosition.scale;
            configStyles.zoom = usedScale;

            if (mobilePortrait && usedScale < 0.75) {
                configStyles.height = Math.round(300 / usedScale);
            }

        }
        return configStyles;
    }, [mapConfig]);


    const apiIsLoaded = ({map, maps, places}) => {
        const styleObject = mapStyle(google);
        map.mapTypeId = "styled_map";
        map.mapTypes.set("styled_map", styleObject);
        setDataLayers(map, maps);

        if(onLoaded) {
            onLoaded();
        }
        setGoogleMapRef(map);
        setGoogleRef(maps);
    };


    const handleLayerLoaded = (map, maps, layer, featuresArray) => {
        const featuresData = featuresArray.map(feature => {
            const area = feature.getGeometry()
            const paths = area.getAt(0).getArray()
            return {
                id: feature.getProperty("OBJECTID"),
                district: feature.getProperty("DISTRICT") | "",
                precinct: feature.getProperty("PRECINCT") || "",
                area,
                paths,
                label: feature.getProperty("NAME"),
                data: feature,
            }
        });

        const layerData = {
            ...layer.geoLocation,
            features: featuresData,
            order: layer.order,
        };
        setMapLayer(mapConfig.slug, layerData);
    }

    const setDataLayers = (map, maps) => {
        if (map) {
            if (!google) return;
            // Google api is not ready

            forEach(mapConfig.mapLayers, (layer) => {
                const isVisible = layer.showData;
                const { layerOptions, geoLocation } = layer;
                const backupColor = getRandomFromSet("all");
                const strokeWeight = isVisible ? layerOptions.borderThickness || 2 : 0;
                const fillOpacity = isVisible && layerOptions.fillOpacity !== null ? layerOptions.fillOpacity : 0;
                const layerStyle = {
                    strokeColor: layerOptions.borderColor || backupColor,
                    strokeWeight,
                    fillOpacity,
                };
                if (layerOptions.fillOpacity) {
                    layerStyle.fillColor = layerOptions.fillColor || backupColor;
                }

                const { geojson, geojsonFile } = geoLocation;

                const geojsonFilePath = cleanPublicUrl(geojson?.file) || geojsonFile;
                if (geojsonFilePath) {
                    const dataLayer = new google.maps.Data({map: map});
                    dataLayer.loadGeoJson(
                        geojsonFilePath,
                        {
                            idPropertyName: layer.geoLocation.featureId,
                        },
                        (featuresArray) => handleLayerLoaded(map, maps, layer, featuresArray),
                    );
                    dataLayer.setStyle(layerStyle);
                    dataLayer.addListener("click", (event) => {
                        const district = event.feature.getProperty("DISTRICT");
                        const precinct = event.feature.getProperty("PRECINCT");
                        // console.log("GoogleMaps :: dataLayer.click :: district = ", district);
                        // console.log("GoogleMaps :: dataLayer.click :: precinct = ", precinct);
                    });
                }
            });
        }
    };
    // if (selectedLocation) {
    //     console.log(`lat={${selectedLocation?.geometry.location.lat()}}
    //                 lng={${selectedLocation?.geometry.location.lng()}}`)
    // }

    const createMapOptions = (maps) => {
        const { mapPosition } = mapConfig;
        const mapOptions = {
            maxZoom: mapPosition.maxZoom,
            minZoom: mapPosition.minZoom,
        };

        if (mapPosition.locked) {
            mapOptions.restriction = {
                latLngBounds: {
                  north: mapPosition.north,
                  south: mapPosition.south,
                  west: mapPosition.west,
                  east: mapPosition.east,
                },
                strictBounds: false,
            };
        }
        return mapOptions;
    }

    return (
        <WithErrorBoundary
            componentName={"GoogleMaps"}
        >
            <div className={classnames(styles.root, {
                [styles.bordered]: showBorder,
                [styles.mobile]: screenSizes.size === MOBILE,
            })} style={mapStyleObject}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: window.ENV.GOOGLE_API_KEY, mapId: "8e0a97af9386fef"}}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={(mapInfo) => apiIsLoaded(mapInfo)}
                    defaultCenter={{
                        lat: defaultPosition.lat,
                        lng: defaultPosition.lng,
                    }}
                    defaultZoom={defaultPosition.zoom}
                    options={createMapOptions}
                    // onClick={({x, y, lat, lng, event}) => console.log(x, y, lat, lng, event)}
                >
                    {selectedLocation && (
                        <MapMarker
                            key={selectedLocation.id}
                            text={selectedLocation.name}
                            lat={selectedLocation.geometry.location.lat()}
                            lng={selectedLocation.geometry.location.lng()}
                        />
                    )}
                    {/*{layerLabels}*/}
                    {children}
                </GoogleMapReact>
            </div>
        </WithErrorBoundary>
    );
}


GoogleMaps.propTypes = {
    mapConfig: PropTypes.object.isRequired,
    screenSizes: PropTypes.shape({
        size: PropTypes.string,
        orientation: PropTypes.string,
    }),
    onLoaded: PropTypes.func,
    onMatchedLocations: PropTypes.func,
    showBorder: PropTypes.bool,
}

GoogleMaps.defaultProps = {}

export default connect(
    (state, ownProps) => {
        const {mapConfig} = ownProps;
        const mapSlug = mapConfig?.slug;
        return {
            mapLayers: state.mapState.mapLayers[mapSlug],
            selectedLocation: state.mapState.location,
        }
    },
    (dispatch) => ({
        setMapLayer: (slug, layerData) => dispatch(MapActions.setMapLayer(slug, layerData)),
        setUserLocations: (locations) => dispatch(MapActions.setUserLocations(locations)),
    })
)(GoogleMaps);
