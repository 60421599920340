import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./FeaturesList.scss";
import filter from "lodash/filter";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import {getLayerFeatures} from "@src/components/maps/Google/utils/feature-utils";
import FeaturesListItem from "@src/components/maps/FeaturesList/FeaturesListItem";
import Panel from "@src/components/shared/widgets/Panel";

function FeaturesList({
    mapSlug,
    mapLayers,
}) {
    const renderElectionsInfo = (elected = true, appointed = false) => {
        const allLayers = getLayerFeatures(mapLayers);
        return map(sortBy(allLayers, ["order"]), layer => {
            if (layer.key === "countyMap") {
                return null;
            }
            return (
                <FeaturesListItem
                    layer={layer}
                    mapSlug={mapSlug}
                    key={layer.key}
                    appointed={appointed}
                    elected={elected}
                />
            );
        })
    }

    const renderElectedOfficials = () => {
        const withElected = filter(mapLayers, layer => layer.seats?.length);
        if (!withElected.length) {
            return null;
        }

        return (
            <Panel
                title={"Elected Officials"}
            >
                {renderElectionsInfo()}
             </Panel>
        );
    }

    const renderAppointedOfficials = () => {
        const withAppointed = filter(mapLayers, layer => layer.agencySeats?.length);
        if (!withAppointed.length) {
            return null;
        }
        return (
            <Panel
                title={"Appointed Officials"}
            >
                {renderElectionsInfo(false, true)}
            </Panel>
        );
    }

    return (
        <>
            {renderElectedOfficials()}
            {renderAppointedOfficials()}
        </>
    );

}


FeaturesList.propTypes = {
    mapSlug: PropTypes.string.isRequired,
}

export default connect(
    (state, ownProps) => ({
        mapLayers: state.mapState.mapLayers[ownProps.mapSlug],
    }),
    (dispatch) => ({
        // function: () => dispatch(ACTION.function()),
    })
)(FeaturesList);
