import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./LoginLink.scss";
import UserInitials from "@src/components/shared/elements/UserInitials";

function LoginLink({ isLoggedIn }) {
    if (isLoggedIn) {
        return (
            <div className={styles.root}>
                <Link to={"/user-profile"}>
                    <UserInitials />
                </Link>

            </div>
        );
    }

    return (
        <div className={styles.root}>
            <a href={"/login/?next=/"}>Login</a> / <a href={"/signup/"}>Signup</a>
        </div>
    );
}


LoginLink.propTypes = {
    isLoggedIn: PropTypes.bool,
}

export default LoginLink;
