import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import styles from "./MapTag.scss";
import Icon from "@src/components/shared/elements/Icon";

function MapTag({ hideLabel, slug }) {
    if (!slug) {
        return null;
    }

    const label = useMemo(() => {
        if (!hideLabel) {
            return <span className={styles.label}>Map</span>
        }
        return null;
    }, [hideLabel]);

    return (
        <Link className={styles.root} to={`/maps/${slug}`}>
            <Icon iconName={"map-location-dot"} solid />
            {label}
        </Link>
    );
}


MapTag.propTypes = {
    slug: PropTypes.string,
    hideLabel: PropTypes.bool,
}

export default MapTag;

