import {requestError} from "@src/utils/request";
import * as ArticlesApi from "@src/api/ArticlesApi";
import * as ActionTypes from "@src/constants/ActionTypes";
import {toaster} from "@src/utils/toaster";
import {ARTICLE_DETAILS_LOADED} from "@src/constants/ActionTypes";

export function createArticle(data) {
    return (dispatch) => {
        ArticlesApi.createArticle(data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.ARTICLE_CREATED,
                    article: res.body,
                    posterId: data.posterId,
                });
                toaster.success("New Article Created!");
            })
            .catch((err) => {
                requestError(err, "Error creating article");
            });
    };
}

export function creatingArticle() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CREATING_ARTICLE
        })
    }
}
export function editArticle(articleId) {
    return (dispatch) => {
        ArticlesApi.loadArticle(articleId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.EDITING_ARTICLE,
                    article: res.body
                });
            })
            .catch((err) => {
                requestError(err, "Error loading article");
            });
    }
}

export function updateArticle(articleId, data) {
    return (dispatch) => {
        ArticlesApi.updateArticle(articleId, data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.ARTICLE_UPDATED,
                    article: res.body,
                    posterId: data.posterId,
                });
                toaster.success("Article Saved!");
            })
            .catch((err) => {
                requestError(err, "Error updating article");
            });
    };
}

export function publishArticle(articleId, data) {
    return (dispatch) => {
        ArticlesApi.updateArticle(articleId, data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.ARTICLE_PUBLISHED,
                    article: res.body,
                    posterId: data.posterId,
                });
                toaster.success("Article Published");
            })
            .catch((err) => {
                requestError(err, "Error publishing article");
            });
    };
}

export function loadArticles() {
    return (dispatch) => {
        ArticlesApi.loadArticles()
            .then((res) => {
                dispatch({
                    type: ActionTypes.ARTICLE_FEED_LOADED,
                    articles: res.body
                });
            })
            .catch((err) => {
                requestError(err, "Error loading articles");
            });
    }
}

export function loadRecentArticles() {
    return (dispatch) => {
        ArticlesApi.loadRecentArticles()
            .then((res) => {
                dispatch({
                    type: ActionTypes.RECENT_ARTICLES_LOADED,
                    recentArticles: res.body
                });
            })
            .catch((err) => {
                requestError(err, "Error loading articles");
            });
    }
}

export function loadArticleBySlug(articlePath) {
    return (dispatch) => {
        ArticlesApi.loadArticleBySlug(articlePath)
            .then((res) => {
                dispatch({
                    type: ActionTypes.ARTICLE_DETAILS_LOADED,
                    article: res.body
                });
            })
            .catch((err) => {
                requestError(err, "Error loading articles");
            });
    }
}
