import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styles from "./MapListPage.scss";
import MapPage from "@src/pages/MapPage";
import Hero from "@src/components/Hero";
import {setPageTitle} from "@src/utils/windowHelpers";
import MapListContent from "@src/components/maps/MapListContent";

function MapListPage({ allMaps }) {
    const [defaultMap, setDefaultMap] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        setPageTitle("All Maps", window.location);
    }, []);



    if (defaultMap) {
        return <MapPage mapSlug={defaultMap} key={`map_${defaultMap}`} />
    }

    const handleHasDefault = (mapSlug) => {
        if (!allMaps) {
            setDefaultMap(mapSlug);
        }
    }

    return (
        <div className={styles.root}>
            <Hero
                imageFilename={"wcc_hero_background_strip.jpg"}
                imageAltText={"American flag and Tennessee flying"}
                headerText={"Your Local Government"}
                subpage
            />
            <div className={styles.inner}>
                <MapListContent onHandleHasDefault={handleHasDefault} />
            </div>
        </div>
    );


}


MapListPage.propTypes = {
    allMaps: PropTypes.bool,
}
export default MapListPage;
