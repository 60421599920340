import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./RatingsTag.scss";
import Icon from "@src/components/shared/elements/Icon";
import {cleanPublicUrl} from "@src/utils/publicBucketUtils";

function RatingsTag({ rating, small }) {
    if (!rating) {
        return null;
    }

    const { image, iconName = "hand-holding-seedling" } = rating;
    if (image) {
        return <div className={classnames(styles.root, {
            [styles.small]: small,
        })}>
            <div className={styles.imageWrapper}>
                <div className={styles.image}>
                    <img src={cleanPublicUrl(image)} alt={rating.message} />
                </div>
            </div>
        </div>
    }
    return (
        <div className={styles.root}><Icon iconName={iconName} solid colorHex={"#399337"}  /></div>
    );
}


RatingsTag.propTypes = {
    rating: PropTypes.object,
    small: PropTypes.bool,
}

export default RatingsTag;
