// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ElectionsDashboard__root{padding:0 2rem}.ElectionsDashboard__root .ElectionsDashboard__links{display:flex;flex-direction:column}.ElectionsDashboard__root .ElectionsDashboard__links>a{font-size:1rem;margin-bottom:0.25rem}
`, "",{"version":3,"sources":["webpack://./web/src/pages/app/ElectionsDashboard/ElectionsDashboard.scss"],"names":[],"mappings":"AAGA,0BACE,cAAe,CADjB,qDAGI,YAAa,CACb,qBAAsB,CAJ1B,uDAOM,cAAe,CACf,qBAAsB","sourcesContent":["@import \"@commonstyles/vars.scss\";\n@import \"@commonstyles/colors.scss\";\n\n.root {\n  padding: 0 2rem;\n  .links {\n    display: flex;\n    flex-direction: column;\n\n    > a {\n      font-size: 1rem;\n      margin-bottom: 0.25rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ElectionsDashboard__root`,
	"links": `ElectionsDashboard__links`
};
export default ___CSS_LOADER_EXPORT___;
