import reducers from "@src/reducers/index";
import {createStore, applyMiddleware} from "redux";
import {thunk} from "redux-thunk";


const store = createStore(
    reducers,
    applyMiddleware(
        thunk,
    ),
);

export default function getStore() {
    return store;
}
