import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "@src/pages/HomePage/HomePage.scss";
import Hero from "@src/components/Hero";
import {setPageTitle} from "@src/utils/windowHelpers";

function TermsPage({
    //...props
}) {

    useEffect(() => {
        setPageTitle("Terms & Conditions", window.location);
    }, []);

    return (
        <div className={classnames(styles.root, styles.contactPage)}>
            <Hero
                imageFilename={"wcc_hero_background_strip.jpg"}
                imageAltText={"American flag and Tennessee flying"}
                headerText={`Terms and Conditions`}
                subpage
            />
            <main className={styles.main}>
                <section>
                    <h2>Terms & Conditions</h2>
                </section>
            </main>
        </div>
    );
}


TermsPage.propTypes = {

}

export default TermsPage;
