import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import map from "lodash/map";
import styles from "./SignupForm.scss";
import {isValidEmailAddress, isValidPhoneNumber, formatPhoneNumber} from "@src/utils/text_helpers";
import Input from "@src/components/shared/elements/Input";
import Button from "@src/components/shared/elements/Button";
import UserOption from "@src/pages/SignupPage/UserOption";

function SignupForm({
    onSubmit,
    organization,
}) {
    const [formData, setFormData] = useState({});
    const [userOptionResponses, setUserOptionResponses] = useState({})
    const [showEmailError, setShowEmailError] = useState(null);
    const [showPhoneError, setShowPhoneError] = useState(null);

    const formDataComplete = useMemo(() => {
        const hasFirstLast = !!formData.first_name && !!formData.last_name;
        const hasPhone = !showPhoneError;
        const hasEmail = !!formData.username && !showEmailError;

        return hasFirstLast && hasPhone && hasEmail;

    }, [formData]);

    const handleOptionChange = ({ name, value, optionId }) => {
        const newUserOptions = {
            ...userOptionResponses,
            [name]: {
                value,
                id: optionId, // in case name is not option.id
                choice: value,
            },
        };
        setUserOptionResponses(newUserOptions);
    }

    const renderOptionalQuestions = () => {
        return map(organization?.userOptions, (option, index) => {
            return (
                <UserOption
                    key={`option_idx_${index}`}
                    index={index}
                    option={option}
                    onUpdate={handleOptionChange}
                    response={userOptionResponses[option.id]}
                />
            )
        });
    }

    const handleSubmit = () => {
        const optionsArray = map(userOptionResponses, (option, id) => ({
            id,
            choice: option.value,
        }));
        console.log("SignupForm :: handleSubmit :: optionsArray = ", optionsArray);
        onSubmit({
            ...formData,
            options: optionsArray,
        });
    }

    const handleChange = ({ name, value }) => {
        const newFormData = {
            ...formData,
            [name]: value
        };
        setFormData(newFormData);
        checkEmailValid(newFormData);
        checkPhoneValid(newFormData);
    }



    const handlePhoneChange = ({ name, value }) => {
        const formattedPhone = formatPhoneNumber(value);
        handleChange({ name, value: formattedPhone });
    }

    const checkEmailValid = (formData) => {
        const { username } = formData;
        if (!username) {
            setShowEmailError(null);
            return;
        }

        const isValid = isValidEmailAddress(username);
        if (!isValid) {
            setShowEmailError("Not a valid email address");
            return;
        }

        setShowEmailError(null);
    };

    const checkPhoneValid = (formData) => {
        const { phone } = formData;
        if (!phone) {
            setShowPhoneError(null);
            return;
        }

        const isValid = isValidPhoneNumber(phone);
        if (!isValid) {
            setShowPhoneError("Not a valid phone number");
            return;
        }

        setShowPhoneError(null);
    }

    return (
        <div className={styles.root}>
            <h2>Contact Information</h2>
            <div className={styles.formControl}>
                <div className={"form-group"}>
                    <label htmlFor="first_name">First Name</label>
                    <Input
                        id="first_name"
                        name="first_name"
                        required
                        onChange={handleChange}
                        type="text"
                        maxLength={30}/>
                </div>
                <div className={"form-group"}>
                    <label htmlFor="last_name">Last Name</label>
                    <Input
                        id="last_name"
                        name="last_name"
                        required
                        onChange={handleChange}
                        type="text"
                        maxLength={30}/>
                </div>
                <div className={"form-group"}>
                    <label htmlFor="username">Email</label>
                    <Input
                        id="username"
                        name="username"
                        onChange={handleChange}
                        type="email"
                        required={true}
                        maxLength={254}/>
                    {showEmailError &&
                        <div className={styles.errorlist}>
                            {showEmailError}
                        </div>
                    }
                </div>
                <div className={"form-group"}>
                    <label htmlFor="phone">Phone (Optional)</label>
                    <div><small>If you provide your phone number we will send very relevant, but infrequent, notifications directly to your phone.</small></div>
                    <Input
                        id="phone"
                        name="phone"
                        placeholder={"(XXX) XXX-XXXX"}
                        onChange={handlePhoneChange}
                        // onBlur={formatPhoneNumber}
                        type="text"
                        required={false}
                        value={formData.phone}
                        maxLength={14}/>
                    {showPhoneError &&
                        <div className={styles.errorlist}>
                            {showPhoneError}
                        </div>
                    }
                </div>
                {renderOptionalQuestions()}
                <div className={styles.submitButton}>
                    <Button onClick={handleSubmit} buttonText={"Subscribe"} disabled={!formDataComplete}/>
                </div>
            </div>
        </div>
    );
}


SignupForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
}

export default connect(
    (state) => ({
        organization: state.appState.currentOrganization,
    }),
    null
)(SignupForm);
