import React from "react";
import PropTypes from "prop-types";
import styles from "./Loader.scss";
import classnames from "classnames";
import ProgressBar from "@src/components/shared/elements/ProgressBar";
import { PROGRESS_COLORS }  from "@src/constants/Colors";

export default function Loader({
    dark,
    loaderText,
    loaderSubText,
    overlay,
    progressBar,
    progressPercent,
}) {

    const WithOverlay = (component) => {
        if (overlay) {
            return (
                <div className={styles.overlay}>
                    {component}
                </div>
            );
        }

        return component;
    }


    const LoaderComponent = (
        <div className={classnames(styles.root, {
            [styles.dark]: dark || overlay,
            [styles.hasText]: loaderText
        })}>

            <div className={styles.loaderText}>
                {loaderText}
                {loaderSubText && (
                    <span className={styles.subText}><br/>{loaderSubText}</span>
                )}
            </div>
            {progressBar && !!progressPercent ? (
                <div className={styles.progressBarWrapper}>
                    <ProgressBar
                        colors={PROGRESS_COLORS()}
                        thresholds={[0,75,95]}
                        percent={progressPercent}
                        border
                    />
                    <span className={styles.progressText}>{`${Math.round(progressPercent)}%`}</span>
                </div>
            ) : (
                <div className={styles.loader} />
            )}
        </div>
    );

    return WithOverlay(LoaderComponent)
}

Loader.propTypes = {
    loaderText: PropTypes.string,
    loaderSubText: PropTypes.string,
    progressBar: PropTypes.bool,
    progressPercent: PropTypes.number,
    dark: PropTypes.bool,
    overlay: PropTypes.bool,
};
