import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styles from "./HamburgerMenu.scss";
import classnames from "classnames";

function HamburgerMenu({
    useBars,
    hideLabel,
    iconClass = "fa-regular fa-ellipsis-v",
    isOpen,
    menuLabel = "Menu",
    onClick,
    openIconClass = "fa-light fa-times",
    stacked,
}) {

    const showLabel = useMemo(() => {
        return !hideLabel;
    }, [hideLabel]);
    const handleMenuClick = () => {
        onClick();
    }

    const iconButton = useMemo(() => {
        if (useBars) {
            return isOpen ? <i className={openIconClass} /> : <i className={"fa-bars fa-regular"} />;
        }
        return isOpen ? <i className={openIconClass} /> : <i className={iconClass} />;
    }, [iconClass, openIconClass, isOpen, useBars]);

    return (

        <div className={classnames(styles.root, {
            [styles.stacked]: useBars || stacked,
        })} onClick={handleMenuClick}>
            <span className={styles.icon}>
                {iconButton}
            </span>
            {showLabel && <span className={styles.label}>{isOpen ? "Close" : menuLabel }</span>}
        </div>
    );
}

HamburgerMenu.propTypes = {
    hideLabel: PropTypes.bool,
    iconClass: PropTypes.string,
    isOpen: PropTypes.bool,
    menuLabel: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    openIconClass: PropTypes.string,
    stacked: PropTypes.bool,
    useBars: PropTypes.bool,
};

export default HamburgerMenu

