
import * as ActionTypes from "@src/constants/ActionTypes";
import {clearDraft} from "@src/utils/EditorDraftUtils";

export function setWindowSize(width, height) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.WINDOW_SIZE_SET,
            dimensions: {
                width,
                height,
            },
        });
    }
}

export function setWindowVisibility(inFocus) {
    const type = inFocus ? ActionTypes.WINDOW_FOCUS_EVENT : ActionTypes.WINDOW_BLUR_EVENT;
    return (dispatch) => {
        dispatch({
            type,
        })
    }
}

export function resetTextFields(posterId) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.RESET_TEXT,
            posterId,
        })
    }
}

export function clearPosterStatus(posterId) {
    return (dispatch) => {
        // Remove from sessionStorage
        clearDraft(posterId);
        dispatch({
            type: ActionTypes.CLEAR_SUCCESS,
            posterId,
        })
    }
}
