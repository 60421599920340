import * as MapApi from "@src/api/MapApi";
import {requestError} from "@src/utils/request";
import * as ActionTypes from "@src/constants/ActionTypes";
import {loadMapById} from "@src/api/MapApi";
import {MAP_LOCATION_CLEAR} from "@src/constants/ActionTypes";

export function lookupLocationByPlacesId(placesId) {
    return (dispatch) => {
        MapApi.lookupLocationByPlacesId(placesId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.LOCATION_DETAILS_LOADED,
                    location: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error loading location user");
            });
    }
}

export function loadLocationDetails(placesId) {
    return (dispatch) => {
        MapApi.lookupLocationByPlacesId(placesId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.LOCATION_DETAILS_LOADED,
                    location: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error loading location user");
            });
    }
}

export function loadMaps() {
    return (dispatch) => {
        MapApi.loadMaps()
            .then((res) => {
                dispatch({
                    type: ActionTypes.MAPS_LIST_LOADED,
                    maps: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error loading location user");
            });
    }
}

export function loadMapDetails(mapId) {
    return (dispatch) => {
        MapApi.loadMapById(mapId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.MAP_DETAILS_LOADED,
                    map: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error loading map details");
            });
    }
}

export function loadMapDetailsBySlug(mapSlug) {
    return (dispatch) => {
        MapApi.loadMapBySlug(mapSlug)
            .then((res) => {
                dispatch({
                    type: ActionTypes.MAP_DETAILS_LOADED,
                    map: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error loading map details using slug");
            });
    }
}

export function setMapLayer(slug, layerData) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.MAP_LAYER_SET,
            slug,
            layerData,
        })
    }
}


export function setUserLocations(locations) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.MAP_LOCATIONS_MATCHED,
            locations,
        })
    }
}

export function setMapLocation(location) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.MAP_LOCATION_SET,
            location,
        })
    }
}


export function clearMapLocation(location) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.MAP_LOCATION_CLEAR
        })
    }
}


export function createLocation(locationData) {
    return (dispatch) => {
        MapApi.createLocation(locationData)
            .then((res) => {
                dispatch({
                    type: ActionTypes.LOCATION_CREATED,
                    location: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error creating location");
            });
    }
}

export function createAddress(locationData) {
    return (dispatch) => {
        MapApi.createAddress(locationData)
            .then((res) => {
                dispatch({
                    type: ActionTypes.USER_ADDRESS_LOCATION_CREATED,
                    location: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error creating location");
            });
    }
}


export function loadVotingLocations() {
    return (dispatch) => {
        MapApi.loadVotingLocations()
            .then((res) => {
                dispatch({
                    type: ActionTypes.VOTING_LOCATIONS_LOADED,
                    locations: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error creating location");
            });
    }
}
