import React, {useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import classnames from "classnames";
import map from "lodash/map";
import styles from "./NewsSection.scss";
import ArticleTile from "@src/components/ArticleTile";
import Button from "@src/components/shared/elements/Button";
import * as ArticleActions from "@src/actions/ArticleActions";
import Carousel from "@src/components/shared/widgets/Carousel";
import getStaticFile from "@src/utils/getStaticFile";

function NewsSection({ articles, loadArticles }) {

    const grassrootsImg = getStaticFile("img/grassroots_symbol.png");

    useEffect(() => {
        loadArticles();
    }, []);

    const articleTiles = useMemo(() => {
        return map(articles, article => {
            return (<ArticleTile article={article} key={article.path} />);
        });
    }, [articles]);

    return (
        <div className={styles.root}>
            <div className={styles.sectionHeader}>
                <div className={styles.iconImg}>
                    <img src={grassrootsImg} />
                </div>
                <h2>Grassroots Spotlight</h2>
                <div className={styles.iconImg}>
                    <img src={grassrootsImg} />
                </div>
            </div>
            <Carousel>
                {articleTiles}
            </Carousel>
            <div className={styles.viewAllBtn}>
                <Link to={"/news/"}>
                    <Button large wide buttonText={"View All"} />
                </Link>
            </div>

        </div>
    );
}

export default connect(
    (state) => ({
        articles: state.entities.recentArticles,
    }),
    (dispatch) => ({
        loadArticles: () => dispatch(ArticleActions.loadRecentArticles()),
    })
)(NewsSection);
