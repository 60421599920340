export const MOBILE = "mobile";
export const TABLET = "tablet";
export const LAPTOP = "laptop";
export const DESKTOP = "desktop";

export const PORTRAIT = "portrait";
export const LANDSCAPE = "landscape";
export const LANDSCAPE_PRIMARY = "landscape-primary";
export const LANDSCAPE_SECONDARY = "landscape-secondary";
export const PORTRAIT_PRIMARY = "portrait-primary";
export const PORTRAIT_SECONDARY = "portrait-secondary";

export function getScreenSize(docWidth, docHeight) {
    const { availWidth, availHeight, orientation } = window.screen;
    const pixelRatio = window.devicePixelRatio || 1;
    let adjustedHeight = availHeight/pixelRatio;
    let adjustedWidth = availWidth/pixelRatio;
    let screenSize;
    let screenOrientation;
    let docSize;

    if (orientation.type === LANDSCAPE_PRIMARY) { // Landscape by default... laptop, desktop, etc.
        screenSize = adjustedHeight < 1000 ? LAPTOP : DESKTOP;
        screenOrientation = LANDSCAPE;
    } else if (orientation.type === LANDSCAPE_SECONDARY) { // Tablet or phone in landscape-secondary
        screenSize = adjustedWidth > 1000 ? TABLET : MOBILE;
        screenOrientation = LANDSCAPE;
    } else {
        screenSize = adjustedWidth < 600 ? MOBILE : TABLET;
    }

    const docOrientation = docWidth < docHeight ? PORTRAIT : LANDSCAPE;
    if (docWidth < availWidth) {
        adjustedWidth = docWidth / pixelRatio;
    }
    if (docHeight < availHeight) {
        adjustedHeight = docHeight/pixelRatio;
    }

    if (docOrientation === PORTRAIT) {
        if (docWidth > 1440) {
            docSize = DESKTOP;
        } else {
            docSize = docWidth < 600 ? MOBILE : TABLET;
        }
    } else {
        if (docHeight > 1000) {
            docSize = DESKTOP;
        } else {
            docSize = docHeight < 900 ? MOBILE : TABLET;
        }
    }

    return {
        orientation: docOrientation,
        size: docSize,
        screenSize,
        screenOrientation,
        availHeight,
        availWidth
    }
}

export function getWindowSize() {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const screen = getScreenSize(width, height);

    return {
        ...screen,
        width,
        height
    }
}
