import React, {useEffect, useMemo, useState, useLayoutEffect} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {useLocation, useNavigation} from "react-router-dom";
import { find } from "lodash";
import {ToastContainer} from "react-toastify";
import styles from "./RootContainer.scss";
import SiteHeader from "@src/components/SiteHeader";
import Footer from "@src/components/Footer";

import * as AppActions from "@src/actions/AppActions";
import * as UserActions from "@src/actions/UserActions";
import * as ApplicationUrls from "@src/constants/ApplicationUrls";

function RootContainer({
    children,
    errorMessage,
    hasError,
    loadCurrentOrganization,
    loadUser,
    organization,
    setWindowSize,
    setWindowVisibility,
    userProfile,
}) {
    const [isLoggedIn, setIsLoggedIn] = useState(window.ENV.IS_LOGGED_IN);

    // const history  = useNavigation();
    // const location = useLocation().pathname;

    const handleWindowFocus = (evt) => {
        setWindowVisibility(true);
    }

    const handleWindowBlur = (evt) => {
        setWindowVisibility(false);
    }

    useEffect(() => {
        if (isLoggedIn) {
            loadUser();
        }

        if (!organization) {
            loadCurrentOrganization();
        }

        window.addEventListener("focus", handleWindowFocus);
        window.addEventListener("blur", handleWindowBlur);

        return () => {
            window.removeEventListener("focus", handleWindowFocus);
            window.removeEventListener("blur", handleWindowBlur);
        }

    }, [isLoggedIn]);

    useLayoutEffect(() => {
        function updateSize() {
            setWindowSize(window.innerWidth, window.innerHeight);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
      }, []);

    // Determine if we should show the site footer by checking if the current page is a "feed" page
    // Feed pages should not get the site footer, but every other page should

    const showSiteFooter = true;
    // const showSiteFooter = !isTourPage && !isExpiredPage && !find(ApplicationUrls.FEED_URLS, feedUrl => {
    //     if (feedUrl === location) {
    //         // Exact match
    //         return true;
    //     }
    //
    //     // Check for a partial match - if the first part of the url is included in the feed url
    //     // Right now the feed urls are unique enough that this works, but if more community or group links are added that are not also feed pages, may need to update this logic
    //     const baseUrlLocation = location.substring(
    //         location.indexOf("/") + 1,
    //         location.lastIndexOf("/")
    //     );
    //     if (baseUrlLocation !== "/") {
    //         return feedUrl.includes(baseUrlLocation);
    //     }
    // });

    return (
        <div className={styles.root}>
            <SiteHeader hideLinks />
            <div className={styles.content}>
                {children}
            </div>
            {showSiteFooter &&
                <Footer />
            }
            <ToastContainer />
        </div>
    );
}

// TODO: Update to user an applicationState reducer for global errors from API/Actions
RootContainer.propTypes = {
    hasError: PropTypes.bool,
    errorMessage: PropTypes.string,
};

export default connect(
    (state) => ({
        userProfile: state.user.userProfile,
        organization: state.appState.organization,
    }),
    (dispatch) => ({
        loadUser: () => dispatch(UserActions.loadCurrentUser()),
        loadCurrentOrganization: () => dispatch(UserActions.loadCurrentOrganization()),
        setWindowSize: (width, height) => dispatch(AppActions.setWindowSize(width, height)),
        setWindowVisibility: (inFocus) => dispatch(AppActions.setWindowVisibility(inFocus)),
    })
)(RootContainer);
