import React from "react";
import PropTypes from "prop-types";
import styles from "./TextPromo.scss";

function TextPromo({ text, title, children }) {
    return (
        <div className={styles.root}>
            {title && <h4>{title}</h4>}
            {text && <p>{text}</p>}
            {children}
        </div>
    );
}


TextPromo.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired
}

export default TextPromo;
