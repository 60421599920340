import request from "@src/utils/request";


export function lookupLocationByPlacesId(data) {
    return request()
        .get(`/api/v1/maps/`)
        .send(data);
}

export function loadMaps() {
    return request()
        .get("/api/v1/maps/")
        .send();
}

export function loadMapById(mapId) {
    return request()
        .get(`/api/v1/maps/${mapId}/`)
        .send();
}

export function loadMapBySlug(mapSlug) {
    return request()
        .get(`/api/v1/map-lookup/${mapSlug}/`)
        .send();
}

export function createLocation(locationData) {
    return request()
        .post("/api/v1/locations/")
        .send(locationData);
}

export function createAddress(locationData) {
    return request()
        .post("/api/v1/locations/address/")
        .send(locationData);
}

export function loadVotingLocations() {
    return request()
        .get("/api/v1/voting-locations/")
        .send();
}
