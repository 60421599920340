import {combineReducers} from "redux";
import appState from "./appState";
import entities from "./entities";
import mapState from "./mapState";
import site from "./site";
import user from "./user";

export default combineReducers({
    appState,
    entities,
    mapState,
    site,
    user
});
