import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./EditorDashboard.scss";

function EditorDashboard(props) {
    return (
        <div className={styles.root}>
            <h1>EditorDashboard</h1>
        </div>
    );
}


EditorDashboard.propTypes = {}

EditorDashboard.defaultProps = {}

export default connect(
    (state, ownProps) => ({
        // property: state.property,
    }),
    (dispatch) => ({
        // function: () => dispatch(ACTION.function()),
    })
)(EditorDashboard);
