import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ElectedOfficialsDetails.scss";
import * as urls from "@src/constants/ApplicationUrls";
import {Link} from "react-router-dom";


function ElectedOfficialsDetails(props) {
    return (
        <div className={styles.root}>
            <h1>ElectedOfficialsDetails</h1>
            <div className={styles.links}>
                <Link to={"/"}>Dashboard</Link>
                <Link to={"/profile"}>User Profile</Link>
                <Link to={urls.ARTICLE_LIST}>ARTICLES</Link>
                <Link to={urls.AGENCY_DETAILS}>AGENCY_DETAILS</Link>
                <Link to={urls.ELECTIONS}>ELECTIONS</Link>
                <Link to={urls.ELECTION_DETAILS}>ELECTION_DETAILS</Link>
                <Link to={urls.ELECTION_CANDIDATE_DETAILS}>ELECTION_CANDIDATE_DETAILS</Link>
                <Link to={urls.AGENCY_ELECTED_OFFICIALS}>AGENCY_ELECTED_OFFICIALS</Link>
                <Link to={urls.AGENCY_ELECTED_OFFICIAL_DETAILS}>AGENCY_ELECTED_OFFICIAL_DETAILS</Link>
                <Link to={urls.ARTICLE_CREATE}>ARTICLE_CREATE</Link>
                <Link to={urls.ARTICLE_EDIT}>ARTICLE_EDIT</Link>
                <Link to={urls.ELECTION_CREATE}>ELECTION_CREATE</Link>
                <Link to={urls.ELECTION_EDIT}>ELECTION_EDIT</Link>
                <Link to={urls.EDITOR_DASHBOARD}>EDITOR_DASHBOARD</Link>
            </div>
        </div>
    );
}


ElectedOfficialsDetails.propTypes = {}

ElectedOfficialsDetails.defaultProps = {}

export default connect(
    (state, ownProps) => ({
        // property: state.property,
    }),
    (dispatch) => ({
        // function: () => dispatch(ACTION.function()),
    })
)(ElectedOfficialsDetails);
