import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ElectionEditor.scss";

function ElectionEditor(props) {
    return (
        <div className={styles.root}>
            <h1>ElectionEditor</h1>
        </div>
    );
}


ElectionEditor.propTypes = {
    isNew: PropTypes.bool,
}

ElectionEditor.defaultProps = {}

export default connect(
    (state, ownProps) => ({
        // property: state.property,
    }),
    (dispatch) => ({
        // function: () => dispatch(ACTION.function()),
    })
)(ElectionEditor);
