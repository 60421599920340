
import {combineReducers} from "redux";
import * as ActionTypes from "@src/constants/ActionTypes";
import keyBy from "lodash/keyBy";
import {USER_ADDRESS_LOCATION_CREATED} from "@src/constants/ActionTypes";

function userProfile(state={}, action) {
    switch (action.type) {
        case ActionTypes.CURRENT_USER_LOADED:
            return {
                ...action.userProfile,
                organizations: {
                    ...keyBy(action.userProfile.organizations, "id"),
                },
            };
        case ActionTypes.CURRENT_USER_UPDATED:
            return {
                ...state,
                ...action.userProfile,
            };
        case ActionTypes.NOTIFICATION_SETTINGS_CREATED:
            return {
                ...state,
                notificationSettings: action.notificationSettings,
            };
        default:
            return state;
    }
}

function organizationList(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CURRENT_USER_LOADED:
            return keyBy(action.userProfile.organizations, "id");
        default:
            return state;
    }
}

function notificationSettings(state={}, action) {
    switch (action.type) {
        case ActionTypes.NOTIFICATION_SETTINGS_LOADED:
        case ActionTypes.NOTIFICATION_SETTINGS_UPDATED:
            return action.notificationSettings;
        default:
            return state;
    }
}

function locations(state = null, action) {
    switch (action.type) {
        case ActionTypes.MAP_LOCATIONS_MATCHED:
            return action.locations;
        case ActionTypes.MAP_LOCATION_CLEAR:
            return null;
        default:
            return state;
    }
}

function addressLocation(state = null, action) {
    switch (action.type) {
        case ActionTypes.USER_ADDRESS_LOCATION_CREATED:
            return action.location;
        case ActionTypes.MAP_LOCATION_CLEAR:
            return null;
        default:
            return state;
    }
}

export default combineReducers({
    addressLocation,
    userProfile,
    locations,
    notificationSettings,
    organizationList,
});
