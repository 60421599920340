import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import map from "lodash/map";
import styles from "./UserOption.scss";
import Input from "@src/components/shared/elements/Input";
import Select from "@src/components/shared/elements/Select";
function UserOption({
    index,
    onUpdate,
    option,
    response,
}) {

    const responseData = useMemo(() => {
        if (response) {
            return response;
        };

        return { value: null };

    }, [option, response]);

    const handleChange = ({ name, value }) => {
        onUpdate({ name, value, optionId: option.id });
    }

    const isDropdown = useMemo(() => {
         return (option?.choices.length);
    }, [option]);

    const optionsList = useMemo(() => {
        if (isDropdown) {
            // multiple choice
            return map(option.choices, choice => ({
                name: choice.label,
                value: choice.id,
            }));
        }
        return null;
    }, [option, isDropdown]);

    return (
        <div className={classnames("form-group", styles.root)}>
            {isDropdown ? (
                <Select
                    name={option.id}
                    label={option.label}
                    key={`${option.id}-select`}
                    options={optionsList}
                    value={responseData.value}
                    onChange={handleChange}
                    placeholder={option.placeholder || "--"}
                />
            ) : (
                <Input
                    name={option.id}
                    label={option.label}
                    type={"checkbox"}
                    value={responseData.value}
                    onChange={handleChange}
                />
            )}
        </div>
    );
}

UserOption.propTypes = {
    index: PropTypes.number.isRequired,
    onUpdate: PropTypes.func.isRequired,
    option: PropTypes.shape({
        label: PropTypes.string.isRequired,
        choices: PropTypes.array,
        description: PropTypes.string,
        placeholder: PropTypes.string,
    }).isRequired,
    response: PropTypes.object,
}

export default UserOption;
