import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import map from "lodash/map";
import styles from "./ArticlesListPage.scss";
import ArticlePreview from "@src/components/ArticlePreview";
import * as ArticleActions from "@src/actions/ArticleActions";
import Hero from "@src/components/Hero";
import {setPageTitle} from "@src/utils/windowHelpers";

function ArticlesListPage({ articles, loadArticles }) {
    useEffect(() => {
        loadArticles();
        setPageTitle("Grassroots Spotlight", window.location);
    }, []);


    const renderArticles = () => {
        return map(articles, article => (
            <ArticlePreview article={article} key={article.path} />
        ))
    }
    return (
        <div className={styles.root}>
            <Hero
                imageFilename={"wcc_hero_background_strip.jpg"}
                imageAltText={"American flag and Tennessee flying"}
                headerText={"Grassroots Spotlight"}
                subpage
            />
            <div className={styles.inner}>
                {renderArticles()}
            </div>
        </div>
    );
}


ArticlesListPage.propTypes = {}

ArticlesListPage.defaultProps = {}

export default connect(
    (state, ownProps) => ({
        articles: state.entities.articles,
    }),
    (dispatch) => ({
        loadArticles: () => dispatch(ArticleActions.loadArticles()),
    })
)(ArticlesListPage);
