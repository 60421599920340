
import * as UserApi from "@src/api/UserApi";
import * as ActionTypes from "@src/constants/ActionTypes";
import {requestError} from "@src/utils/request";
import {toaster} from "@src/utils/toaster";
// import * as Sentry from "@sentry/react";

// const getSentryUserData = (user) => {
//     return {
//         id: user.id,
//         roles: {
//             admin: user.isAdmin,
//             staff: user.isStaff,
//             teacher: user.isTeacher,
//             freeTrial: user.freeTrial,
//         },
//     }
// }

export function loadCurrentUser() {
    return (dispatch) => {
        UserApi.loadCurrentUser()
            .then((res) => {
                // Sentry.setUser(getSentryUserData(res.body));
                dispatch({
                    type: ActionTypes.CURRENT_USER_LOADED,
                    userProfile: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error loading current user");
            });
    }
}

export function updateCurrentUser(currentUser) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.START_SAVE_USER_PROFILE,
        });
        UserApi.updateCurrentUser(currentUser)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CURRENT_USER_UPDATED,
                    userProfile: res.body,
                });
                toaster.success("User Profile and Preferences Saved");
            })
            .catch((err) => {
                dispatch({
                    type: ActionTypes.USER_PROFILE_SAVED_ERROR,
                });
                requestError(err, "Error updating current user");
            });
    }
}

export function updateUserEmail(data) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.START_SAVE_USER_EMAIL,
        });
        UserApi.updateUserEmail(data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CURRENT_USER_UPDATED,
                    userProfile: res.body,
                });
            })
            .catch((err) => {
                dispatch({
                    type: ActionTypes.USER_PROFILE_SAVED_ERROR,
                    // TODO return error message
                });
                requestError(err, "Error updating email");
            })
    }
}


export function loadCurrentOrganization() {
    return (dispatch) => {
        UserApi.loadCurrentOrganization()
            .then((res) => {
                dispatch({
                    type: ActionTypes.CURRENT_ORGANIZATION_LOADED,
                    organization: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error loading current organization");
            });
    }
}

export function updateNotificationSettings(notificationSettings) {
    return (dispatch) => {
        UserApi.updateNotificationSettings(notificationSettings)
            .then((res) => {
                dispatch({
                    type: ActionTypes.NOTIFICATION_SETTINGS_UPDATED,
                    notificationSettings: res.body,
                });
                toaster.success("Notification settings saved");
            })
            .catch((err) => {
                requestError(err, "Error updating notifications settings");
            });
    }
}
