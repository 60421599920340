import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import { ErrorBoundary } from "react-error-boundary";

function WithErrorBoundary({
    children,
    componentName,
    fallbackMessage,
    fallbackComponent,
    functionName,
}) {
    const errorFallbackMessage = <div>{fallbackMessage || `Error loading ${componentName}`}</div>

    const onError = ({ error, info }) => {
        const functionNameString = functionName ? `${functionName} ::` : "";
        console.error(`${componentName} ::${functionNameString} error = `, error);
        console.error(`${componentName} ::${functionNameString} info = `, info);
    };

    return (
        <ErrorBoundary
            fallback={errorFallbackMessage}
            FallbackComponent={fallbackComponent}
            onError={onError}
        >
            {children}
        </ErrorBoundary>
    );
}


WithErrorBoundary.propTypes = {
    componentName: PropTypes.string.isRequired,
    fallbackMessage: PropTypes.string,
    fallbackComponent: PropTypes.any,
    functionName: PropTypes.string,
}

export default WithErrorBoundary;
