import * as ActionTypes from "@src/constants/ActionTypes";
import * as SiteNotificationApi from "@src/api/SiteNotificationApi";
import {requestError} from "@src/utils/request";
import {toaster} from "@src/utils/toaster";

export function loadSiteNotifications(dataFilters) {
    return (dispatch) => {
        SiteNotificationApi.loadSiteNotifications(dataFilters)
            .then((res) => {
                dispatch({
                    type: ActionTypes.SITE_NOTIFICATIONS_LOADED,
                    siteNotifications: res.body,
                })
            }).catch((err) => {
                requestError(err, "Error loading site notifications");
            });
    }
}

export function loadSiteNotificationDetails(siteNotificationId) {
    return (dispatch) => {
        SiteNotificationApi.loadSiteNotificationDetails(siteNotificationId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.SITE_NOTIFICATION_DETAILS_LOADED,
                    siteNotification: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error site notification details");
            });
    };
}

export function loadActiveSiteNotifications(dataFilters={}) {
    return (dispatch) => {
        SiteNotificationApi.loadActiveSiteNotifications(dataFilters)
            .then((res) => {
                dispatch({
                    type: ActionTypes.ACTIVE_SITE_NOTIFICATIONS_LOADED,
                    siteNotifications: res.body,
                })
            }).catch((err) => {
                requestError(err, "Error loading site notifications");
            });
    }
}

export function createSiteNotification(siteNotification, posterId) {
    return (dispatch) => {
        SiteNotificationApi.createSiteNotification(siteNotification).then((res) => {
            dispatch({
                type: ActionTypes.SITE_NOTIFICATION_CREATED,
                siteNotification: res.body,
                posterId,
            });
            toaster.success("Site notification created");
        }).catch((err) => {
            dispatch({
                type: ActionTypes.SITE_NOTIFICATIONS_CREATED_ERROR,
                // TODO return error message
            });
            requestError(err, "Error creating site notification");
        });
    }
}

// export function publishSiteNotification(siteNotification) # SITE_NOTIFICATION_PUBLISHED

export function updateSiteNotification(siteNotification) {
    return (dispatch) => {
        SiteNotificationApi.updateSiteNotification(siteNotification)
            .then((res) => {
                dispatch({
                    type: ActionTypes.SITE_NOTIFICATION_UPDATED,
                    siteNotification: res.body,
                });
                toaster.success("Site notification Updated");
            })
            .catch((err) => {
                requestError(err, "Error updating site notification");
            });
    }
}

export function deleteSiteNotification(siteNotificationId) {
    return (dispatch) => {
        SiteNotificationApi.deleteSiteNotification(siteNotificationId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.SITE_NOTIFICATION_DELETED,
                    siteNotification: siteNotificationId,
                });
            })
            .catch((err) => {
                requestError(err, "Error deleting site notification");
            });
    };
}

export function clearPosterSuccess(posterId) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CLEAR_SUCCESS,
            posterId,
        });
    }
}

export function dismissSiteNotification(siteNotificationId) {
    return (dispatch) => {
        SiteNotificationApi.dismissSiteNotification(siteNotificationId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.ACTIVE_SITE_NOTIFICATION_DISMISSED,
                    siteNotificationId,
                })
            })
            .catch((err) => {
                requestError(err, "Error dismissing site notification");
            });
    }

}
