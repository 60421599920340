import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "@src/pages/HomePage/HomePage.scss";
import Hero from "@src/components/Hero";
import {setPageTitle} from "@src/utils/windowHelpers";

function PrivacyPage({
    //...props
}) {

    useEffect(() => {
        setPageTitle("Privacy Policy", window.location);
    }, []);

    return (
        <div className={classnames(styles.root, styles.contactPage)}>
            <Hero
                imageFilename={"wcc_hero_background_strip.jpg"}
                imageAltText={"American flag and Tennessee flying"}
                headerText={`Privacy`}
                subpage
            />
            <main className={styles.main}>
                <section>
                    <h2>Privacy Policy</h2>
                </section>
            </main>
        </div>
    );
}


PrivacyPage.propTypes = {

}

export default PrivacyPage;
