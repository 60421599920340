import React, {useEffect, useMemo, useRef, useState} from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import styles from "./MapComponent.scss";
import * as MapProviders from "@src/constants/MapProviders";
import GoogleMaps from "@src/components/maps/Google/GoogleMaps";
import WithErrorBoundary from "@src/components/shared/elements/WithErrorBoundary";

function MapComponent({ children, mapConfig, screenSizes, onMapLoaded, onMatchedLocations, showBorder }) {
    return (
        <WithErrorBoundary
            componentName="MapComponent"
        >
            <div className={styles.root}>
                <GoogleMaps
                    onLoaded={onMapLoaded}
                    mapConfig={mapConfig}
                    onMatchedLocations={onMatchedLocations}
                    showBorder={showBorder}
                    screenSizes={screenSizes}
                >{children}</GoogleMaps>
            </div>
        </WithErrorBoundary>
    );
}


MapComponent.propTypes = {
    mapConfig: PropTypes.object.isRequired,
    onMapLoaded: PropTypes.func.isRequired,
    onMatchedLocations: PropTypes.func,
    showBorder: PropTypes.bool,
    mobilePortrait: PropTypes.bool,
    screenSizes: PropTypes.shape({
        size: PropTypes.string,
        orientation: PropTypes.string,
    }),
}

export default MapComponent;
