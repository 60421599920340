import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import map from "lodash/map";
import styles from "./Panel.scss";
import Icon from "@src/components/shared/elements/Icon";
import Modal from "@src/components/shared/elements/Modal";

function Panel({
    children,
    color,
    columnHeaders,
    headerExtras,
    infoText,
    infoTitle,
    isChild,
    light,
    showInfoTitle,
    stickyHeader,
    title,
}) {

    const [showInfoText, setShowInfoText] = useState(false);
    const renderedColumnHeaders = useMemo(() => {
        const colHeaders = map(columnHeaders, (header, index) => (
            <div className={styles.colHeader} key={`col_header_${index}`}>
                {header.iconName && <Icon iconName={header.iconName} solid colorHex={light ? "#2D383A" : "#FFFFFF"} />}
                <span>{header.label}</span>
            </div>
        ));
        if (colHeaders.length) {
            return (
                <div className={styles.columnHeaders}>
                    {colHeaders}
                </div>
            );
        }
        return null;
    }, [columnHeaders]);

    const extras = useMemo(() => {
        if (headerExtras) {
            return (
                <div className={styles.headerExtras}>{headerExtras}</div>
            );
        }
        return null;
    }, [headerExtras]);

    const titleTag = useMemo(() => {
        const infoTextElement = showInfoTitle ? <div className={styles.infoLinkText}>{infoTitle}</div> : <i className="fa-solid fa-info-circle" />;
        if (isChild) {
            return infoText ? (
                <h3 onClick={() => setShowInfoText(true)} className={styles.infoLink}>
                    <span>{title}</span> {infoTextElement}
                </h3>
            ) : (
                <h3>{title}</h3>
            );
        }
        return infoText ? (
            <h2 onClick={() => setShowInfoText(true)} className={styles.infoLink}>
                <span>{title}</span> <i className="fa-solid fa-info-circle" />
            </h2>
        ) : (
            <h2>{title}</h2>
        );
    }, [title]);

    return (
        <div className={classnames(styles.root, {
            [styles.light]: light,
            [styles.red]: color === "red",
            [styles.green]: color === "green",
            [styles.isChild]: isChild,
        })}>
            <div className={styles.innerCard}>
                <div className={classnames(styles.panelHeader, {
                    [styles.stickyHeader]: stickyHeader,
                })}>
                    <div className={classnames(styles.panelTitle, {
                        [styles.withHeaders]: !!columnHeaders?.length,
                    })}>{titleTag}</div>
                    {renderedColumnHeaders}
                    {extras}
                </div>
                <div className={styles.panelContent}>
                    {children}
                </div>
            </div>
            {infoText && (
                <Modal
                    modalTitle={infoTitle||"More Information"}
                    onClose={() => setShowInfoText(false)}
                    clickOutsideToClose
                    small
                    show={showInfoText}
                >
                    <div
                        style={{ paddingBottom: "1rem" }}
                        dangerouslySetInnerHTML={{ __html: infoText }}
                    />
                </Modal>
            )}
        </div>
    );
}

Panel.propTypes = {
    title: PropTypes.string.isRequired,
    columnHeaders: PropTypes.arrayOf(PropTypes.shape({
        iconName: PropTypes.string,
        label: PropTypes.string,
    })),
    headerExtras: PropTypes.object,
    light: PropTypes.bool,
    color: PropTypes.string,
    isChild: PropTypes.bool,
    infoText: PropTypes.string,
    infoTitle: PropTypes.string,
    showInfoTitle: PropTypes.bool,
    stickyHeader: PropTypes.bool,
}

export default Panel;
