import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ElectionCalendarItem.scss";
import map from "lodash/map";
import moment from "moment";
import ElectionCardInfo from "@src/components/elections/ElectionCardInfo";
import Panel from "@src/components/shared/widgets/Panel";

function ElectionCalendarItem({
    config,
    hideVotingDate,
    isPast,
}) {

    const votingDate = useMemo(() => {
        if (config?.votingDate) {
            return moment(config.votingDate).format("MMMM Do YYYY");
        }
        return null;
    }, [config]);

    const earlyVoting = useMemo(() => {
        if (config?.earlyStartDate) {
            const start = moment(config.earlyStartDate).format("MMMM Do");
            const end = moment(config.earlyEndDate).format("Do YYYY");
            const earlyDates = `${start} - ${end}`;
            return (
                <div className={classnames(styles.earlyVoting, {
                    [styles.earlyVotingOpen]: config.earlyVotingOpen,
                })}>
                    <div className={styles.earlyVotingDates}>Early Voting: <span>{earlyDates}</span></div>
                    {config.earlyVotingOpen && <div className={styles.open}>Open</div>}
                </div>
            );
        }
        return null;
    }, [config]);




    const renderElectionsInfo = () => {
        const electionCards = map(config.elections, election => (
            <ElectionCardInfo election={election} key={election.slug} isPast={isPast} />
        ));

        return electionCards.length ? electionCards : <div className={styles.noBallot}><h3>Sample Ballot Not Currently Available</h3><p>This will be updated as soon as the ballot becomes available</p></div>;
    }

    return (
        <div className={styles.root}>
            {/*{!hideVotingDate && <Link to={`/elections/${config.id}`}><h2>{votingDate}</h2></Link>}*/}
            <Panel
                title={votingDate}
                headerExtras={earlyVoting}
            >
                {config.title && <div className={styles.grayBar}>{config.title || "Election"}</div>}
                {renderElectionsInfo()}
            </Panel>
        </div>
    );
}


ElectionCalendarItem.propTypes = {
    config: PropTypes.object.isRequired,
    hideVotingDate: PropTypes.bool,
    isPast: PropTypes.bool,
}

export default connect(
    (state, ownProps) => ({
        // property: state.property,
    }),
    (dispatch) => ({
        // function: () => dispatch(ACTION.function()),
    })
)(ElectionCalendarItem);
