import React from "react";
import {useParams} from "react-router-dom";
import ElectionDetails from "@src/pages/app/ElectionDetails";

function ElectionWrapper(props) {
    const { electionDateId } = useParams();
    return <ElectionDetails electionDateId={electionDateId} key={electionDateId} />
}

export default ElectionWrapper;
