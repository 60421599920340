import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./PrimaryTag.scss";
import Icon from "@src/components/shared/elements/Icon";

function PrimaryTag({ hideLabel, isPrimary, votingDay }) {
    const label = useMemo(() => {
        if (!hideLabel) {
            const labelText = isPrimary ? "Primary" : "General";
            return <span className={styles.label}>{labelText}</span>
        }
        return null;
    }, [isPrimary, hideLabel]);

    return (
        <div className={classnames(styles.root, {
            [styles.primary]: isPrimary,
        })}>
            <Icon className={styles.icon} solid iconName="fa-solid fa-check-to-slot" />
            {label}
            {votingDay && <span className={styles.votingDay}>{votingDay}</span>}
        </div>
    );
}


PrimaryTag.propTypes = {
    isPrimary: PropTypes.bool,
    votingDay: PropTypes.string,
    hideLabel: PropTypes.bool
}

export default PrimaryTag;
