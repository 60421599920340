import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";

import ArticlePage from "@src/pages/app/ArticlePage";

function ArticleWrapper() {
    const { articlePath } = useParams();
    return <ArticlePage articlePath={articlePath}/>
}

export default ArticleWrapper;
