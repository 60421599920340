import React, {useEffect, useMemo, useState} from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Footer.scss";
import getStaticFile from "@src/utils/getStaticFile";

function Footer(props) {
    const logoImage = getStaticFile("img/wcc_text_logo.png");
    const AppStore = getStaticFile("img/Download_on_the_App_Store.png");
    const GooglePlay = getStaticFile("img/GetItOnGooglePlay.png")

    return (
        <div className={styles.root}>
            <div className={styles.footerInner}>
                <div className={styles.copyInfo}>
                    <div className={styles.logo}>
                        <img src={logoImage} alt="Williamson County Citizens logo" />
                        <a href={"/logout"}>Logout</a>
                    </div>
                </div>
                <div className={styles.links}>

                </div>
                {/*<div className={styles.downloadPrompt}>*/}
                {/*    <h4>Download Our App for real-time updates!</h4>*/}
                {/*    <div className={styles.appLinks}>*/}
                {/*        <a className={styles.apple}>*/}
                {/*            <img src={AppStore} alt="Apple App Store Link" />*/}
                {/*        </a>*/}
                {/*        <a className={styles.google}>*/}
                {/*            <img src={GooglePlay} alt="Google Play Link" />*/}
                {/*        </a>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className={styles.disclaimer}>*/}
                {/*    Paid for by Williamson County Citizens PAC.<br /> Not authorized by any candidate or candidate's committee.*/}
                {/*</div>*/}
            </div>


            <div className={styles.copyright}>
                Williamson County Citizens 2024 | <Link to={"/privacy"} >Privacy Policy</Link> | <Link to={"/contact"} >Contact Us</Link>
            </div>
        </div>
    );
}


Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer;
