import React, {useEffect, useMemo, useRef, useState} from "react";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "@src/pages/HomePage/HomePage.scss";
import Hero from "@src/components/Hero";
import * as SignupActions from "@src/actions/SignupActions";
import Button from "@src/components/shared/elements/Button";
import {setPageTitle} from "@src/utils/windowHelpers";

function WelcomePage({
    organization,
    userProfile,
}) {
    const shareLinkRef = useRef(null);
    const userFirstName = userProfile?.firstName || "Subscriber";
    const [isCopied, setIsCopied] = useState(false);

    useEffect(() => {
        setPageTitle("Verification Successful", window.location);
    }, []);

    const handleCopyClicked = () => {
        const element = shareLinkRef.current;
        if (element) {
            element.focus();
            element.select();
            element.setSelectionRange(0, 99999); // For mobile devices (says w3schools)
            if (document.execCommand("copy")) {
                setIsCopied(true);
            }
        }
    }

    const mailToHrefText = useMemo(() => {
        const orgEmailBody = organization?.socialEmailBody ||
            "I just subscribed to Williamson County Citizens.\nI think you'll like it https://williamsoncountycitizens.org/?share=true";
        const orgEmailSubject = organization?.socialEmailSubject || "Check out Williamson County Citizens";
        // return `mailto:?body=${encodeURIComponent(orgEmailBody)}&subject=${orgEmailSubject}`;
        return `mailto:?body=Subscribe to WCC&subject=Check out WCC`;
    }, [organization]);

    return (
        <div className={styles.root}>
            <Hero
                imageFilename={"wcc_hero_background_strip.jpg"}
                imageAltText={"American flag and Tennessee flying"}
                headerText={`Welcome ${userFirstName}!`}
                subpage
            />
            <main className={styles.main}>
                <section>
                    <Link to={"/"}><i className={"fa-solid fa-chevron-left"} /> Return to Homepage</Link>
                </section>
                <section>
                    <h2>Success!</h2>
                    <div className={styles.divider} />
                    <div>
                        <p>You are officially subscribed to Williamson County Citizens!</p>
                        {/*<p>Your conservative grassroots network in Williamson County.</p>*/}
                        <p>You will now receive our notifications!</p>
                    </div>
                </section>
                {/*<section>*/}
                {/*    <h4>Share with a like-minded friend!</h4>*/}
                {/*    <div className={styles.socialShare}>*/}
                {/*        <div className={styles.linkText}>*/}
                {/*            <input*/}
                {/*                className={styles.code}*/}
                {/*                ref={shareLinkRef}*/}
                {/*                type="text"*/}
                {/*                value="https://williamsoncountycitizens.org/?shared=true"*/}
                {/*                readOnly*/}
                {/*                disabled*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*        <div className={styles.shareButtons}>*/}
                {/*            <Button*/}
                {/*                buttonText={"Copy Link"}*/}
                {/*                buttonIcon={"fa-solid fa-link"}*/}
                {/*                buttonColor={isCopied ? "green" : ""}*/}
                {/*                onClick={handleCopyClicked}*/}
                {/*            />*/}
                {/*            <a href={mailToHrefText} className={styles.emailLink}>*/}
                {/*                <Button*/}
                {/*                    buttonText={"Email"}*/}
                {/*                    buttonIcon={"fa-solid fa-paper-plane-top"}*/}
                {/*                />*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}

            </main>
        </div>
    );
}


WelcomePage.propTypes = {}

export default connect(
    (state) => {
        const organization = state.appState.currentOrganization;
        const userProfile = state.user.userProfile;

        return {
            organization,
            userProfile,
        };
    },
    (dispatch) => ({
        signUpUser: (user) => dispatch(SignupActions.signUpUser(user)),
        requestEditLink: (email) => dispatch(SignupActions.requestEditLink(email)),
        clearSignupStatus: () => dispatch(SignupActions.clearSignupStatus()),
    })
)(WelcomePage);
