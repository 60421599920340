import request from "@src/utils/request";

export function loadSiteNotifications(dataFilters) {
    return request()
        .query(dataFilters)
        .get("/api/v1/site-notifications/")
        .send();
}

export function loadActiveSiteNotifications(dataFilters) {
    return request()
        .query(dataFilters)
        .get("/api/v1/site-notifications/active/")
        .send();
}

export function createSiteNotification(siteNotification) {
    return request()
        .post("/api/v1/site-notifications/")
        .send(siteNotification);
}

export function updateSiteNotification(siteNotification) {
    return request()
        .patch(`/api/v1/site-notifications/${siteNotification.id}/`)
        .send(siteNotification);
}

export function deleteSiteNotification(siteNotificationId) {
    return request()
        .delete(`/api/v1/site-notifications/${siteNotificationId}/`)
        .send();
}

export function loadSiteNotificationDetails(siteNotificationId) {
    return request()
        .get(`/api/v1/site-notifications/${siteNotificationId}/`)
        .send();
}

export function dismissSiteNotification(siteNotificationId) {
    return request()
        .get(`/api/v1/site-notifications/${siteNotificationId}/dismiss/`)
        .send();
}
