import React from "react";
import styles from "./Select.scss";
import PropTypes from "prop-types";
import classnames from "classnames";
import map from "lodash/map";

export default function Select({
    disabled,
    inline,
    label,
    name,
    numeric,
    onChange,
    options,
    placeholder,
    value,
}) {

    const handleChange = (e) => {
        let value = e.target.value;
        if (numeric) {
            value = e.target.value === "" ? null : Number(e.target.value);
        }

        onChange({
            name,
            value,
        });
    };

    const selectOptions = map(options, (option, index) => (
        <option
            key={`${option.name}_${index}`}
            value={option.value}
        >
            {option.name}
        </option>
    ));

    return (
        <div className={classnames(styles.root, {
            [styles.inline]: inline,
        })}>
            {label &&
                <div className="label">
                    {label}
                </div>
            }
            <select
                onChange={handleChange}
                value={value || ""}
                disabled={disabled}
                className="select"
            >
                {placeholder && (
                    <option value="">{placeholder}</option>
                )}
                {selectOptions}
            </select>
        </div>
    )
}

Select.propTypes = {
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        value: PropTypes.any,
    })).isRequired,
    value: PropTypes.any,
    invalid: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    numeric: PropTypes.bool,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
};
