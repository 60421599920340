import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./UserLocations.scss";
import {loadCurrentUser} from "@src/actions/UserActions";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import LocationDetails from "@src/components/maps/LocationDetails";
import UserLocationSeat from "@src/components/maps/UserLocations/UserLocationSeat";
import Icon from "@src/components/shared/elements/Icon";
import Panel from "@src/components/shared/widgets/Panel";

function UserLocations({
    mapSlug,
    userAddress,
    userLocations,
    userProfile,
}) {

    const userLocationsList = useMemo(() => {
        if (userLocations) {
            console.log("UserLocations :: userLocationList :: userLocations = ", userLocations);
            return map(sortBy(userLocations, ["layer.order"]), location => (
                <UserLocationSeat mapSlug={mapSlug} location={location} key={`layer_${location.layer.name}`} />
            ))
        }

        return null;
    }, [userLocations]);

    if (!userLocations) {
        return null;
    }



    return (
        <Panel
            title={"Your Elected Officials"}
        >
            {userLocationsList}
         </Panel>
    );
}


UserLocations.propTypes = {
    mapSlug: PropTypes.string.isRequired,
}

UserLocations.defaultProps = {}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const userAddress = userProfile?.address;

        // Get the mapConfig based on slug
        return {
            userProfile,
            userAddress,
            userLocations: state.user.locations,
        }
    },
    (dispatch) => ({
        loadCurrentUser: () => dispatch(loadCurrentUser()),
    })
)(UserLocations);
