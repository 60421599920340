// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OpportunityTag__root{cursor:pointer}
`, "",{"version":3,"sources":["webpack://./web/src/components/OpportunityTag/OpportunityTag.scss"],"names":[],"mappings":"AAGA,sBACE,cAAe","sourcesContent":["@import \"@commonstyles/vars.scss\";\n@import \"@commonstyles/colors.scss\";\n\n.root {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `OpportunityTag__root`
};
export default ___CSS_LOADER_EXPORT___;
