import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import find from "lodash/find";
import styles from "./AppointedOfficialDetails.scss";
import Icon from "@src/components/shared/elements/Icon";

function AppointedOfficialDetails({ seat }) {
    const currentlyAppointed = useMemo(() => {
        if (seat?.appointedOfficial) {
            const { tenure, name } = seat.appointedOfficial
            const in_office = tenure ? `(${tenure})` : "";

            return `${name} ${in_office}`
        }
        return <span className={styles.vacant}>Currently Vacant</span>
    }, [seat]);

    if (!seat ) {
        return null;
    }

    return (
        <div className={styles.root}>
            <div className={styles.text}>
                <h3>{currentlyAppointed}</h3>
                <div className={styles.seatName}>{seat.name}</div>
            </div>
        </div>
    );
}


AppointedOfficialDetails.propTypes = {
    seat: PropTypes.object.isRequired,
}

export default AppointedOfficialDetails;
