import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Icon.scss";

function Icon({
    circle,
    colorHex,
    iconClass = "",
    iconName,
    solid,
}) {

  const iconWeight = solid ? "fa-solid" : "fa-regular";
  const iconNameClass = `${iconWeight} fa-${iconName}`;

  let circleStyles = {};
  let iconStyles = {};

  if (colorHex) {

    if (circle) {
      circleStyles = {
        backgroundColor: `${colorHex}`
      }
    } else {
      iconStyles = {
        color: `${colorHex}`
      }
    }
  }

  return (
      <div className={classnames(styles.root, {
        [styles.circle]: circle
      })} style={circleStyles}>
        <i className={classnames(iconNameClass, iconClass)} style={iconStyles} />
      </div>
  );
}


Icon.propTypes = {
  iconName: PropTypes.string,
  iconClass: PropTypes.string,
  solid: PropTypes.bool,
  colorHex: PropTypes.string,
  circle: PropTypes.bool,
}

export default Icon;
