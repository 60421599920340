import request from "@src/utils/request";


export function loadElectionCalendar() {
    return request()
        .get(`/api/v1/election-dates/calendar/`)
        .send();
}
export function loadPastElections(start_date) {
    return request()
        .post(`/api/v1/election-dates/past/`)
        .send({
            start_date
        });
}

export function loadElectionDate(id) {
    return request()
        .get(`/api/v1/election-dates/${id}`)
        .send()
}

export function loadElectionBySlug(slug) {
    return request()
        .get(`/api/v1/election-lookup/${slug}/`)
        .send();
}

export function loadPoliticianById(id) {
    return request()
        .get(`/api/v1/politicians/${id}/`)
        .send();
}

export function loadPoliticians() {
    return request()
        .get(`/api/v1/politicians/`)
        .send();
}

export function loadRatings() {
    return request()
        .get("/api/v1/ratings")
        .send();
}
