// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FeaturesListItem__root{margin:0.5rem}.FeaturesListItem__root .FeaturesListItem__seats{padding:0 1rem 0.5rem}@media (min-width: 768px){.FeaturesListItem__root{margin:1rem}}
`, "",{"version":3,"sources":["webpack://./web/src/components/maps/FeaturesList/FeaturesListItem/FeaturesListItem.scss"],"names":[],"mappings":"AAGA,wBACE,aAAc,CADhB,iDAII,qBAAsB,CACvB,0BALH,wBAQI,WAAY,CAEf","sourcesContent":["@import \"@commonstyles/vars.scss\";\n@import \"@commonstyles/colors.scss\";\n\n.root {\n  margin: 0.5rem;\n\n  .seats {\n    padding: 0 1rem 0.5rem;\n  }\n\n  @media (min-width: $breakpointSm) {\n    margin: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `FeaturesListItem__root`,
	"seats": `FeaturesListItem__seats`
};
export default ___CSS_LOADER_EXPORT___;
