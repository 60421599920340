import React, {useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";

export default function ProgressBar({
    border,
    colors,
    percent,
    thresholds,
    thin,
}) {

    const [barColor, setBarColor] = useState("blue");
    const barStyle = useMemo(() => {
        const baseBarStyle = {
            height: "1.5em",
            position: "relative",
            width: "100%",
        }
        if (border) {
            baseBarStyle.border = "1px solid #D5D5D5";
        }
        if (thin) {
            baseBarStyle.height = "0.75em";
        }

        return baseBarStyle;

    }, [border, thin]);

    useEffect(() => {
        if (colors?.[0]) {
            setBarColor(colors[0]);
        }
    }, []);

    useEffect(() => {
        if (!!thresholds?.length && colors?.length === thresholds.length) {
            const reverseColors = colors.reverse();
            const reverseThresholds = thresholds.reverse();
            for(let i=0;i < reverseThresholds; i++) {
                if (percent >= value) {
                    setBarColor(reverseColors[index]);
                    break;
                }
            }
        }
    }, [percent]);

    const barHeight = useMemo(() => {
        if (thin) {
            return "0.55em";
        }
        return "1.2em";
    }, [thin]);


    return (
        <div
            style={barStyle}
        >
            <div
                style={{
                    position: "absolute",
                    height: barHeight,
                    top: ".1em",
                    left: "0.1em",
                    width: `${percent}%`,
                    backgroundColor: `${barColor}`
                }}
            />
        </div>
    )
}

ProgressBar.propTypes = {
    percent: PropTypes.number.isRequired,
    colors: PropTypes.arrayOf(PropTypes.string),
    thresholds: PropTypes.arrayOf(PropTypes.number),
    border: PropTypes.bool,
    thin: PropTypes.bool,
};
