// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoMessage__root{margin:0 0 1rem}.InfoMessage__root .InfoMessage__messageContent{padding:0.5rem}
`, "",{"version":3,"sources":["webpack://./web/src/components/shared/widgets/InfoMessage/InfoMessage.scss"],"names":[],"mappings":"AAGA,mBACE,eAAgB,CADlB,gDAII,cAAe","sourcesContent":["@import \"@commonstyles/vars.scss\";\n@import \"@commonstyles/colors.scss\";\n\n.root {\n  margin: 0 0 1rem;\n\n  .messageContent {\n    padding: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `InfoMessage__root`,
	"messageContent": `InfoMessage__messageContent`
};
export default ___CSS_LOADER_EXPORT___;
