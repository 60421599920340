import defaults from "superagent-defaults";
import Cookies from "js-cookie";
import * as Sentry from "@sentry/react";
import {toaster} from "@src/utils/toaster";


export default function request() {
    const superagent = defaults();

    return superagent
        .set("Content-Type", "application/json")
        .set("Accept", "application/json")
        .use((request) => {
            request.on("error", (error) => {
                if (error.status === 401) {
                    window.location = `/login/?inactive=1&next=${window.location.pathname}`;
                }
            });
        })
        .set("X-CSRFTOKEN", Cookies.get("csrftoken"));
}


export const requestError = (err, message="Error loading data") => {
    Sentry.captureException(err);
    toaster.error(message);
    console.error(err);
}
