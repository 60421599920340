// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserOption__root{margin:0.5rem 0}.UserOption__root.form-group{margin:1rem 0}
`, "",{"version":3,"sources":["webpack://./web/src/pages/SignupPage/UserOption/UserOption.scss"],"names":[],"mappings":"AAGA,kBACE,eAAgB,CADlB,6BAGI,aAAc","sourcesContent":["@import \"@commonstyles/vars.scss\";\n@import \"@commonstyles/colors.scss\";\n\n.root {\n  margin: 0.5rem 0;\n  &:global(.form-group) {\n    margin: 1rem 0;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `UserOption__root`
};
export default ___CSS_LOADER_EXPORT___;
