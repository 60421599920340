// Color values match colors.scss

export const DARK_YELLOW = "rgb(214, 182, 61)";
export const PURPLE = "rgb(141, 146, 170)";
export const ORANGE = "rgb(219, 138, 2)";

export const BLUE_GREEN = "rgb(57, 147, 44)";

export const DEFAULT_RED = "rgb(204, 0, 0)"; // TN Flag Red "#CC0000";
export const RED = DEFAULT_RED;
export const RED_1 = "rgb(255, 162, 162)"; // "#FFA2A2"
export const RED_2 = "rgb(255, 95, 95)"; // "#FF5F5F"
export const RED_3 = "rgb(255, 0, 0)"; // "#FF0000"
export const RED_4 = "rgb(187, 0, 0)"; // "#BB0000"
export const RED_5 = "rgb(119, 0, 0)"; // "#770000"
export const RED_6 = "rgb(51, 0, 0)"; // "#330000"

export const DEFAULT_BLUE = "rgb(0, 45, 101)";
export const HEADER_BLUE = DEFAULT_BLUE;
export const BLUE = DEFAULT_BLUE;
export const BLUE_1 = "rgb(170, 210, 234)"; // "#AAD2EA"
export const BLUE_2 = "rgb(74, 154, 201)"; // "#4A9AC9"
export const BLUE_3 = "rgb(0, 0, 0)"; // "#0270BA"
export const BLUE_4 = "rgb(2, 112, 186)"; // "#005587"
export const BLUE_5 = "rgb(0, 55, 102)"; // "#003766"
export const BLUE_6 = "rgb(0, 31, 69)"; // "#001F45"

// GREEN
export const GREEN_1 = "rgb(199, 217, 190)"; // "#c7d9be"
export const GREEN_2 = "rgb(133, 181, 125)"; // "#85b57d"
export const GREEN_3 = "rgb(57, 147, 55)"; // "#399337"
export const GREEN_4 = "rgb(23, 116, 27)"; // "#17741B"
export const GREEN_5 = "rgb(7, 90, 15)"; // "#075A0F"
export const GREEN_6 = "rgb(0, 65, 7)"; // "#004107"


export const PROGRESS_COLORS = () => {
    return [
        BLUE_3,
        BLUE_GREEN,
        GREEN_3
    ]
}

export const POLITICAL_PARTY_COLORS = {
    republican: "#e5211e",
    democratic: "#0044c8",
    independent: "#BBBBBB",
}

export function getPartyColor(party) {
  return POLITICAL_PARTY_COLORS[party];
}
