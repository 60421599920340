import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";

import MapPage from "@src/pages/MapPage";

function MapPageWrapper(props) {
    const { mapSlug } = useParams();
     return <MapPage mapSlug={mapSlug} key={`map_${mapSlug}`} />
}

export default MapPageWrapper;
