import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ElectionSeatInfo.scss";
import map from "lodash/map";
import ElectionCandidateCard from "@src/components/elections/ElectionCandidateCard";

function ElectionSeatInfo({
    seat,
}) {
    const candidates = useMemo(() => {
        if (!seat) {
            return [];
        }
        const allCandidates = map(seat.candidates, (candidate, index) => {
            const isRecommended = candidate.id === seat.recommendedCandidate?.id;
            return (
                <ElectionCandidateCard
                    key={candidate.candidateId}
                    candidate={candidate}
                    isRecommended={isRecommended}
                    showVs={index !== 0}
                />
            );
        });

        if (allCandidates.length === 1) {
            allCandidates.push(<ElectionCandidateCard key={`${seat.id}-empty`} wasUnopposed showVs />)
        }

        return allCandidates;

    }, [seat]);

     const seatLabel = useMemo(() => {
        if (seat.seat) {
            const { featureLabel } = seat.seat;
            return featureLabel ? `${seat.seat.name} - ${featureLabel}` : seat.seat.name;
        }
        return null;
    }, [seat]);

    return (
        <div className={styles.root}>
            <h4>{seatLabel}</h4>
            <div className={styles.candidates}>
                {candidates}
            </div>
        </div>
    );
}


ElectionSeatInfo.propTypes = {
    seat: PropTypes.object.isRequired,
}

export default connect(
    (state, ownProps) => ({
        // property: state.property,
    }),
    (dispatch) => ({
        // function: () => dispatch(ACTION.function()),
    })
)(ElectionSeatInfo);
