// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MapComponent__root{display:flex;flex-grow:1;height:100%;width:100%;min-height:300px;max-height:700px}.MapComponent__root .MapComponent__map{background-color:#f8f8f8;width:100%;height:100%;display:flex;justify-content:center;align-items:center;flex:1;position:relative}.MapComponent__root .MapComponent__map.MapComponent__darkBorder{border:1px solid #333333}.MapComponent__root .MapComponent__map .MapComponent__map-no-data{position:absolute;z-index:1;background-color:#fff;top:0;right:0;bottom:0;left:0;display:flex;justify-content:center;align-items:center}.MapComponent__root .MapComponent__loadingMessage{position:absolute;top:1.5em;z-index:1000;padding:0.5em;display:flex;font-size:2em;font-family:Oswald, sans-serif;letter-spacing:2px;text-transform:uppercase;background-color:#399337;color:#ffffff;box-shadow:4px 4px 8px black}
`, "",{"version":3,"sources":["webpack://./web/src/components/maps/MapComponent/MapComponent.scss"],"names":[],"mappings":"AAGA,oBACE,YAAa,CACb,WAAY,CACZ,WAAY,CACZ,UAAW,CACX,gBAAiB,CACjB,gBAAiB,CANnB,uCASI,wBAAyB,CACzB,UAAW,CACX,WAAY,CACZ,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACnB,MAAO,CACP,iBAAkB,CAhBtB,gEAmBM,wBAAyB,CAnB/B,kEAuBM,iBAAkB,CAClB,SAAU,CACV,qBAAsB,CACtB,KAAM,CACN,OAAQ,CACR,QAAS,CACT,MAAO,CACP,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CAhCzB,kDAqCI,iBAAkB,CAClB,SAAU,CACV,YAAa,CACb,aAAc,CACd,YAAa,CACb,aAAc,CACd,8BAA+B,CAC/B,kBAAmB,CACnB,wBAAyB,CACzB,wBAAyB,CACzB,aAAc,CACd,4BAA6B","sourcesContent":["@import \"@commonstyles/vars.scss\";\n@import \"@commonstyles/colors.scss\";\n\n.root {\n  display: flex;\n  flex-grow: 1;\n  height: 100%;\n  width: 100%;\n  min-height: 300px;\n  max-height: 700px;\n\n  .map {\n    background-color: #f8f8f8;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex: 1;\n    position: relative;\n\n    &.darkBorder {\n      border: 1px solid #333333;\n    }\n\n    .map-no-data {\n      position: absolute;\n      z-index: 1;\n      background-color: #fff;\n      top: 0;\n      right: 0;\n      bottom: 0;\n      left: 0;\n      display: flex;\n      justify-content: center;\n      align-items: center;\n    }\n  }\n\n  .loadingMessage {\n    position: absolute;\n    top: 1.5em;\n    z-index: 1000;\n    padding: 0.5em;\n    display: flex;\n    font-size: 2em;\n    font-family: Oswald, sans-serif;\n    letter-spacing: 2px;\n    text-transform: uppercase;\n    background-color: #399337;\n    color: #ffffff;\n    box-shadow: 4px 4px 8px black;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `MapComponent__root`,
	"map": `MapComponent__map`,
	"darkBorder": `MapComponent__darkBorder`,
	"map-no-data": `MapComponent__map-no-data`,
	"loadingMessage": `MapComponent__loadingMessage`
};
export default ___CSS_LOADER_EXPORT___;
