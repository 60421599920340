import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./IconTag.scss";
import Icon from "@src/components/shared/elements/Icon";

function IconTag({
    classes = "",
    color = "",
    disabled = false,
    hideIcon,
    iconName,
    iconOnly,
    labelText = "",
    solidIcon,
    extraStyles = {}
}) {
    const tipId = useMemo(() => {
        return new Date().getTime();
    }, []);

    const label = useMemo(() => {
        if (labelText && !iconOnly) {
            return <span className={styles.label}>{labelText}</span>
        }
        return null;
    }, [labelText, iconOnly]);

    return (
        <div
            className={classnames(styles.root, classes, {
                [styles.red]: color === "red",
                [styles.blue]: color === "blue",
                [styles.gray]: color === "gray",
                [styles.white]: color === "white",
                [styles.disabled]: disabled,
            })}
            style={extraStyles}
        >
            {!hideIcon && <Icon iconClass={styles.icon} solid={solidIcon} iconName={iconName} />}
            {label}
        </div>
    );
}


IconTag.propTypes = {
    labelText: PropTypes.string,
    classes: PropTypes.string,
    iconName: PropTypes.string,
    hideIcon: PropTypes.bool,
    iconOnly: PropTypes.bool,
    color: PropTypes.string,
    solidIcon: PropTypes.bool,
    extraStyles: PropTypes.object,
    disabled: PropTypes.bool,
}

export default IconTag;
