// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RatingsTag__root{margin-left:0.5rem;display:flex;flex-direction:row;align-items:center}.RatingsTag__root .Icon__root{font-size:1.35rem;height:1.5em}.RatingsTag__root .RatingsTag__imageWrapper{width:48px;height:40px;display:flex;flex-direction:row;align-items:flex-end}.RatingsTag__root .RatingsTag__imageWrapper .RatingsTag__image{height:48px;flex:1}.RatingsTag__root .RatingsTag__imageWrapper .RatingsTag__image>img{height:100%;width:100%}.RatingsTag__root.RatingsTag__small{margin:0}.RatingsTag__root.RatingsTag__small .Icon__root{height:1.35rem}.RatingsTag__root.RatingsTag__small .RatingsTag__imageWrapper{height:36px;width:36px;align-items:flex-start}.RatingsTag__root.RatingsTag__small .RatingsTag__imageWrapper .RatingsTag__image{height:36px}
`, "",{"version":3,"sources":["webpack://./web/src/components/PoliticianInfo/RatingsTag/RatingsTag.scss"],"names":[],"mappings":"AAGA,kBACE,kBAAmB,CACnB,YAAa,CACb,kBAAmB,CACnB,kBAAmB,CAJrB,8BAOI,iBAAkB,CAClB,YAAa,CARjB,4CAYI,UAAW,CACX,WAAY,CACZ,YAAa,CACb,kBAAmB,CACnB,oBAAqB,CAhBzB,+DAmBM,WAAY,CACZ,MAAO,CApBb,mEAuBQ,WAAY,CACZ,UAAW,CAxBnB,oCA8BI,QAAS,CA9Bb,gDAgCM,cAAe,CAhCrB,8DAoCM,WAAY,CACZ,UAAW,CACX,sBAAuB,CAtC7B,iFAyCQ,WAAY","sourcesContent":["@import \"@commonstyles/vars.scss\";\n@import \"@commonstyles/colors.scss\";\n\n.root {\n  margin-left: 0.5rem;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n\n  :global(.Icon__root) {\n    font-size: 1.35rem;\n    height: 1.5em;\n  }\n\n  .imageWrapper {\n    width: 48px;\n    height: 40px;\n    display: flex;\n    flex-direction: row;\n    align-items: flex-end;\n\n    .image {\n      height: 48px;\n      flex: 1;\n\n      > img {\n        height: 100%;\n        width: 100%;\n      }\n    }\n  }\n\n  &.small {\n    margin: 0;\n    :global(.Icon__root) {\n      height: 1.35rem;\n    }\n\n    .imageWrapper {\n      height: 36px;\n      width: 36px;\n      align-items: flex-start;\n\n      .image {\n        height: 36px;\n      }\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `RatingsTag__root`,
	"imageWrapper": `RatingsTag__imageWrapper`,
	"image": `RatingsTag__image`,
	"small": `RatingsTag__small`
};
export default ___CSS_LOADER_EXPORT___;
