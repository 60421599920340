import React, {useEffect, useMemo, useState} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ArticlePreview.scss";
import moment from "moment";

function ArticlePreview({ article }) {
    const publishedDate = moment(article.publishedDate).format("MMMM DD, YYYY");
    const authorName = "Williamson County Citizens"; // article.author?.fullName || "";
    return (
        <Link className={styles.root} to={`/news/${article.path}/`}>
            <div className={styles.date}>{publishedDate}</div>
            <h3>{article.headline}</h3>
            <div className={styles.author}>By {authorName}</div>
            <div className={styles.bodyText}>{article.summary}</div>
        </Link>
    );
}


ArticlePreview.propTypes = {
    article: PropTypes.object
}

export default ArticlePreview;
