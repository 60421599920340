
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import Root from "@src/Root";
import "@commonstyles/global.scss";
import "@commonstyles/buttons.scss";

function getEnvironmentName() {
    const loc = window.location.hostname;
    switch(loc) {
        case "williamsoncountycitizens.org":
            return "production";
        default:
            return "localhost";
    }
}

const environmentName = getEnvironmentName();
if(environmentName === "production") {
    Sentry.init({
        dsn: "https://77812b8767357cd11360085638f471c0@o4507487068553216.ingest.us.sentry.io/4507487070912512",
        environment: environmentName,
        debug: false,
        attachStacktrace: true,
        ignoreErrors: [
            "ResizeObserver loop limit exceeded",
            "No matching state found in storage",
            "Unauthorized",
            "TypeError: Cannot read properties of null (reading 'zoom')",
        ],
    });
}


ReactDOM.render(
    <Root />,
    document.getElementById("app")
);

if (DEVELOPMENT) {
    __webpack_public_path__ = DEVELOPMENT_SERVER_STATIC_PATH;
}
