// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RichTextEditor__root{display:flex;flex-direction:column}.RichTextEditor__root .RichTextEditor__label{margin-bottom:0.5em}.RichTextEditor__root .RichTextEditor__inputBox{flex:1;width:100%;position:relative}
`, "",{"version":3,"sources":["webpack://./web/src/components/shared/widgets/RichTextEditor/RichTextEditor.scss"],"names":[],"mappings":"AAGA,sBACE,YAAa,CACb,qBAAsB,CAFxB,6CAKI,mBAAoB,CALxB,gDASI,MAAO,CACP,UAAW,CACX,iBAAkB","sourcesContent":["@import \"@commonstyles/vars.scss\";\n@import \"@commonstyles/colors.scss\";\n\n.root {\n  display: flex;\n  flex-direction: column;\n\n  .label {\n    margin-bottom: 0.5em;\n  }\n\n  .inputBox {\n    flex: 1;\n    width: 100%;\n    position: relative;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `RichTextEditor__root`,
	"label": `RichTextEditor__label`,
	"inputBox": `RichTextEditor__inputBox`
};
export default ___CSS_LOADER_EXPORT___;
