import {requestError} from "@src/utils/request";
import * as SignupApi from "@src/api/SignupApi";
import {
    CLEAR_SIGNUP_STATUS,
    SIGNUP_FAILED,
    SIGNUP_SUCCESS,
    SIGNUP_DUPLICATED,
    SUBSCRIPTION_EDIT_REQUEST_SUCCESS,
} from "@src/constants/ActionTypes";

export function signUpUser(data) {
    return (dispatch) => {
        SignupApi.signUpUser(data)
            .then((res) => {
                dispatch({
                    type: SIGNUP_SUCCESS,
                    user: res.body
                });
            })
            .catch((err) => {
                const errorMessage = "Error creating subscription";
                if (err.status === 409) {
                    console.log("SignupActions :: signUpUser 409 error :: err.status = ", err.status);
                    dispatch({
                        type: SIGNUP_DUPLICATED,
                        error: "This email has already subscribed",
                    });
                } else {
                    console.log("SignupActions :: signUpUser :: err = ", err);
                    dispatch({
                        type: SIGNUP_FAILED,
                        error: errorMessage,
                    });
                    requestError(err, errorMessage);
                }
            });
    };
}

export function clearSignupStatus() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_SIGNUP_STATUS,
        })
    }
}

export function requestEditLink(email) {
    return (dispatch) => {
        SignupApi.requestEditLink(email)
            .then((res) => {
                dispatch({
                    type: SUBSCRIPTION_EDIT_REQUEST_SUCCESS,
                    user: res.body
                });
            })
            .catch((err) => {
                let errorMessage = "Error creating subscription";
                if (err.status === 409) {
                    errorMessage = "This email has already subscribed";
                    dispatch({
                        type: SIGNUP_DUPLICATED,
                        error: "This email has already subscribed",
                    });
                }
                console.log("SignupActions :: signUpUser :: err.status = ", err.status);

                console.log("SignupActions :: signUpUser :: err = ", err);
                dispatch({
                    type: SIGNUP_FAILED,
                    error: errorMessage,
                });

                requestError(err, "Error creating subscription");
            });
    };
}

