
export const RED = "red";
export const ORANGE = "orange";
export const YELLOW = "yellow";
export const GREEN = "green";
export const BLUE = "blue";
export const GRAY = "gray";
export const ALL = "all";


export const DEFAULT_REDS = ["#FFA2A2","#FF5F5F","#FF0000","#BB0000","#770000","#330000"];
export const DEFAULT_ORANGES = ["#FFCC99","#FFAF5F","#FFA040","#FF8800","#CF7F00","#9F5F00"];
export const DEFAULT_YELLOWS = ["#FFFF00","#FFEE33","#FFEE00","#FFDD33","#FFDD00","#FFCC00"];
export const DEFAULT_GREENS = ["#c7d9be","#85b57d","#399337","#17741B","#075A0F","#004107"];
export const DEFAULT_BLUES = ["#AAD2EA","#4A9AC9","#0270BA","#005587","#003766","#001F45"];
export const DEFAULT_GRAYS = ["#E5E5E5","#D5D5D5","#CCCCCC", "#AAAAAA", "#999999","#757575"];
export const ALL_COLOR_SETS = [
    ...DEFAULT_REDS,
    ...DEFAULT_ORANGES,
    ...DEFAULT_YELLOWS,
    ...DEFAULT_GREENS,
    ...DEFAULT_BLUES,
    ...DEFAULT_GRAYS,
];

export const COLOR_SETS = {
  [RED]: DEFAULT_REDS,
  [ORANGE]: DEFAULT_ORANGES,
  [YELLOW]: DEFAULT_YELLOWS,
  [GREEN]: DEFAULT_GREENS,
  [BLUE]: DEFAULT_BLUES,
  [GRAY]:DEFAULT_GRAYS,
  [ALL]: ALL_COLOR_SETS,
};


export function getRandomFromSet(color = ALL) {
  const colorSet = COLOR_SETS[color];
  const randomSelection = Math.floor(Math.random() * colorSet.length);
  return colorSet[randomSelection];
}

export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  const values = ['#'];
  for (let i = 0; i < 6; i++) {
    values.push(letters[Math.floor(Math.random() * 16)]);
  }
  return values.join("");
}

export function checkColorBrightness(color) {
  let r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {

    // If HEX --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  }
  else {

    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    color = +("0x" + color.slice(1).replace(
      color.length < 5 && /./g, '$&$&'
    )
             );

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(
    0.299 * (r * r) +
    0.587 * (g * g) +
    0.114 * (b * b)
  );

  // Using the HSP value, determine whether the color is light or dark
  if (hsp>127.5) {

    return 'light';
  }
  else {

    return 'dark';
  }
}

