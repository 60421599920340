import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import {
    useMapsLibrary,
} from '@vis.gl/react-google-maps';

function PlacesAutoComplete({
    areaBounds = null,
    onPlaceSelect,
    placeholderText = "Search for your address",
}) {

    const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
    const inputRef = useRef(null);
    const places = useMapsLibrary('places');

    useEffect(() => {
        if (!places || !inputRef.current) return;
        const options = {
            fields: [
                "name",
                "address_components",
                "formatted_address",
                "geometry",
                "place_id",
                "id",
                "icon",
            ]
        }

        if (areaBounds) {
            options.bounds = areaBounds;
        }

        setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
    }, [places]);

    useEffect(() => {
        if (!placeAutocomplete) return;

        placeAutocomplete.addListener('place_changed', () => {
            onPlaceSelect(placeAutocomplete.getPlace());
        });
    }, [onPlaceSelect, placeAutocomplete]);

    return (
        <div className="autocomplete-container">
            <input ref={inputRef} placeholder={placeholderText} />
        </div>
    );
}


PlacesAutoComplete.propTypes = {
    onPlaceSelect: PropTypes.func.isRequired,
    areaBounds: PropTypes.object,
    placeholderText: PropTypes.string,
}

PlacesAutoComplete.defaultProps = {}

export default PlacesAutoComplete;
