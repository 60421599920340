import React, {useEffect, useMemo, useState} from "react";

import {Link} from 'react-router-dom';
import PropTypes from "prop-types";
import classnames from "classnames";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import styles from "./UserLocationSeat.scss";
import PrimaryTag from "@src/components/elections/PrimaryTag"
import AppointedOfficialDetails from "@src/components/maps/AppointedOfficialDetails";
import LocationDetails from "@src/components/maps/LocationDetails";
import Panel from "@src/components/shared/widgets/Panel";

function UserLocationSeat({
    location,
    mapSlug,
}) {
    if (!location) {
        return null;
    }
    const columnHeaders = [
        {
            iconName: "calendar-xmark",
            label: "Reelection Year"
        },
        // {
        //     iconName: "check-to-slot",
        //     label: "Election"
        // }
    ]

    return (
        <div className={styles.root}>
            <Panel
                title={location.layer.name}
                columnHeaders={columnHeaders}
                light
                isChild
            >
                <div className={styles.seats}>
                    <LocationDetails mapSlug={mapSlug} location={location} key={`layer_${location.layer.name}`} />
                </div>
            </Panel>
        </div>
    );
}


UserLocationSeat.propTypes = {
    mapSlug: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
}

export default UserLocationSeat;

