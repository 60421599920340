import forEach from "lodash/forEach";

function replaceLink(urlText, linkText) {
    if (urlText.indexOf('youtu.be') > -1 || urlText.indexOf('youtube.com/watch') > -1) {
        return youtube(urlText);
    } else {
        return `<a href="${urlText}" target="_blank">${linkText}</a>`;
    }
}

function replaceAllUrls (text) {
    // TODO: Catching src links.... fix

    const urlRegex = /(?<!")(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])(?!abc)/ig;
    return text.replace(urlRegex, url => {
        if (url.indexOf('youtu.be') > -1 || url.indexOf('youtube.com/watch') > -1) {
            return youtube(url);
        }
        if (url.indexOf("rumble.com") > -1) {
            return rumble(url);
        }
        if (text.indexOf('href="' + url) > -1) {
            return url;
        } else {
            const newLink = `<a href="${url}" target="_blank">${url}</a>`;
            return newLink;
        }
    });
}

function youtube( url ) {
    let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|live\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    let match = url.match(regExp);
    if (match && match[2].length === 11) {
        return '<iframe width="560" height="315" src="//www.youtube.com/embed/' + match[2] + '" frameborder="0" allowfullscreen></iframe>';
    }

    // https://www.youtube.com/live/uzgIkzrEUeg?feature=share

    // Didn't return. Check the next type of link
    regExp = /^.*(youtube.com\/|v\/|u\/\w\/|embed\/|live\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    match = url.match(regExp);
    if (match && match[2].length === 11) {
        return '<iframe width="560" height="315" src="//www.youtube.com/embed/' + match[2] + '" frameborder="0" allowfullscreen></iframe>';
    }
    return url;
}

function rumble( url ) {

    let regExp = /^.*(rumble.com\/embed\/)(.*)+(\/?pub=).*/;
    // https://rumble.com/embed/v561w59/?pub=3pqs5e
    let match = url.match(regExp);
    // Is embed URL
    if (match) {
        return '<div style="position:relative; width: 100%; height: 0; padding-top: 56.25%;"><iframe width="100%" height="100%" style="position: absolute; top: 0; left: 0;" src="' + url + '" frameborder="0" allowfullscreen></iframe></div>';
    }



    regExp = /^.*(rumble.com\/)+.*(\.html\?mref=)(.*)+(\&.*)/;
    // https://rumble.com/v58e4ql-rep.-ogles-lays-out-the-case-to-impeach-kamala-harris-on-oan.html?mref=3pqs5e&mc=eq0vf
    match = url.match(regExp);

    // Is FUll URL
    if (match) {
        return '<div style="position:relative; width: 100%; height: 0; padding-top: 56.25%;"><iframe width="100%" height="100%" style="position: absolute; top: 0; left: 0;" src="https://rumble.com/embed/v561w59/?pub=' + match[2] + '" frameborder="0" allowfullscreen></iframe>';
    }

    // Didn't return. Try just a link
    return url;
}



export function replaceMentionLink(link) {
    const replacement = link.replace("href=", "data-id=").replace('class="', 'class="mention ');
    return replacement;
}

export function formatArticle(text) {
    /*
    * Quill editor automatically wraps links when the user hits return
    * <a href=[url]>[url]</a>
    * Be sure to remove the tag if it's YouTube
    */
    if (!text) {
        return text;
    }

    let modifiedText = '';
    if (text.indexOf('<a ') !== -1) {
        // If text contains an <a tag, replace the whole thing
        modifiedText = text;
        let hasLink = true;
        let lastIndex = 0;
        let loopIndex = 0;
        while(hasLink && loopIndex < 20) {
            // process all the links
            const linkStart = lastIndex + modifiedText.substring(lastIndex).indexOf('<a ');
            const linkEnd = lastIndex + modifiedText.substring(lastIndex).indexOf('</a>') + 4;
            const textBefore = modifiedText.slice(0, linkStart);
            const textAfter = modifiedText.slice(linkEnd);

            const link = modifiedText.slice(linkStart, linkEnd);
            let linkReplacement = '';
            const splitLink = link.split('href="');
            let linkUrl = splitLink[1].split('"')[0];
            const linkText = link.split('>')[1].split('<')[0];

            if (link.indexOf('data-mention') !== -1) {
                // This is a mention/tag
                linkReplacement = replaceMentionLink(link);
            } else {
                linkReplacement = replaceLink(linkUrl, linkText);
            }

            modifiedText = `${textBefore}${linkReplacement}${textAfter}`;

            lastIndex = textBefore.length + linkReplacement.length;
            hasLink = (modifiedText.substring(lastIndex).indexOf('<a ') > -1); // Do it again?
            loopIndex++;
        }
    }
    modifiedText = replaceAllUrls(modifiedText||text);

    return modifiedText.replace('#caret', '')
}

export function addListenersToMentions(currentContent, postText, setUserId) {
    if (postText.indexOf("mention") !== -1 && currentContent) {
        const mentionLinks = currentContent.getElementsByClassName("mention");
        forEach(mentionLinks, link => {
            const userStudioId = link.getAttribute("data-id");
            link.addEventListener("click", () => {
                setUserId(userStudioId);
            });
        });
    }
}
