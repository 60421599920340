import * as LocationTypes from "@src/constants/LocationTypes";

export function isValidAddressForOrg(placesInfo, { locationType, locationName }) {

    let validationField;

    switch (locationType) {
        case LocationTypes.FEDERAL:
            validationField = "country";
        case LocationTypes.STATE:
            validationField = LocationTypes.STATE;
        case LocationTypes.DISTRICT:
            validationField = LocationTypes.DISTRICT;
        case LocationTypes.COUNTY:
            validationField = LocationTypes.COUNTY;
        case LocationTypes.CITY:
            validationField = LocationTypes.CITY;
        case LocationTypes.PRECINCT:
            validationField = LocationTypes.PRECINCT;
        default:
            validationField = LocationTypes.COUNTY;
    }

    const placeLocation = placesInfo?.[validationField];
    return placeLocation && placeLocation === locationName;
}

export function formatPlaceData(placeInfo) {
    const {address_components: address} = placeInfo;
    const addressLine = `${address?.[0].long_name||""} ${address?.[1].long_name || ""}`;
    return {
        ...placeInfo,
        lat: placeInfo.geometry.location.lat(),
        lng: placeInfo.geometry.location.lng(),
        address: addressLine,
        city: address[2]?.long_name,
        county: address[3]?.long_name,
        stateProvince: address[4]?.short_name,
        country: address[5]?.long_name,
        postalCode: address[6]?.long_name,
        googlePlaceId: placeInfo.place_id,
    }
}
