
import {combineReducers} from "redux";
import * as ActionTypes from "@src/constants/ActionTypes";
import cloneDeep from "lodash/cloneDeep";
import forEach from "lodash/forEach";
import keyBy from "lodash/keyBy";
import omit from "lodash/omit";
import {CLEAR_SIGNUP_STATUS, SIGNUP_DUPLICATED, SUBSCRIPTION_EDIT_REQUEST_SUCCESS} from "@src/constants/ActionTypes";

function windowSize(state=null, action) {
    switch (action.type) {
        case ActionTypes.WINDOW_SIZE_SET:
            return action.dimensions;
        default:
            return state;
    }
}

function renderMode(state={}, action) {
    switch (action.type) {
        case ActionTypes.WINDOW_SIZE_SET:
            return {
                mobile: action.dimensions.width < 1024,
            };
        default:
            return state;
    }
}

function loadingFeed(state=false, action) {
    switch (action.type) {
        case ActionTypes.START_ARTICLE_FEED_LOAD:
            return true;
        case ActionTypes.ARTICLE_FEED_LOADED:
            return false;
        default:
            return state;
    }
}

function lastCommentId(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENTS_LOADED:
            return action.commentResponse.lastCommentId;
        default:
            return state;
    }
}

function bustCache(state = false, action) {
    switch (action.type) {
        case ActionTypes.BUST_CACHE:
            return true;
        case ActionTypes.CACHE_BUSTED:
            return false;
        default:
            return state;
    }
}

function lastReplyIds(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENTS_LOADED:
            const commentReplies = {};
            forEach(action.commentResponse.results, (c) => {
                const hasMore = !!c.replies && c.replies.results.length < c.replies.total;
                commentReplies[c.id] = hasMore ? c.replies.results[c.replies.results.length - 1].id : null;
            });
            return {
                ...state,
                ...commentReplies,
            };
        case ActionTypes.REPLIES_LOADED:
            return {
                ...state,
                [action.commentId]: action.replyResult.results.length < STANDARD_RESULTS_PER_PAGE ? null : action.replyResult.lastCommentId,
            };
        default:
            return state;
    }
}

function numberOfReplies(state={}, action) {
    switch (action.type) {
        case ActionTypes.COMMENTS_LOADED:
            const commentReplies ={};
            forEach(action.commentResponse.results, (c) => {
                commentReplies[c.id] = c.replies && c.replies.results.length ? c.replies.total : 0;
            });
            return {
                ...state,
                ...commentReplies,
            };
        default:
            return state;
    }
}

function savingUserProfile(state=false, action) {
    switch (action.type) {
        case ActionTypes.START_SAVE_USER_PROFILE:
        case ActionTypes.START_SAVE_USER_EMAIL:
            return true;
        case ActionTypes.CURRENT_USER_UPDATED:
        case ActionTypes.USER_PROFILE_SAVED_ERROR:
            return false;
        default:
            return state;
    }
}

function currentOrganization(state=null, action) {
    switch (action.type) {
        case ActionTypes.CURRENT_ORGANIZATION_LOADED:
            return {
                ...action.organization,
            };
        default:
            return state;
    }
}

function windowIsActive(state = true, action) {
    switch (action.type) {
        case ActionTypes.WINDOW_BLUR_EVENT:
            return false;
        case ActionTypes.WINDOW_FOCUS_EVENT:
            return true;
        default:
            return state;
    }
}

function signupStatus(state = { success: false }, action) {
    switch (action.type) {
        case ActionTypes.SIGNUP_SUCCESS:
            // use this to redirect them to a server page which logs them in then redirects to the welcome page
            return {
                user: action.user,
                success: true,
            };
        case ActionTypes.SIGNUP_FAILED:
            return {
                success: false,
                error: action.error,
            };
        case ActionTypes.SIGNUP_DUPLICATED:
            return {
                success: false,
                duplicate: true,
                error: action.error,
            };
        case ActionTypes.SUBSCRIPTION_EDIT_REQUEST_SUCCESS:
            return {
                success: false,
                editRequested: true,
            };
        case ActionTypes.CLEAR_SIGNUP_STATUS:
            return {
                success: false
            };
        default:
            return state;
    }
}

function isLoggedIn(state = false, action) {
    switch (action.type) {
        case ActionTypes.SIGNUP_SUCCESS:
        case ActionTypes.CURRENT_USER_LOADED:
            return true;
        default:
            return state;
    }
}

function articleEditorState(state = { created: null, editing: null }, action) {
    switch (action.type) {
        case ActionTypes.CLEAR_EDITOR_STATE:
        case ActionTypes.CREATING_ARTICLE:
            return {
                created: null,
                editing: null,
                published: false,
            }
        case ActionTypes.EDITING_ARTICLE:
            console.log("appState :: articleEditorState :: action.article = ", action.article);
            return {
                created: null,
                editing: action.article,
                updated: false,
                published: false,
            }
        case ActionTypes.ARTICLE_CREATED:
            return {
                created: action.article.path,
                editing: null,
                updated: false,
                published: false,
            }
        case ActionTypes.ARTICLE_UPDATED:
            return {
                ...state,
                updated: true,
                published: false,
            }
        case ActionTypes.ARTICLE_PUBLISHED:
            return {
                ...state,
                updated: true,
                published: true,
            }
        default:
            return state;
    }
}

function siteNotifications(state = {}, action) {
    switch (action.type) {
        case ActionTypes.ACTIVE_SITE_NOTIFICATIONS_LOADED:
            return keyBy(action.siteNotifications, "id");
        case ActionTypes.ACTIVE_SITE_NOTIFICATION_DISMISSED:
            return omit(state, action.siteNotificationId);
        default:
            return state;
    }
}

function posterSuccess(state = {}, action) {
    switch (action.type) {
        case ActionTypes.COMMENT_POSTED:
        case ActionTypes.REPLY_POSTED:
        case ActionTypes.ARTICLE_PUBLISHED:
        case ActionTypes.ARTICLE_UPDATED:
        case ActionTypes.SITE_NOTIFICATION_CREATED:
        case ActionTypes.SITE_NOTIFICATION_UPDATED:
            return {
                ...state,
                [action.posterId]: true,
            };
        case ActionTypes.CLEAR_SUCCESS:
            return {
                ...state,
                [action.posterId]: false,
            };
        default:
            return state;
    }
}

export default combineReducers({
    articleEditorState,
    bustCache,
    currentOrganization,
    lastCommentId,
    lastReplyIds,
    loadingFeed,
    numberOfReplies,
    posterSuccess,
    renderMode,
    savingUserProfile,
    signupStatus,
    siteNotifications,
    windowIsActive,
    windowSize,
    isLoggedIn,
});
