import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Hero.scss";
import getStaticFile from "@src/utils/getStaticFile";
import Button from "@src/components/shared/elements/Button";

function Hero({
    alignLeft,
    buttonText,
    darkText,
    headerText,
    imageFilename,
    onButtonClick,
    subpage,
    subtitleText,
}) {
    const handleButtonClick = () => {
        if (onButtonClick) {
            onButtonClick();
        }
    }

    const sectionBackgroundImage = getStaticFile(`img/${imageFilename}`);
    return (
        <div className={classnames(styles.root, {
            [styles.subpage]: subpage,
        })} style={{backgroundImage: `url(${sectionBackgroundImage})` }}>
            <div className={classnames(styles.heroText, {
                [styles.dark]: darkText,
                [styles.alignLeft]: alignLeft,
            })}>
                <div className={styles.textContainer}>
                    <div className={styles.header}>{headerText}</div>
                    {subtitleText && <div className={styles.subtitle}>{subtitleText}</div>}
                    {!subpage && (
                        <Button
                            className={styles.headerButton}
                            onClick={handleButtonClick}
                            buttonText={buttonText}
                            buttonColor={"red"}
                            large
                            wide
                        />
                    )}
                </div>
            </div>
        </div>
    );
}


Hero.propTypes = {
    imageFilename: PropTypes.string.isRequired,
    buttonText: PropTypes.string,
    headerText: PropTypes.string.isRequired,
    subtitleText: PropTypes.node,
    darkText: PropTypes.bool,
    onButtonClick: PropTypes.func,
    alignLeft: PropTypes.bool,
    subpage: PropTypes.bool,
}

export default Hero;
