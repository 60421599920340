
import request from "@src/utils/request";


export function loadCurrentUser() {
    return request()
        .get("/api/v1/current-user/")
        .send();
}

export function updateCurrentUser(currentUser) {
    return request()
        .set("Content-Type", null)
        .patch("/api/v1/current-user/")
        .send(currentUser);
}

export function loadCurrentOrganization() {
    return request()
        .get("/api/v1/current-organization/")
        .send();
}

export function updateNotificationSettings(notificationSettings) {
    return request()
        .patch(`/api/v1/notification-settings/`)
        .send(notificationSettings);
}

export function updateUserEmail({ email, password }) {
    return request()
        .post("/api/v1/update-user-email/")
        .send({
            email,
            password,
        })
}

export function logoutUser() {
    return request()
        .post("/logout/")
        .send()
}
