import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import PropTypes from "prop-types";
import classnames from "classnames";
import filter from "lodash/filter";
import find from "lodash/find";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import styles from "./FeaturesListItem.scss";
import PrimaryTag from "@src/components/elections/PrimaryTag"
import AppointedOfficialDetails from "@src/components/maps/AppointedOfficialDetails";
import LocationDetails from "@src/components/maps/LocationDetails";
import Panel from "@src/components/shared/widgets/Panel";

const COLUMN_HEADERS = [
    {
        iconName: "",
        label: "Reelection Year"
    },
    // {
    //     iconName: "",
    //     label: "Upcoming Election Date"
    // }
]
function FeaturesListItem({
    appointed = false,
    elected = false,
    layer,
    mapSlug,
    election,
    agencySeats,
    electedSeats,
    layerDescription,
}) {
    const officials = useMemo(() => {
        if (!elected || !electedSeats?.length) {
            return [];
        }
        return map(sortBy(filter(layer.features, lFeat => lFeat.district !== null), ft => ft.district), location => {
            return (
                 <LocationDetails
                     mapSlug={mapSlug}
                     name={layer.name}
                     location={location}
                     layerKey={layer.key}
                     key={location.id}
                     columnHeaders={COLUMN_HEADERS}
                 />
            )
        });
    }, [elected, layer]);

    const appointedOfficials = useMemo(() => {
        if (!appointed || !agencySeats?.length) {
            return [];
        }
        return map(agencySeats, seat => {
            return (
                 <AppointedOfficialDetails
                     seat={seat}
                     key={seat.id}
                 />
            )
        });

    }, [appointed, layer]);

    if (!appointedOfficials?.length && !officials?.length) {
        return null;
    }


    return (
        <div className={styles.root}>
            <Panel
                columnHeaders={COLUMN_HEADERS}
                title={layer.name}
                infoText={layerDescription}
                infoTitle={"Roles & Responsibilities"}
                showInfoTitle
                light
                isChild
            >
                <div className={styles.seats}>
                    {appointedOfficials}
                    {officials}
                </div>
            </Panel>
        </div>
    );
}


FeaturesListItem.propTypes = {
    layer: PropTypes.object.isRequired,
    mapSlug: PropTypes.string.isRequired,
    appointed: PropTypes.bool,
    elected: PropTypes.bool,
}

FeaturesListItem.defaultProps = {
}

export default connect(
    (state, ownProps) => {
        const mapLayer = state.mapState.mapLayers[ownProps.mapSlug]?.[ownProps.layer.key];
        const election = mapLayer?.elections?.[0] || null;
        const agencySeats = mapLayer?.agencySeats || null;
        const electedSeats = mapLayer?.seats || null;
        const mapConfig = state.mapState.maps[ownProps.mapSlug];
        const fullLayerInfo = find(mapConfig.mapLayers, mlayr => mlayr.geoLocation.id === mapLayer.id);
        const layerDescription = fullLayerInfo?.description || "";
        return {
            election,
            agencySeats,
            electedSeats,
            layerDescription,
        }
    },
    (dispatch) => ({
        // function: () => dispatch(ACTION.function()),
    })
)(FeaturesListItem);
