import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./PoliticianInfo.scss";
import RatingsTag from "@src/components/PoliticianInfo/RatingsTag";
import PrimaryTag from "@src/components/elections/PrimaryTag";
import OpportunityTag from "@src/components/OpportunityTag";
import * as ElectionActions from "@src/actions/ElectionActions";
import moment from "moment";
import IconTag from "@src/components/IconTag";
import Modal from "@src/components/shared/elements/Modal";

function PoliticianInfo({
    columnHeaders,
    id,
    election,
    opportunity,
    politician,
    rating,
    seatLabel,
    seatRoles,
    termEnd,
}) {
    const [showRoles, setShowRoles] = useState(false);
    const name = useMemo(() => {
        if (politician) {
            return `${politician.firstName} ${politician.lastName}`;
        }
        return "Currently Vacant";
    }, [politician]);

    const handleShowRoles = () => {
        setShowRoles(true);    }

    const seatRolesHTML = useMemo(() => {
        if (seatRoles) {
            return `<div>${seatRoles.replace(/-/g, "&bull;&nbsp;").replace(/\n/g, '<br />')}</div>`;
        }
        return "";
    }, [seatRoles]);

    const seatInfo = useMemo(() => {
        if (seatRoles) {
            return <div onClick={handleShowRoles} className={styles.seatInfo}>
                <span>{seatLabel}</span> <i className="fa-solid fa-info-circle" />
            </div>
        }
        return seatLabel;
    }, [seatLabel, seatRoles]);


    const termEndDate = termEnd ? moment(termEnd).format("YYYY") : "";
    return (
        <div className={classnames(styles.root, {
            [styles.vacant]: !politician,
        })}>
            <div className={styles.mainContent}>
                <div className={styles.nameRating}>
                    <h3>{name}</h3>
                    <RatingsTag rating={rating} small />
                </div>
                <div className={styles.seatName}>{seatInfo}</div>
                {politician?.website && (
                    <div className={styles.websiteLink}>
                        <a href={politician.website} target="_blank">Campaign Website</a>
                    </div>
                )}
            </div>
            <div className={styles.extraContent}>

                <div className={styles.contentColumn}>
                    {columnHeaders?.[0]?.label && (
                        <div className={styles.mobileLabel}>
                            {columnHeaders[0].label}
                        </div>
                    )}
                    <IconTag
                        labelText={termEndDate || "N/A"}
                        color={"white"}
                        iconName={"calendar-xmark"}
                        classes={"termEnd"}
                        hideIcon
                    />
                </div>
                {/*{opportunity && <OpportunityTag opportunity={opportunity} />}*/}
                {/*<div className={styles.contentColumn}>*/}
                {/*    {columnHeaders?.[1]?.label && (*/}
                {/*        <div className={styles.mobileLabel}>*/}
                {/*            {columnHeaders[1].label}*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*    {election ? (*/}
                {/*        <Link to={`/elections/${election.electionDay}`}>*/}
                {/*            <PrimaryTag isPrimary={election.isPrimary} votingDay={election.votingDay} />*/}
                {/*        </Link>*/}
                {/*    ): (*/}
                {/*        <IconTag*/}
                {/*            labelText={"N/A"}*/}
                {/*            color={"white"}*/}
                {/*            iconName={"calendar-xmark"}*/}
                {/*            extraStyles={{ width: "125px" }}*/}
                {/*            hideIcon*/}
                {/*            disabled*/}
                {/*        />*/}
                {/*    )}*/}
                {/*</div>*/}
            </div>

            <Modal
                modalTitle={"Roles & Responsibilites"}
                show={showRoles}
                clickOutsideToClose
                onClose={() => setShowRoles(false)}
                small
            >
                <div dangerouslySetInnerHTML={{ __html: seatRolesHTML }} />
            </Modal>
        </div>
    );
}


PoliticianInfo.propTypes = {
    columnHeaders: PropTypes.array,
    id: PropTypes.string.isRequired,
    opportunity: PropTypes.object,
    termEnd: PropTypes.string,
    seatLabel: PropTypes.string,
    seatRoles: PropTypes.string,
    election: PropTypes.object,
}

PoliticianInfo.defaultProps = {}

export default connect(
    (state, ownProps) => {
        const politician = state.entities.politicians[ownProps.id];
        const { rating } = politician || {};
        return {
            politician,
            rating,
        }
    },
    null
)(PoliticianInfo);
