
import {combineReducers} from "redux";
import * as ActionTypes from "@src/constants/ActionTypes";
import keyBy from "lodash/keyBy";
import {MAP_LOCATION_CLEAR} from "@src/constants/ActionTypes";


function location(state = null, action) {
    switch (action.type) {
        case ActionTypes.MAP_LOCATION_SET:
            return action.location;
        case ActionTypes.MAP_LOCATION_CLEAR:
            return null;
        default:
            return state;
    }
}

function mapLayers(state = {}, action) {
    switch (action.type) {
        case ActionTypes.MAP_LAYER_SET:
            return {
                ...state,
                [action.slug]: {
                    ...state[action.slug],
                    [action.layerData.featureId]: action.layerData,
                },
            };
        default:
            return state;
    }
}

function geoLocations(state={}, action) {
    switch (action.type) {
        case ActionTypes.GEOLOCATIONS_LOADED:
        case ActionTypes.GEOLOCATIONS_UPDATED:
            return action.locations;
        default:
            return state;
    }
}

function maps(state = null, action) {
    switch (action.type) {
        case ActionTypes.MAPS_LIST_LOADED:
            const shallowMaps = keyBy(action.maps, "slug");
            return {
                ...shallowMaps,
                ...state,
            };
        case ActionTypes.MAP_DETAILS_LOADED:
            return {
                ...state,
                [action.map.slug]: action.map,
            }
        default:
            return state;
    }
}

function votingLocations(state = null, action) {
    switch (action.type) {
        case ActionTypes.VOTING_LOCATION_CREATED:
            return {
                ...state,
                [action.location.id]: action.location,
            };
        case ActionTypes.VOTING_LOCATIONS_LOADED:
            return keyBy(action.locations, "id");
        default:
            return state;
    }
}

export default combineReducers({
    location,
    mapLayers,
    geoLocations,
    maps,
    votingLocations,
});
