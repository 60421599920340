import React, {useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "../HomePage/HomePage.scss";
import Button from "@src/components/shared/elements/Button";
import BottomContent from "@src/components/BottomContent";
import Hero from "@src/components/Hero";
import {setPageTitle} from "@src/utils/windowHelpers";

function AboutPage(props) {
    const ourValuesRef = useRef();

    useEffect(() => {
        setPageTitle("About", window.location);
    }, []);

    useEffect(() => {
        const deepLink = window.location.hash;
        if (deepLink === "#ourValues" && ourValuesRef.current) {
            const linkPos = ourValuesRef.current.getBoundingClientRect();
            setTimeout(() => {
                window.scrollTo({ left: 0, top: linkPos.top, behavior: "smooth" });
            }, 250);
        }
    }, [ourValuesRef]);

    return (
        <div className={styles.root}>
            <Hero
                imageFilename={"wcc_hero_background_strip.jpg"}
                imageAltText={"American flag and Tennessee flying"}
                headerText={"Williamson County Citizens"}
                subpage
            />
            <main className={styles.main}>
                <section>
                    <h3>Description</h3>
                    <div className={styles.divider} />
                    <p>We are a network of local voters that elect grassroots conservatives to public office.</p>
                </section>
                <section>
                    <a name="ourValues" ref={ourValuesRef} />
                    <h3>Our Values</h3>
                    <div className={styles.divider} />
                    <p><strong>What is a grassroots conservative and how do they differ from establishment republicans?</strong></p>
                    <ul className={styles.redBullets}>
                        <li>Grassroots conservatives <strong>always support</strong> fraud-proof elections. This means:
                            <ul className={styles.redBullets}>
                                <li>Paper ballots instead of machines</li>
                                <li>Precincts instead of voting centers</li>
                                <li>Closed primaries instead of open primaries</li>
                                <li>Citizen-only voting</li>
                            </ul>
                        </li>
                        <li>Grassroots conservatives <strong>are not funded</strong> by special interest groups. Our local elections are influenced by millions upon millions of dollars that flow to establishment republicans from outside Tennessee and even outside the US.</li>
                        <li>Grassroots conservatives <strong>do not promote</strong> or align with DEI, Pride, or Woke ideologies.</li>
                        <li>Grassroots conservatives <strong>do limit</strong> the size and reach of government by reducing its spending, regulations, taxation, and debt.</li>
                        <li>Grassroots conservatives <strong>never seek</strong> to silence or destroy their political opponents, but rather seek to win elections using ideas and truth.</li>
                        {/*<li>Grassroots conservatives <strong>are courageous</strong> and hold firm to America First principles even when under pressure from the political machine.</li>*/}
                        <li>Grassroots conservatives <strong>do not desire</strong> to protect or increase their own institutional power. Their loyalty is to their constituents and the principles of the American founding.</li>
                        <li>Grassroots conservatives <strong>support</strong> other grassroots conservative candidates.</li>
                    </ul>
                </section>
                <section>
                    <h3>We'd Like to Pose a Couple of Questions:</h3>
                    <div className={styles.divider} />
                    <p>Are you happy with the way the Republican Party is representing your interests in Washington DC?</p>
                    <p>Are you aware this same establishment agenda is prevalent among many of your elected officials here in Williamson County? This is unfortunately the case, but we are here to help you change it.</p>
                </section>
                <section>
                    <h3>We do the Legwork</h3>
                    <div className={styles.divider} />
                    <p>Most conservatives don't have the time to attend school board meetings, research local candidates and figure out who to vote for. This has resulted in our local government being over-represented with "old guard" establishment politicians and agendas. Most of the culprits are Republicans.</p>
                </section>

                <section>
                    <h3>We inform</h3>
                    <div className={styles.divider} />
                    <p>We let you know when local elections are taking place, where to vote, and which candidates truly represent your values. Many of our local elections have a small margin of victory and often a low turnout. Only a handful of votes could mean the difference between real representation or politics as usual. In many cases, the primary election is where the biggest battles take place, not the general election. The bottom line is your vote counts!</p>
                </section>
                <section>
                    <h3>Building a Strong Bench</h3>
                    <div className={styles.divider} />
                    <p>Today's local election is tomorrow's federal election. Our own Andy Ogles in the U.S. House of Representatives is one of merely 20-30 in congress that consistently stand up to the political power structure.</p>
                    <p>Before congress, Andy was elected as mayor of Maury County in 2018. When we win local elections, we create a "bench" of public servants. From this bench will likely be selected our next senator, house representative, and governor.</p>
                </section>
                <section>
                    <h3>We Need Your Help!</h3>
                    <div className={styles.divider} />
                    <p>Whether you are a recent transplant from another state, or your family has lived here for generations, this is your home and your children's future. We need everyone to vote. This grassroots movement grows from person to person, neighbor to neighbor, and citizen to citizen. We hope that you will subscribe and share this website with like-minded people. <strong>It's time to unify, stay focused and win!</strong></p>
                </section>

                <BottomContent />
            </main>
        </div>
    );
}


AboutPage.propTypes = {}

AboutPage.defaultProps = {}

export default AboutPage;
