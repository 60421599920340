import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./CKEditor.scss";
import 'ckeditor5/ckeditor5.css';
import MediaUploadAdapter from "@src/utils/CKEditorMediaUploadPlugin";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
    Alignment,
    BlockQuote,
    Bold,
    ClassicEditor,
    Code,
    Essentials,
    FileRepository,
    Font,
    Heading,
    HtmlEmbed,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    MediaEmbed,
    Paragraph,
    SourceEditing,
    Subscript,
    Superscript,
    Underline,
    Undo,
} from 'ckeditor5';

function CKEditorComponent({
    initialHtml,
    onChange,
}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [editorInstance, setEditorInstance] = useState(null);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    const handleChange = () => {
        // Pull HTML from editor
        const updatedData = editorInstance.getData();
        onChange(updatedData);
    }

    if (!isLoaded) {
        return null;
    }

    return (
        <CKEditor
            editor={ ClassicEditor }
            config={ {
                toolbar: {
                    items: [
                        'undo',
                        'redo',
                        '|',
                        'heading',
                        'fontSize',
                        'fontFamily',
                        'fontColor',
                        'fontBackgroundColor',
                        '|',
                        'alignment',
                        'bold',
                        'italic',
                        'link',
                        'underline',
                        '|',
                        'subscript',
                        'superscript',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'insertImage',
                        'htmlEmbed',
                        'mediaEmbed',
                        'code',
                        'sourceEditing',
                    ],
                },
                plugins: [
                    Alignment,
                    BlockQuote,
                    Bold,
                    Code,
                    Essentials,
                    FileRepository,
                    Font,
                    Heading,
                    HtmlEmbed,
                    Image,
                    ImageCaption,
                    ImageResize,
                    ImageStyle,
                    ImageToolbar,
                    ImageUpload,
                    Italic,
                    Link,
                    LinkImage,
                    List,
                    ListProperties,
                    MediaEmbed,
                    MediaUploadAdapter,
                    Paragraph,
                    SourceEditing,
                    Subscript,
                    Superscript,
                    Underline,
                    Undo,
                ],
                list: {
                    properties: {
                        styles: true,
                        startIndex: true,
                        reversed: true
                    }
                },
                image: {
                    toolbar: [
                        'imageStyle:block',
                        'imageStyle:side',
                        '|',
                        'toggleImageCaption',
                        'imageTextAlternative',
                        '|',
                        'linkImage'
                    ],
                    insert: {
                        // If this setting is omitted, the editor defaults to 'block'.
                        // See explanation below.
                        type: 'auto'
                    },
                },
                initialData: initialHtml,
            } }
            onChange={handleChange}
            onReady={ ( editor ) => {
              // You can store the "editor" and use when it is needed.
              console.log( 'Editor is ready to use!', editor );
              setEditorInstance(editor);
            } }
        />
    );
}


CKEditorComponent.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialHtml: PropTypes.string,
}

export default CKEditorComponent;
