// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Select__root{flex:1;width:100%;position:relative}.Select__root select{width:100%;-webkit-appearance:none;-moz-appearance:none;appearance:none;background-image:linear-gradient(45deg, transparent 50%, gray 50%),linear-gradient(135deg, gray 50%, transparent 50%);background-position:calc(100% - 25px) calc(0.825em + 2px),calc(100% - 15px) calc(0.825em + 2px);background-size:10px 10px, 10px 10px;background-repeat:no-repeat}.Select__root select:-moz-focusring{color:transparent;text-shadow:0 0 0 #000}.Select__root.Select__inline{display:flex;flex-direction:row;align-items:center}.Select__root.Select__inline .label{flex:none;margin-right:1em}.Select__root.Select__inline select{flex:1}.Select__root .Select__arrow{position:absolute;top:0;right:1rem;height:100%;display:flex;align-items:center}
`, "",{"version":3,"sources":["webpack://./web/src/components/shared/elements/Select/Select.scss"],"names":[],"mappings":"AAGA,cACE,MAAO,CACP,UAAW,CACX,iBAAkB,CAHpB,qBAMI,UAAW,CACX,uBAAgB,CAAhB,oBAAgB,CAAhB,eAAgB,CAEhB,qHAEoD,CAEpD,+FAEuC,CAEvC,oCAEW,CAEX,2BAA4B,CArBhC,oCA0BE,iBAAkB,CAClB,sBAAuB,CA3BzB,6BA+BI,YAAa,CACb,kBAAmB,CACnB,kBAAmB,CAjCvB,oCAoCM,SAAU,CACV,gBAAiB,CArCvB,oCAwCM,MAAO,CAxCb,6BA6CI,iBAAkB,CAClB,KAAM,CACN,UAAW,CACX,WAAY,CACZ,YAAa,CACb,kBAAmB","sourcesContent":["@import \"@commonstyles/vars.scss\";\n@import \"@commonstyles/colors.scss\";\n\n.root {\n  flex: 1;\n  width: 100%;\n  position: relative;\n\n  select {\n    width: 100%;\n    appearance: none;\n\n    background-image:\n      linear-gradient(45deg, transparent 50%, gray 50%),\n      linear-gradient(135deg, gray 50%, transparent 50%);\n\n    background-position:\n      calc(100% - 25px) calc(0.825em + 2px),\n      calc(100% - 15px) calc(0.825em + 2px);\n\n    background-size:\n      10px 10px,\n      10px 10px;\n\n    background-repeat: no-repeat;\n  }\n\n\nselect:-moz-focusring {\n  color: transparent;\n  text-shadow: 0 0 0 #000;\n}\n\n  &.inline {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n\n    :global(.label) {\n      flex: none;\n      margin-right: 1em;\n    }\n    select {\n      flex: 1;\n    }\n  }\n\n  .arrow {\n    position: absolute;\n    top: 0;\n    right: 1rem;\n    height: 100%;\n    display: flex;\n    align-items: center;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Select__root`,
	"inline": `Select__inline`,
	"arrow": `Select__arrow`
};
export default ___CSS_LOADER_EXPORT___;
