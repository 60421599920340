import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./MapMarker.scss";

function MapMarker({ text, icon, iconName = "location-dot", dark, onHover, isSelected }) {

    const handleOnHover = (evt) => {
        onHover(true);
    }

    const handleOffHover = (evt) => {
        onHover(false);
    }

    return (
        <div
            className={classnames(styles.root, {
                [styles.isSelected]: isSelected,
            })}
            // onMouseEnter={handleOnHover}
            // onMouseLeave={handleOffHover}
            onMouseOver={handleOnHover}
            onMouseOut={handleOffHover}
        >
            <i className={`fa-solid fa-${iconName}`} />
            {!!text && <div className={classnames(styles.text, {
                [styles.dark]: dark,
            })}>{text}</div>}
          </div>
    );
}


MapMarker.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    text: PropTypes.string,
    iconName: PropTypes.string,
    dark: PropTypes.bool,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    onHover: PropTypes.func,
}


export default MapMarker;
