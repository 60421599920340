import {requestError} from "@src/utils/request";
import * as ActionTypes from "@src/constants/ActionTypes";
import * as ElectionApi from "@src/api/ElectionApi";

export function loadElectionCalendar() {
    return (dispatch) => {
        ElectionApi.loadElectionCalendar()
            .then((res) => {
                dispatch({
                    type: ActionTypes.UPCOMING_ELECTIONS_LOADED,
                    electionDates: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error loading election calendar");
            });
    }
}

export function loadPastElections() {
    return (dispatch) => {
        ElectionApi.loadPastElections()
            .then((res) => {
                dispatch({
                    type: ActionTypes.PAST_ELECTIONS_LOADED,
                    electionDates: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error loading election calendar");
            });
    }
}

export function loadElectionDate(id) {

    return (dispatch) => {
        ElectionApi.loadElectionDate(id)
            .then((res) => {
                console.log("ElectionActions :: loadElectionDate :: res.body = ", res.body);
                dispatch({
                    type: ActionTypes.ELECTION_DATE_LOADED,
                    election: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error loading election date");
            });
    }
}

export function loadElectionDetails(electionSlug) {
    console.log("ElectionActions :: loadElectionDetails :: electionSlug = ", electionSlug);
    return (dispatch) => {
        ElectionApi.loadElectionBySlug(electionSlug)
            .then((res) => {
                dispatch({
                    type: ActionTypes.ELECTION_DETAILS_LOADED,
                    election: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error loading election using slug");
            });
    }
}

export function loadPoliticianById(id) {
    return (dispatch) => {
        ElectionApi.loadPoliticianById(id)
            .then((res) => {
                dispatch({
                    type: ActionTypes.POLITICIAN_LOADED,
                    politician: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error loading politician");
            });
    }
}


export function loadPoliticians() {
    return (dispatch) => {
        ElectionApi.loadPoliticians()
            .then((res) => {
                dispatch({
                    type: ActionTypes.POLITICIANS_LOADED,
                    politicians: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error loading politician");
            });
    }
}

export function loadRatings() {
    return (dispatch) => {
        ElectionApi.loadRatings()
            .then((res) => {
                dispatch({
                    type: ActionTypes.RATINGS_LOADED,
                    ratings: res.body,
                });
            })
            .catch((err) => {
                requestError(err, "Error loading ratings");
            });
    }
}


