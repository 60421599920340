import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "@src/pages/HomePage/HomePage.scss";
import Hero from "@src/components/Hero";
import {setPageTitle} from "@src/utils/windowHelpers";

function ContactPage({
    //...props
}) {
    useEffect(() => {
        setPageTitle("Contact Us", window.location);
    }, []);

    return (
        <div className={classnames(styles.root, styles.contactPage)}>
            <Hero
                imageFilename={"wcc_hero_background_strip.jpg"}
                imageAltText={"American flag and Tennessee flying"}
                headerText={`Contact Us`}
                subpage
            />
            <main className={styles.main}>
                <section>
                    <h2>Williamson County Citizens</h2>
                    <div className={styles.subheader}>Your Conservative Grassroots Network in Williamson County, Tennessee</div>
                    <div>Email us at: <a href={"mailto:support@williamsoncountycitizens.org"}><strong>support@williamsoncountycitizens.org</strong></a></div>
                </section>
            </main>
        </div>
    );
}


ContactPage.propTypes = {

}

export default ContactPage;
