
import request from "@src/utils/request";


export function createArticle(data) {
    return request()
        .post("/api/v1/articles/")
        .send(data);
}

export function updateArticle(articleId, data) {
    return request()
        .patch(`/api/v1/articles/${articleId}/`)
        .send(data);
}

export function deleteArticle(articleId) {
    return request()
        .delete(`/api/v1/articles/${articleId}/`)
        .send();
}

export function loadArticles() {
    return request()
        .get("/api/v1/articles/")
        .send();
}

export function loadRecentArticles() {
    return request()
        .get("/api/v1/articles/recent/")
        .send();
}

export function loadArticle(articleId) {
    return request()
        .get(`/api/v1/articles/${articleId}/`)
        .send();
}

export function loadArticleBySlug(articlePath) {
    return request()
        .get(`/api/v1/article-lookup/${articlePath}/`)
        .send();
}
