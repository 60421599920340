import {v4 as uuidv4} from 'uuid';
import * as ContentTypes from "@src/constants/ContentTypes";
import forEach from "lodash/forEach";

export const generatePosterId = (contentId = null, type = ContentTypes.RICH_TEXT, action = ContentTypes.NEW, options = {}) => {
    const addOns = [];
    const identifier = contentId || "";
    forEach(options, (v,k) => {
        if (v) {
            addOns.push(`${k}_${v}`);
        }
    });
    const optionsList = addOns.length ? `_${addOns.join("_")}` : "";
    return `${type}_${identifier}${optionsList}_${action}`;
}

export const generateUUID = () => {
    return uuidv4()
}

/*
    `post_${new Date().getTime()}`
*/
