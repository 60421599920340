import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./ArticleEditor.scss";
import Button from "@src/components/shared/elements/Button";
import Input from "@src/components/shared/elements/Input";
import RichTextEditor from "@src/components/shared/widgets/RichTextEditor";
import * as ArticleActions from "@src/actions/ArticleActions";
import { RICH_TEXT, NEW, EDIT } from "@src/constants/ContentTypes";
import {generatePosterId} from "@src/utils/IdGenerator";
import AdminAccessWrapper from "@src/components/shared/widgets/AdminAccessWrapper";
function ArticleEditor(props) {
    const {
        isNew,
        article,
        articleId,
        created,
        editorPublished,
        editorUpdated,
        creatingArticle,
        createArticle,
        editArticle,
        loadArticles,
        updateArticle,
        userProfile,
        publishArticle,
        organization,
    } = props;

    const [isLoaded, setIsLoaded] = useState(false);
    const [posterId, setPosterId] = useState(null);
    const [articleDraftId, setArticleDraftId] = useState(generatePosterId(null, RICH_TEXT, NEW));
    const [contentToEdit, setContentToEdit] = useState(null);

    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [unpublishedChanges, setUnpublishedChanges] = useState(false);
    const [formData, setFormData] = useState({
        title: "",
        headline: "",
        path: "",
        content: null,
        contentJson: null,
    });
    const navigate = useNavigate();

    useEffect(() => {
        setPosterId(`comment_${new Date().getTime()}`);
        console.log("\n\n\nArticleEditor :: effect :: LOAD ARTICLE");
        loadArticles();
    }, []);

    useEffect(() => {
        if (articleId && !article) {
            editArticle(articleId);
        } else if (article) {
            setArticleDraftId(generatePosterId(articleId, RICH_TEXT, EDIT));
            setContentToEdit(article.content);
            setFormData({
                ...article
            });
            setIsLoaded(true);
        }
    }, [article, articleId]);

    useEffect(() => {
         creatingArticle();
         setIsLoaded(true);
    }, [isNew])

    useEffect(() => {
        if (created) {
            navigate(`/news/${created}/edit`);
        }
    }, [created]);

    useEffect(() => {
        if (editorPublished) {
            setUnsavedChanges(false);
            setUnpublishedChanges(false);
        } else if (editorUpdated) {
            setUnsavedChanges(false);
            setUnpublishedChanges(true);
        }
    }, [editorUpdated, editorPublished]);

    if (!isLoaded) {
        return <div><h1>LOADING....</h1></div>
    }

    const handleBodyUpdate = ({ name, value }) => {
        const updatedFormData = {
            ...formData,
        }

        updatedFormData.contentJson = "";
        updatedFormData.content = value;

        setFormData(updatedFormData);
        setUnsavedChanges(true);
        setUnpublishedChanges(true);
    }

    const handleInputChange = ({ name, value }) => {
        const updatedData = {
            ...formData
        };
        updatedData[name] = value;
        setFormData(updatedData);
        setUnsavedChanges(true);
        setUnpublishedChanges(true);
    };

    const handleCancel = () => {
        if (article) {
            navigate(`/news/${article.path}/`);
        } else {
            navigate("/news/");
        }
    }

    const handleSaveArticle = () => {
        if (article) {
            updateArticle(article.id, formData);
        } else {
            createArticle(formData);
        }
    }

    const handlePublishArticle = () => {
        publishArticle(article.id, {
            ...formData,
            published: true,
            publishedDate: new Date().toISOString(),
        });
    }

    const pageTitle = isNew ? "Add New Article" : "Edit Article";

    return (
        <AdminAccessWrapper>
            <div className={styles.root}>
                <div className={styles.controlsRow}>
                    <div className={styles.controls}>
                        <Link className={styles.backLink} to={"/news"}><i className={"fa-solid fa-chevron-left"} /> Articles</Link>
                    </div>
                    <h2>{pageTitle}</h2>
                </div>
                <div className={styles.form}>
                    <Input
                        className={"top-margin"}
                        label="Title"
                        onChange={handleInputChange}
                        type={"text"}
                        name={"title"}
                        value={formData.title}
                    />
                    <Input
                        label="Headline"
                        onChange={handleInputChange}
                        type={"text"}
                        name={"headline"}
                        value={formData.headline}
                    />
                    <Input
                        label={<span>Path <small>(/app/news/[PATH]/)</small></span>}
                        onChange={handleInputChange}
                        type={"text"}
                        name={"path"}
                        value={formData.path}
                    />
                    <RichTextEditor
                        label="Article Body"
                        name="content"
                        id={articleDraftId}
                        onChange={handleBodyUpdate}
                        value={contentToEdit || ""}
                        defaultContent={contentToEdit}
                        placeholder="Insert text here"
                        preserveOnSave
                        disableDrafts
                        loaded={isLoaded}
                        editing={!isNew}
                    />
                    <div className={styles.buttonRow}>
                        <Button onClick={handleCancel} buttonText={"Cancel"} buttonColor={"light"} />
                        <Button disabled={!unsavedChanges} onClick={handleSaveArticle} buttonText={"Save"} buttonColor={"default"} />
                        <Button disabled={!unsavedChanges && !unpublishedChanges} onClick={handlePublishArticle} buttonText={"Save & Publish"} buttonColor={"default"} />
                    </div>
                </div>
            </div>
        </AdminAccessWrapper>
    );
}


ArticleEditor.propTypes = {
    isNew: PropTypes.bool,
    articlePath: PropTypes.string,
}

ArticleEditor.defaultProps = {}

export default connect(
    (state, ownProps) => {
        const articlePath = ownProps.params.articlePath;
        const articleData = state.entities.articles[articlePath] || null;
        const articleId = articleData?.id;
        const editorState = state.appState.articleEditorState;

        return {
            articleId,
            created: editorState.created,
            article: editorState.editing,
            editorUpdated: editorState.updated,
            editorPublished: editorState.published,
            organization: state.appState.organization,
            userProfile: state.user.userProfile,
        }
    },
    (dispatch) => ({
        loadArticles: () => dispatch(ArticleActions.loadArticles()),
        creatingArticle: () => dispatch(ArticleActions.creatingArticle()),
        createArticle: (data) => dispatch(ArticleActions.createArticle(data)),
        updateArticle: (articleId, data) => dispatch(ArticleActions.updateArticle(articleId, data)),
        publishArticle: (articleId, data) => dispatch(ArticleActions.publishArticle(articleId, data)),
        editArticle: (articleId) => dispatch(ArticleActions.editArticle(articleId)),
    })
)(ArticleEditor);
