import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import find from "lodash/find";
import filter from "lodash/filter";
import map from "lodash/map";
import moment from "moment";
import styles from "./LocationDetails.scss";
import IconTag from "@src/components/IconTag";
import PoliticianInfo from "@src/components/PoliticianInfo";


function LocationDetails({ columnHeaders, location, mapLayer, showFeatureLabels }) {
    const locationFeatureId = location?.district || location?.precinct || location.featureId;

    const renderSeat = (seat) => {
        const seatLabel = seat.featureLabel ? `${seat.name} - ${seat.featureLabel}` : seat.name;
        const { opportunity, term, politician } = seat.electedOfficial || {};
        const election = seatElection(seat);
        return (
            <div className={styles.seat} key={seat.id}>
                <PoliticianInfo
                    id={politician}
                    opportunity={opportunity}
                    termEnd={term?.endDate}
                    seatLabel={seatLabel}
                    election={election}
                    columnHeaders={columnHeaders}
                />
            </div>
        );
    }

    const locationElection = useMemo(() => {
        return find((mapLayer?.elections||[]), election => {
            const locationSeat = find(election.seats, electionSeat =>
                electionSeat.seat.featureId === String(locationFeatureId));

            return !!locationSeat;

        });
    }, [mapLayer]);

    const seatElection = (seat) => {
        if (!locationElection) {
            return null;
        }
        const match = find(locationElection.seats, elecSeat => elecSeat.seat.id === seat.id);
        return match ? locationElection : null;
    }

    const seats = useMemo(() => {
        const featureSeats = filter((mapLayer?.seats||[]), layerSeat => layerSeat.featureId === String(locationFeatureId));
        if (!featureSeats.length) {
            return null;
        }
        return map(featureSeats, seat => renderSeat(seat));
    }, [mapLayer]);

    const featureLabel = useMemo(() => {
        if (!showFeatureLabels || !location || locationFeatureId === 0 || seats?.length <= 1) {
            return null;
        }
        const locType = location?.precinct ? "Precinct" : "District";
        const textLabel = `${locType} ${locationFeatureId}`;
        return <h4 className={styles.featureLabel}>{textLabel}</h4>

    }, [location, locationFeatureId, seats, showFeatureLabels]);

    const hasMultiple = seats?.length > 1 || false;

    const renderSeats = () => {
        if (seats?.length > 1) {
            return (
                <div className={styles.seats}>
                    {seats}
                </div>
            );
        }
        return seats;
    }
    if (!seats) {
        return null;
    }

    return (
        <div className={classnames(styles.root, {
            [styles.withLabel]: !!featureLabel,
            [styles.multiple]: hasMultiple && !showFeatureLabels,
        })}>
            {featureLabel}
            {renderSeats()}
        </div>
    );
}


LocationDetails.propTypes = {
    location: PropTypes.object.isRequired,
    showFeatureLabels: PropTypes.bool,
    columnHeaders: PropTypes.array,
}

LocationDetails.defaultProps = {}

export default connect(
    (state, ownProps) => {
        const mapLayerId = ownProps.location?.layer.key;
        return {
            mapLayer: state.mapState.mapLayers[ownProps.mapSlug]?.[mapLayerId],
        }
    },
    null
)(LocationDetails);
