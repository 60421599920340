import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./TextMarker.scss";

function TextMarker({ text, light }) {
    return (
        <div className={styles.root}>
            <div className={classnames(styles.text, { [styles.light]: light })}>{text}</div>
        </div>
    );
}


TextMarker.propTypes = {
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    text: PropTypes.string,
    light: PropTypes.bool,
}

export default TextMarker;
