import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./PoliticalParty.scss";
import Icon from "@src/components/shared/elements/Icon";
import { getPartyColor } from "@src/constants/Colors";

function PoliticalParty({
    party,
    showName
}) {
    const partyName = useMemo(() => {
        if (party === "democratic") {
            return "democrat";
        } else if (party === "independent") {
            return "i";
        }
        return party;
    }, [party]);

    return (
        <div className={styles.root}>
            <Icon iconName={partyName} colorHex={getPartyColor(party)} solid circle />
            {showName && <div className={styles.partyName}>{party}</div>}
        </div>
    );
}


PoliticalParty.propTypes = {
    party: PropTypes.string.isRequired,
    showName: PropTypes.bool,
}

export default PoliticalParty;
