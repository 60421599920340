import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "@src/pages/HomePage/HomePage.scss";
import Hero from "@src/components/Hero";
import * as SignupActions from "@src/actions/SignupActions";

function SubscriptionVerifyPage({
    organization,
    userProfile,
}) {
    console.log("WelcomePage :: WelcomePage :: userProfile = ", userProfile);
    const userEmailAddress = userProfile?.email || "email@address.com";
    const userFirstName = userProfile?.firstName || "Subscriber";
    const userFullname = userProfile?.fullName || "Subscriber Fullname";
    const userPhoneNumber = userProfile?.phoneNumber || "(615) 123-4567";

    return (
        <div className={styles.root}>
            <Hero
                imageFilename={"wcc_hero_background_strip.jpg"}
                imageAltText={"American flag and Tennessee flying"}
                headerText={`Welcome ${userFirstName}`}
                subpage
            />
            <main className={styles.main}>
                <section>
                    {/*<h2>You’re almost there! Just two more steps!</h2>*/}
                    <h2>You’re almost there! Just one more step!</h2>
                    <div className={styles.divider} />
                </section>
                <section>
                    {/*<h3>1. Verify your email</h3>*/}
                    <h3>Verify your email</h3>
                    <div>You were sent an email with a verification link. Please find it and verify your email address.</div>
                    <div>Be advised you may need to check your SPAM folder.</div>
                </section>
                <section>
                    <h4>Having Trouble?</h4>
                    <div>If you're having difficulties please email us at <a href={"mailto:support@williamsoncountycitizens.org"}><strong>support@williamsoncountycitizens.org</strong></a></div>
                </section>

                {/*<section>*/}
                {/*    <h3>2. Verify your phone number</h3>*/}
                {/*    <div>You were sent a text message from us. Please reply to verify your phone number.</div>*/}
                {/*</section>*/}
            </main>
        </div>
    );
}


SubscriptionVerifyPage.propTypes = {}

export default connect(
    (state) => {
        const organization = state.appState.currentOrganization;
        const userProfile = state.user.userProfile;

        return {
            organization,
            userProfile,
        };
    },
    (dispatch) => ({
        signUpUser: (user) => dispatch(SignupActions.signUpUser(user)),
        requestEditLink: (email) => dispatch(SignupActions.requestEditLink(email)),
        clearSignupStatus: () => dispatch(SignupActions.clearSignupStatus()),
    })
)(SubscriptionVerifyPage);
