// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PoliticalParty__root{flex:1;display:flex;flex-direction:row;align-items:center;justify-content:flex-end}.PoliticalParty__root .PoliticalParty__partyName{text-transform:capitalize;margin-left:0.25rem}
`, "",{"version":3,"sources":["webpack://./web/src/components/shared/widgets/PoliticalParty/PoliticalParty.scss"],"names":[],"mappings":"AAGA,sBACE,MAAO,CACP,YAAa,CACb,kBAAmB,CACnB,kBAAmB,CACnB,wBAAyB,CAL3B,iDAOI,yBAA0B,CAC1B,mBAAoB","sourcesContent":["@import \"@commonstyles/vars.scss\";\n@import \"@commonstyles/colors.scss\";\n\n.root {\n  flex: 1;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-end;\n  .partyName {\n    text-transform: capitalize;\n    margin-left: 0.25rem;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `PoliticalParty__root`,
	"partyName": `PoliticalParty__partyName`
};
export default ___CSS_LOADER_EXPORT___;
