import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import map from "lodash/map";
import styles from "./ElectionsDashboard.scss";
import * as urls from "@src/constants/ApplicationUrls";
import {Link} from "react-router-dom";
import * as ElectionActions from "@src/actions/ElectionActions";
import ElectionCalendarItem from "@src/components/elections/ElectionCalendarItem";

function ElectionsDashboard({ loadElectionCalendar, elections }) {

    useEffect(() => {
        loadElectionCalendar();
    }, []);

    const renderCalendarItems = () => {
        return map(elections, electionInfo => (
            <ElectionCalendarItem config={electionInfo} key={electionInfo.id} />
        ));
    }

    return (
        <div className={styles.root}>
            <h1>Elections Calendar</h1>
            <div className={styles.links}>
                {renderCalendarItems()}
            </div>
        </div>
    );
}

export default connect(
    (state, ownProps) => ({
        elections: state.entities.upcomingElections,
    }),
    (dispatch) => ({
        loadElectionCalendar: () => dispatch(ElectionActions.loadElectionCalendar()),
    })
)(ElectionsDashboard);
