import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./MapListContent.scss";
import Button from "@src/components/shared/elements/Button";
import MapList from "@src/components/maps/MapList";
import filter from "lodash/filter";
import * as MapActions from "@src/actions/MapActions";

function MapListContent({
    lookupMap,
    loadMaps,
    maps,
    onHandleHasDefault
}) {
    useEffect(() => {
        if (!maps) {
            loadMaps();
        }
    }, []);

    const handleHasDefault = (mapSlug) => {
        if (onHandleHasDefault) {
            onHandleHasDefault(mapSlug);
        }
    }
    return (
        <>
            {lookupMap && (
                <div className={styles.lookupTool}>
                    <h2>Address Lookup Tool</h2>
                    <div>Get specific results using your address</div>
                    <Link to={`/local-government/${lookupMap.slug}/`}>
                        <Button buttonText={"Lookup My Address"} />
                    </Link>
                </div>
            )}
            <div className={styles.localMaps}>
                <h2>Political Boundary Maps</h2>
                <div>Select which layer of your local government you would like to view</div>
                <div className={styles.list}>
                    <MapList onHasDefault={onHandleHasDefault}/>
                </div>
            </div>
        </>
    );
}


MapListContent.propTypes = {
    onHandleHasDefault: PropTypes.func,
}

export default connect(
    (state) => {
        const maps = state.mapState.maps;
        const lookupMaps = filter(maps, mapObj => mapObj.isLookup);
        const lookupMap = lookupMaps.length ? lookupMaps[0] : null;
        return {
            maps,
            lookupMap,
        }
    },
    (dispatch) => ({
        loadMaps: () => dispatch(MapActions.loadMaps()),
    })
)(MapListContent);
