import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import styles from "./Input.scss";
import classnames from "classnames";

export default function Input(props) {
    const [textAreaLines, setTextAreaLines] = useState(1);

    useEffect(() => {
        if(props.minLines) {
            setTextAreaLines(props.minLines);
        }
    }, [props.minLines]);

    useEffect(() => {
        const minimum = props.minLines || 1;
        if(props.value === "" && textAreaLines !== minimum) {
            setTextAreaLines(minimum);
        }
    }, [props.value]);

    const handleChange = (e) => {
        let value = e.target.value;
        let lines = e.target.scrollHeight > 38 ? Math.ceil(e.target.scrollHeight / 22) : 1;
        if (lines > props.maxLines) {
            lines = props.maxLines;
        }
        if (props.minLines && lines < props.minLines) {
            lines = props.minLines;
        }
        setTextAreaLines(lines);

        if (props.numeric) {
            value = e.target.value === "" ? null : Number(e.target.value);
        }

        props.onChange({
            name: props.name,
            value,
        });
    };

    const handleOnBlur = () => {
        if(props.onBlur) {
            props.onBlur();
        }
    }

    return (
        <div className={classnames(styles.root, {
            [styles.disabled]: props.disabled,
        })}>
            {props.label &&
                <div className="label">
                    {props.label}{!!props.required && <span>*</span>}
                </div>
            }
            {props.type === "textarea" ? (
                <textarea
                    className={classnames({
                        [styles.noResize]: props.noResize,
                        "error": props.inError,
                    })}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                    name={props.name}
                    id={props.id}
                    required={props.required}
                    autoComplete={props.autocomplete}
                    disabled={props.disabled}
                    checked={props.type === "checkbox" && props.value}
                    max={props.max}
                    rows={textAreaLines}
                />
            ) : (
                <input
                    className={classnames({ "error": props.inError })}
                    type={props.type||"text"}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={handleChange}
                    onBlur={handleOnBlur}
                    name={props.name}
                    id={props.id}
                    required={props.required}
                    autoComplete={props.autocomplete}
                    disabled={props.disabled}
                    checked={props.type === "checkbox" && props.value}
                    max={props.max}
                    maxLength={props.maxLength}
                />
            )}
        </div>
    );
}

Input.propTypes = {
    placeholder: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    type: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    id: PropTypes.string,
    autocomplete: PropTypes.oneOf(["on", "off"]),
    disabled: PropTypes.bool,
    max: PropTypes.number,
    minLines: PropTypes.number,
    maxLines: PropTypes.number,
    maxLength: PropTypes.number,
    noResize: PropTypes.bool,
    inError: PropTypes.bool,
    required: PropTypes.bool,
};

Input.defaultProps = {
    autocomplete: "on",
    maxLines: 5,
};
